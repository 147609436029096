import React, { useState, useEffect, useContext, useRef } from "react"
import { createPortal } from "react-dom"
import IconClose from "../../_assets/icone/close.svg"
import IconPlay from "../../_assets/icone/play-icon-player.svg"
import IconPause from "../../_assets/icone/pause-icon-player.svg"
import audioPlayerCtx from "../../_store/audioPlayer/audioPlayerCtx";

// Styles
import style from "./MusicPlayer.module.css"

const MusicPlayer = () => {
  // useContext
  const {
    currentSong,
    currentTime,
    SetCurrentTime,
    isAudioPlayer,
    toggleAudioPlayer,
    playing,
    togglePlaying,
  } = useContext(audioPlayerCtx)

  const [dur, setDuration] = useState(0)

  // useRef
  let audioRef = useRef()
  let progressBar = useRef()

  // set how many seconds play the audio preview 
  let previewTime = 5;

  // toggle audio play/pause
  const toggleAudio = () => {
    audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause()
  }

  // reset data when the music player is closed
  const handleCloseMusicPlayer = () => {
    toggleAudioPlayer()
    SetCurrentTime(0)
  }

  // update progressbar ui when song is playing
  // reset data when currentSong change
  useEffect(() => {
    if (playing) {
      progressBar.current.style.setProperty('--seek-before-width', currentTime / previewTime * 100 + "%")
      if (currentTime === 0) {
        toggleAudio()
      }
      else if (currentTime > previewTime) {
        toggleAudio()
        togglePlaying()
        audioRef.current.currentTime = 0
      }
    }
  }, [currentSong, currentTime])



  if (isAudioPlayer) {
    return createPortal(
      <div className={`${style.container} ${isAudioPlayer ? style.slideIn : style.slideOut}`} >
        <audio
          ref={audioRef}
          onTimeUpdate={(e) => SetCurrentTime(e.target.currentTime)}
          onCanPlay={(e) => setDuration(e.target.duration)}
          preload="true"
          src={currentSong.SongUrl}
        />
        <div>
          <input
            type="range" />
          <span ref={progressBar} className={style.progressBar}></span>
        </div>

        <div className={style.containerContent}>
          <div className={style.head}>
            <button onClick={() => { toggleAudio(); togglePlaying() }}>
              {playing ? <img src={IconPlay} alt="play" /> : <img src={IconPause} alt="pause" />}
            </button>
            <div className={style.headText}>
              <h4>Anteprima brano</h4>
              <div className={style.headTextDetails}>
                <h5>{currentSong.Registrazioni_Titolo}
                </h5>
                <h5>- {currentSong.SoggettiAIE_NomeArtistico}</h5>
              </div>

            </div>

          </div>
          <div className={style.tail}>
            <button onClick={() => handleCloseMusicPlayer()} className={style.btnClose}>
              <img width={15} height={15} src={IconClose} alt="chiudi popup player" />
            </button>
          </div>
        </div>
      </div>
      , document.getElementById('root-player'))
  } else return null;
}
export default MusicPlayer

