import React from "react";
import style from "./Title.module.css";
const Title = (props) => {
  return (
    <div className={style.title}>
      <h1 style={{ textTransform: props.textTransform, color: props.color }}>{props.title}</h1>
    </div>
  );
};
export default Title;
