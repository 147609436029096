import React, { useReducer } from "react";
import audioPlayerReducer from "./audioPlayerReducer";
import audioPlayerCtx from "./audioPlayerCtx";

const AudioPlayerProvider = (props) => {
  const initialState = {
    currentSong: {},
    currentTime: 0,
    playing: false,
    isAudioPlayer: false,
    songsList: [],
    songsListCurrent: {},
    currentTimePlaylist: 0,
    isAudioPlayerPlaylist: false,
    playingPlaylist: false,
    currentPlaylist: {},
    random: false,
    counter: 0,
    isEmptyPlaylist: false
  }

  const [state, dispatch] = useReducer(audioPlayerReducer, initialState)

  // MUSIC PLAYER SINGLE TRACK
  const SetCurrent = (index) => dispatch({ type: 'SET_CURRENT_SONG', data: index })

  const SetCurrentTime = (time) => dispatch({ type: 'SET_CURRENT_TIME', data: time })

  const SetAudioPlayer = (Boolean) => dispatch({ type: 'SET_AUDIOPLAYER', data: Boolean })

  const toggleAudioPlayer = () =>
    dispatch({ type: "TOGGLE_AUDIOPLAYER", data: state.isAudioPlayer ? false : true })



  const togglePlaying = () =>
    dispatch({ type: "TOGGLE_PLAYING", data: state.playing ? false : true })


  // MUSIC PLAYER PLAYLIST
  const SetCurrentPlaylist = (playlist) => dispatch({
    type: 'SET_CURRENT_PLAYLIST', data: playlist
  })

  const SetEmptyPlaylist = (Boolean) => dispatch({ type: 'SET_EMPTY_PLAYLIST', data: Boolean })

  const SetCurrentTimePlaylist = (time) => dispatch({ type: 'SET_CURRENT_TIME_PLAYLIST', data: time })

  const SetSongsList = (songsArray) => dispatch({
    type: 'SET_SONGS_ARRAY', data: {
      songsList: songsArray, songsListCurrent: { track: songsArray[0], position: 0 }
    }
  })




  const SetAudioPlayerPlaylist = (bool) =>
    dispatch({ type: "SET_AUDIOPLAYER_PLAYLIST", data: bool })

  const SetPlayingPlaylist = (bool) =>
    dispatch({ type: "SET_PLAYING_PLAYLIST", data: bool })


  const togglePlayingPlaylist = () =>
    dispatch({ type: "TOGGLE_PLAYING_PLAYLIST", data: state.playingPlaylist ? false : true })

  const toggleAudioPlayerPlaylist = () =>
    dispatch({ type: "TOGGLE_AUDIOPLAYER_PLAYLIST", data: state.isAudioPlayerPlaylist ? false : true })

  const SetSongsListCurrent = (songsList, index) => dispatch({
    type: 'SET_SONGS_ARRAY_CURRENT', data: { track: songsList[index], position: index }
  })

  const prevSong = () => {
    if (state.songsListCurrent.position === 0) {
      SetSongsListCurrent(state.songsList, state.songsList.length - 1)
    } else {
      SetSongsListCurrent(state.songsList, state.songsListCurrent.position - 1)
    }
  }

  const nextSong = () => {
    if (state.songsListCurrent.position === state.songsList.length - 1) {
      state.counter = 0;
      SetSongsListCurrent(state.songsList, state.counter)
    } else {
      state.counter += 1
      SetSongsListCurrent(state.songsList, state.counter)
    }
  }

  const resetCounter = () => {
    state.counter = 0
  }

  const toggleRandom = (id) => {
    dispatch({ type: 'TOGGLE_RANDOM', data: state.repeat ? false : true })
  }

  const handleEnd = () => {
    if (state.random) {
      return dispatch({ type: "SET_CURRENT_SONG", data: Math.random() * state.songlist.length - 1 })
    } else {
      if (state.currentSong === state.songsList.length - 1) {
        return
      } else {
        nextSong()
      }
    }
  }




  return (
    <audioPlayerCtx.Provider value={{
      currentSong: state.currentSong,
      currentTime: state.currentTime,
      playing: state.playing,
      isAudioPlayer: state.isAudioPlayer,
      SetCurrent,
      SetCurrentTime,
      togglePlaying,
      toggleAudioPlayer,
      SetAudioPlayer,
      currentPlaylist: state.currentPlaylist,
      songsList: state.songsList,
      songsListCurrent: state.songsListCurrent,
      currentTimePlaylist: state.currentTimePlaylist,
      random: state.random,
      playingPlaylist: state.playingPlaylist,
      isAudioPlayerPlaylist: state.isAudioPlayerPlaylist,
      isEmptyPlaylist: state.isEmptyPlaylist,
      SetSongsList,
      SetSongsListCurrent,
      SetCurrentPlaylist,
      SetCurrentTimePlaylist,
      SetAudioPlayerPlaylist,
      toggleAudioPlayerPlaylist,
      togglePlayingPlaylist,
      SetPlayingPlaylist,
      SetEmptyPlaylist,
      prevSong,
      nextSong,
      resetCounter,
      toggleRandom,
      handleEnd
    }}>

      {props.children}
    </audioPlayerCtx.Provider>
  )
}
export default AudioPlayerProvider