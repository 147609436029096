import React from "react"
import LogoCover from "../../_assets/loghi/grm_logo_omino.png"

// Styles
import style from "./MessageCover.module.css"

const MessageCover = (props) => {
  return (
    <div className={style.container}>
      <div className={style.containerBg}>
        <img src={LogoCover} alt="Logo Grm Fun" width={100} />
      </div>
      <div className={style.messageContent}>
        <h4>{props.content}</h4>
      </div>
    </div>
  )
}
export default MessageCover