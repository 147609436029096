let audioPlayerReducer = (state, action) => {
  switch (action.type) {
    // Single track
    case 'SET_CURRENT_SONG':
      return {
        ...state,
        currentSong: action.data,
        playing: true,
        isAudioPlayer: true,
        isAudioPlayerPlaylist: false,
        currentTimePlaylist: 0,
        playingPlaylist: false,
      }
    case 'SET_CURRENT_TIME':
      return {
        ...state,
        currentTime: action.data,
      }
    case 'TOGGLE_PLAYING':
      return {
        ...state,
        playing: action.data,
      }
    case 'TOGGLE_AUDIOPLAYER':
      return {
        ...state,
        currentSong: "",
        playing: false,
        isAudioPlayer: action.data,
      }
    case 'SET_AUDIOPLAYER':
      return {
        ...state,
        currentSong: "",
        playing: false,
        isAudioPlayer: action.data,
      }
    case 'SET_SONGS_ARRAY':
      return {
        ...state,
        ...action.data,
        counter: 0
      }
    case 'SET_SONGS_ARRAY_CURRENT':
      return {
        ...state,
        songsListCurrent: action.data,
        playingPlaylist: true,
      }
    case 'SET_CURRENT_TIME_PLAYLIST':
      return {
        ...state,
        currentTimePlaylist: action.data,
      }
    case 'SET_AUDIOPLAYER_PLAYLIST':
      return {
        ...state,
        isAudioPlayerPlaylist: action.data,
      }
    case 'SET_PLAYING_PLAYLIST':
      return {
        ...state,
        playingPlaylist: action.data,
      }
    case 'SET_CURRENT_PLAYLIST':
      return {
        ...state,
        currentPlaylist: action.data,
        playingPlaylist: true,
        isAudioPlayerPlaylist: true
      }
    case 'SET_EMPTY_PLAYLIST':
      return {
        ...state,
        isEmptyPlaylist: action.data,
      }
    case 'TOGGLE_AUDIOPLAYER_PLAYLIST':
      return {
        ...state,
        songsListCurrent: {},
        playingPlaylist: false,
        isAudioPlayerPlaylist: action.data,
      }
    case 'TOGGLE_PLAYING_PLAYLIST':
      return {
        ...state,
        playingPlaylist: action.data,
      }
    case 'TOGGLE_RANDOM':
      return {
        ...state,
        random: action.data
      }
    default:
      return state
  }
}

export default audioPlayerReducer