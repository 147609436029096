import React, { useState } from "react"
import BtnPlayGreen from "../../_assets/icone/btnplay-green.svg";
import BtnPauseGreen from "../../_assets/icone/btnpause-green.svg";

// Styles
import style from "./BtnGreenPlaylist.module.css";

const BtnGreenPlaylist = (props) => {



  return (
    <div className={style.BtnBackground} onClick={props.onClick}>
      <img src={props.isPlaying ? BtnPauseGreen : BtnPlayGreen} alt="play" />
    </div>
  )
}
export default BtnGreenPlaylist