import React, { useState } from "react";
import StarEmpty from "../../_assets/icone/star-empty.svg";
import StarFull from "../../_assets/icone/star-full.svg";

// Styles
import style from "./BtnFav.module.css";

const BtnFav = ({ isFav, onClick }) => {
  return (
    <div className={style.BtnBackground} onClick={onClick}>
      <img src={isFav ? StarFull : StarEmpty} alt="play" />
    </div>
  );
};

//
BtnFav.defaultProps = {
  isFav: false,
  onClick: () => {},
};

export default BtnFav;
