import React, { useState, useContext } from "react";
import Title from "../../commons/Title";
import BtnFav from "../../buttons/BtnFav";
import BtnGreenPlaylist from "../../buttons/BtnGreenPlaylist";
import ExplicitLabel from "../../commons/ExplicitLabel";
import audioPlayerCtx from "../../../_store/audioPlayer/audioPlayerCtx";
import { success, warning } from "../../modals/PopupNotification";
import jwt_decode from "jwt-decode";

//Styles
import style from "./PlaylistHead.module.css";
import AuthContext from "../../../_store/auth-context";
import PopupAddBrano from "../../modals/PopupAddBrano";
import BtnDots from "../../buttons/BtnDots";
import { useNavigate } from "react-router-dom";
import PopupAlert from "../../modals/PopupAlert";
import PopupConferma from "../../modals/PopupConferma";
import PopupModificaPlaylistInfo from "../../modals/PopupModificaPlaylistInfo";

// Variabili statiche
const ownerPlaylist = "Selezione GRM";

//
const PlaylistHead = ({
  playlistName,
  playlistDescription,
  playlistExplicitContent,
  playlistCoverUrlPlaylist,
  playlistCoverNamePlaylist,
  playlistIsFavourite,
  playlistId,
  playlist,
  setSongs,
  songs,
}) => {
  //#region  context
  //
  const authCtx = useContext(AuthContext);

  //useContext
  const {
    SetCurrentPlaylist,
    SetSongsList,
    SetAudioPlayer,
    SetCurrentTime,
    SetCurrentTimePlaylist,
    SetEmptyPlaylist,
  } = useContext(audioPlayerCtx);

  //#endregion

  //#region UseState

  const [isFav, setIsFav] = useState(playlistIsFavourite === "true");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [playlistHeaderName, setplaylistHeaderName] = useState(playlistName);
  const [playlistHeaderDescription, setplaylistHeaderDescription] = useState(
    playlistDescription
  );
  const [playlistCoverName, setPlaylistCoverName] = useState(
    playlistCoverNamePlaylist
  );
  const [playlistCoverUrl, setPlaylistCoverUrl] = useState(
    playlistCoverUrlPlaylist
  );

  //#endregion

  let navigate = useNavigate();

  //#region Functions

  //
  const switchFav = () => {
    setIsFav((prev) => !prev);
  };

  const handlerDeletePlaylist = (PlaylistID) => {
    const deleteDataFromFavourites = async () => {
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    const deleteData = async () => {
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    const deleteCoverFromBlob = async () => {
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          Cover: playlistCoverName,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/cover_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    deleteCoverFromBlob();
    deleteDataFromFavourites();
    deleteData();
  };

  const removePlaylist = (e) => {
    //
    e.preventDefault();

    //
    handlerDeletePlaylist(playlistId);

    //
    //do something
    setIsPopupOpen3(false);
    navigate("/lamiamusica");
    success("playlist rimossa con successo", true);
  };

  //
  const postHanlderFavorite_Add = () => {
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistId,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  const postHanlderFavorite_Delete = () => {
    //
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistId,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  const postHanlderFavorite = () => {
    //
    switchFav();

    //
    if (!isFav) {
      postHanlderFavorite_Add();
    } else {
      postHanlderFavorite_Delete();
    }
  };

  const handlePlayPlaylist = (e) => {
    e.preventDefault();
    SetAudioPlayer(false);
    SetCurrentTime(0);
    SetCurrentPlaylist(playlist);
    if (playlist.Tracce && playlist.Tracce.length > 0) {
      SetEmptyPlaylist(false);
      SetSongsList(playlist.Tracce);
    } else {
      SetEmptyPlaylist(true);
      SetCurrentTimePlaylist(0);
      warning(
        "Al momento non è presente nessuna traccia in questa playlist",
        true
      );
    }
  };

  //#endregion

  //
  return (
    <div className={style.headerContainer}>
      <div className={style.headerContent}>
        <div className={style.headerContentText}>
          <div className={style.playlistType}>
            <h6>PLAYLIST</h6>
            {playlistExplicitContent === "Esplicito" && (
              <ExplicitLabel value={playlistExplicitContent} />
            )}
          </div>
          <Title
            title={playlistHeaderName}
            textTransform="capitalize"
            color="var(--white)"
          />
          <h6 className={style.playlistAuthor}>{ownerPlaylist}</h6>
          <div className={style.playlistBtn}>
            <BtnGreenPlaylist onClick={handlePlayPlaylist} />
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
              <div
                className={style.btnPlus}
                onClick={() => setIsPopupOpen(true)}
              ></div>
            )}
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
              <BtnDots
                dropdownWidth="200px"
                dropdownLeft="100px"
                width="60px"
                height="60px"
                background="var(--white)"
              >
                <ul>
                  <li>
                    <span onClick={() => setIsPopupOpen4(true)}>
                      <h6>modifica playlist</h6>
                    </span>
                  </li>
                  <li>
                    <span onClick={() => setIsPopupOpen2(true)}>
                      <h6>rimuovi playlist</h6>
                    </span>
                  </li>
                </ul>
              </BtnDots>
            )}
            {(authCtx.isAdmin === false || authCtx.isAdmin === "false") && (
              <BtnFav isFav={isFav} onClick={postHanlderFavorite} />
            )}
          </div>
          <h4 className={style.playlistDescription}>
            {" "}
            {playlistHeaderDescription}
          </h4>
        </div>
        <div
          className={style.headerImage}
          style={{ backgroundImage: `url(${playlistCoverUrl})` }}
        ></div>
      </div>
      {isPopupOpen && (
        <PopupAddBrano
          setIsOpen={setIsPopupOpen}
          setSongsPlaylist={setSongs}
          songsPlaylist={songs}
        />
      )}
      {isPopupOpen2 && (
        <PopupAlert
          setIsOpen={setIsPopupOpen2}
          onConfirmAlert={() => {
            setIsPopupOpen2(false);
            setIsPopupOpen3(true);
          }}
        />
      )}
      {isPopupOpen3 && (
        <PopupConferma
          setIsOpen={setIsPopupOpen3}
          header="Sei sicuro di voler rimuovere questa playlist?"
          onConfirm={removePlaylist}
        />
      )}
      {isPopupOpen4 && (
        <PopupModificaPlaylistInfo
          setIsOpen={setIsPopupOpen4}
          playlistName={playlistHeaderName}
          playlistDescription={playlistHeaderDescription}
          playlistCoverName={playlistCoverName}
          setPlaylistCoverName={setPlaylistCoverName}
          playlistCoverUrl={playlistCoverUrl}
          setPlaylistCoverUrl={setPlaylistCoverUrl}
          setPlaylistName={setplaylistHeaderName}
          setPlaylistDescription={setplaylistHeaderDescription}
          isPlaylistPrivate={playlist.Private}
        />
      )}
    </div>
  );
};
export default PlaylistHead;
