import React from "react";
import style from "./InputSearch.module.css";
import searchIcon from "../../_assets/icone/search.svg";
import closeIcon from "../../_assets/icone/closeSearch.svg";
import UseOutsideClick from "../../_hooks/UseOutsideClick";

const InputSearch = (props) => {


  const handleChange = (event) => {
    props.setSearch(event.target.value);
    if (event.target.value.length > 0) { 
      props.setIsSearching(true);
    } else {    
      props.setIsSearching(false);
    }
  };

  const ref = UseOutsideClick(props.handleClickOutside);

  const clearInput = () => {
    props.setSearch("");
    props.setIsSearching(false);
  };

  return (
    <label ref={ref} onClick={props.onClick} className={style.label} style={{ width: props.width }}>
      <span>
        {!props.isSearching && <img className="show" src={searchIcon} width={15} height={15} alt="Cerca una playlist" />}
        {props.isSearching && <img className="show close" onClick={clearInput} src={closeIcon} width={15} height={15} alt="Annulla ricerca" />}
      </span>
      {props.searchDisabled && <input disabled autoFocus={props.autofocus} type="text" placeholder={props.placeholder} onChange={handleChange} value={props.search} autoComplete="off" style={{ background: props.background, border: props.border, height: props.height }} />}      
      {!props.searchDisabled && <input autoFocus={props.autofocus} type="text" placeholder={props.placeholder} onChange={handleChange} value={props.search} autoComplete="off" style={{ background: props.background, border: props.border, height: props.height }} />}      
      <span></span>
    </label>
  );
};

InputSearch.defaultProps = {
  width: "300px",
  height: "60px",
  background: "var(--white)",
  border: "none",
  placeholder: "Cerca una playlist",
  searchDisabled: false,
  handleClickOutside: function doNothing() { }
};
export default InputSearch;
