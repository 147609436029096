import React from "react"

//Styles
import style from "./BtnOptions.module.css"

const BtnOptions = (props) => {

  return (
    <button onClick={props.onClick} className={`${style.btnContainer} ${props.isActive ? style.active : ""}`} >
      <h4>{props.placeholder}</h4>
    </button>
  )
}

BtnOptions.defaultProps = {
  id: "button",
  placeholder: "tutto",
  onClick: null
}
export default BtnOptions