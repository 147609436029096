import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./CarouselCollection.module.css";
import Slider from "react-slick";
import CardBig from "../cards/CardBig";

const CarouselCollection = (props) => {
  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 2,
    draggable: false
  };

  var _content = null;

  if (props.collection) {
    _content = props.playlists.map((item) =>
      item.CollezioneID === props.collection[1].CollezioneID ? (
        <CardBig
          key={item.PlaylistID}
          image={item.Cover_Playlist}
          itemsName={item.Nome_Playlist}
          itemsDesc={item.Descrizione_Playlist}
          idPlaylist={item.PlaylistID}
        />
      ) : null
    );
  }

  return (
    <div className={style.sliderContainer}>
      <h3>{props.collection[1].Nome_Collezione}</h3>
      <h4>{props.collection[1].Descrizione_Collezione}</h4>
      {_content && (
        <Slider className={style.slider} {...settings}>
          {_content}
        </Slider>
      )}
      {!_content && "Non sono presenti playlist"}
    </div>
  );
};
export default CarouselCollection;
