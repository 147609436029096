import { useContext, useEffect } from "react";

import AuthContext from "../_store/auth-context";

const Logout = () => {
  //
  const ctxAuth = useContext(AuthContext);

  //onPageLoad
  useEffect(() => {
    //
    ctxAuth.logout();

    // window.location.replace("http://test.liberobit.biz:8011/grm_v3_01/master/Utilizzatore/Login.aspx?logout=1");
    window.location.replace("https://grm-app.it//Utilizzatore/Login.aspx?logout=1");    
  }, []);

  //
};

export default Logout;
