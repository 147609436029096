import React, { useContext, useEffect, useState } from "react";

import InputSearch from "../_components/commons/InputSearch";
import Loader from "../_components/commons/Loader";
import CategorieRicerca from "../_components/containers/CategorieRicerca";
import LabelContainer from "../_components/containers/LabelContainer";
import Footer from "../_components/layout/Footer";
import AuthContext from "../_store/auth-context";
import jwt_decode from "jwt-decode";

//Style
import style from "./categorie.module.css";
import CardSmall from "../_components/cards/CardSmall";
import LaMiaMusicaTabOption from "../_components/containers/LaMiaMusicaTabOption";
import BtnGreen from "../_components/buttons/BtnGreen";
import MessageCover from "../_components/commons/MessageCover";
import PopupCreaPlaylist from "../_components/modals/PopupCreaPlaylist";
import { success, warning } from "../_components/modals/PopupNotification";
import PopupAlert from "../_components/modals/PopupAlert";
import PopupConferma from "../_components/modals/PopupConferma";

const Categorie = () => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  const DefaultCollectionID = 4;
  //#endregion

  //#region UseState

  // const [songs, setSongs] = useState(null);
  const [playlists, setPlaylists] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState("300px");
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [playlistToDeleteID, setPlaylistToDeleteID] = useState(null);
  const [playlistToDeleteName, setPlaylistToDeleteName] = useState(null);
  const [isPlaylistPrivate, setIsPlaylistPrivate] = useState(false);
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);

  //#endregion

  //
  let _content = null;

  //#region functions

  //filtro ricerca playlist
  const filterSearch = (item) => {
    if (item.Nome.toLowerCase().includes(search.toLowerCase())) {
      return item;
    } else {
      return;
    }
  };

  //Animazione search bar
  function handleWidth() {
    setIsSearchActive("100%");
  }

  const handlerDeletePlaylist = () => {
    
    const POSTdeleteFromFavourites = async () => {
      try {
        
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: playlistToDeleteID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_delete",
          options
        );

        //const data = await response.json();
        
        success("Elemento rimosso con successo", true);
        
        setPlaylists((prev) => [
          ...prev.filter((item) => item.PlayListID != playlistToDeleteID),
        ]);
      } catch (error) {
      }
    };

    const POSTdeleteFromCollezioniPlaylist = async () => {
      try {
        
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: playlistToDeleteID,
          CollectionID: null,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/Playlist/collection_delete",
          options
        );
        
      } catch (error) {
      }
    };

    const POSTdeletePlaylist = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistToDeleteID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    const POSTdeleteCoverFromBlob = async () => {
      try {        
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          Cover: playlistToDeleteName,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/cover_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };


    //
    POSTdeleteFromFavourites();
    
    //cancellazione playlist
    POSTdeletePlaylist()

    // cancellazione relazione con collezione
    POSTdeleteFromCollezioniPlaylist();
    
    //cancellazione copertina da blob
    POSTdeleteCoverFromBlob();
  };

  const POSTHanlderFavorite_Add = (PlaylistID) => {
    const POSTaddPlaylistToFavourite = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    POSTaddPlaylistToFavourite();
  };

  const handlerAddPlaylist = (PlaylistName, PlaylistDescription) => {
    //
    const POSTaddCoverToPlaylist = async () => {
      //
      try {
        let bodyData = new FormData();
        bodyData.append(
          "File",
          document.getElementById("playerCover").files[0]
        );
        bodyData.append("Playlist_name", PlaylistName.replace(" ", "_"));
        //
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: bodyData,
        };        

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/utilizzatore/playlist/cover_add",
          options
        );

        const data = await response.json();

        //ritorna l'url
        return data;
      } catch (error) {}
    };

    //
    const POSTaddPlaylistCollezioni = async (PlaylistID) => {
      //
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],          
          CollectionID: DefaultCollectionID,
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/Utilizzatore/Playlist/collection_add",
          options
        );

        const data = await response.json();

        setTimeout(() => {}, "200");
      } catch (error) {}
    };

    //
    const POSTupdatePlaylist = async (
      PlaylistID,
      Name,
      Description,
      CoverUrlSharing
    ) => {      
      //
      try {        
        
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
          PlaylistName: Name,
          PlaylistDescription: Description,
          Cover:
            Name.toLowerCase().replace(" ", "_") +
            "_" +
            document.getElementById("playerCover").files[0].name,
          CoverUrlSharing: CoverUrlSharing.slice(1, -1),
          Private: isPlaylistPrivate.toString(),
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
               "Bearer " +
                authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/Utilizzatore/playlist_update",
          options
        );

        const data = await response.json();

        setTimeout(() => {}, "200");
      } catch (error) {}
    };

    //
    const addPlaylist = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistName: PlaylistName,
          PlaylistDescription: PlaylistDescription,
          Private: isPlaylistPrivate.toString(),
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_add",
          options
        );

        //ritorna Id della playlist creata
        const data = await response.json();

        //Aggiunge la playlist a una collezione
        POSTaddPlaylistCollezioni(Math.trunc(data));

        //Aggiunge la nuova playlist alla sezione "Mia Musica"
        POSTHanlderFavorite_Add(Math.trunc(data));

        //Carica la cover sul blob
        let _coverUrl = await POSTaddCoverToPlaylist();

        //update del record della playlist aggiungendo il nome del file e la cover
        POSTupdatePlaylist(
          Math.trunc(data),
          PlaylistName,
          PlaylistDescription,
          _coverUrl
        );

        //Aggiunge la playlist appena creata in locale
        if (
          document.getElementById("playerCover") !== undefined &&
          document.getElementById("playerCover") !== null &&
          document.getElementById("playerCover").files[0] !== undefined &&
          document.getElementById("playerCover").files[0] !== null
        )
          setPlaylists((prev) => [
            ...prev,
            {
              PlayListID: Math.trunc(data),
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              // Playlist_CompanyID: process.env.REACT_APP_COMPANYID_USER,
              Playlist_CompanyID: jwt_decode(authCtx.token).unique_name.split(
                "|"
              )[2],
              Nome: PlaylistName,
              Descrizione: PlaylistDescription,
              CoverUrlSharing: _coverUrl,
              Cover:
                PlaylistName +
                "_" +
                document.getElementById("playerCover").files[0].name,
              tag: null,
            },
          ]);
        else
          setPlaylists((prev) => [
            ...prev,
            {
              PlayListID: Math.trunc(data),
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              Playlist_CompanyID: jwt_decode(authCtx.token).unique_name.split(
                "|"
              )[2],
              Nome: PlaylistName,
              Descrizione: PlaylistDescription,
              tag: null,
            },
          ]);

        setIsPopupOpen(false);
        success("Nuova playlist salvata con successo", true);
      } catch (error) {}
    };
    
    //
    const _isValidName = PlaylistName != undefined && PlaylistName.length > 0
    const _isValidDescription = PlaylistDescription != undefined && PlaylistDescription.length > 0

    if(_isValidName && _isValidDescription){
      addPlaylist();
    }else{
      warning("Attenzione, tutti i campi devono essere compilati", true)
    }

  };

  //#endregion

  //onPageLoad
  useEffect(() => {

    //
    const fetchDataPlaylists = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_list?" +
            new URLSearchParams({
              All: 0,
              Private: false,
              PlaylistID: "",
              CompanyID: process.env.REACT_APP_COMPANYID_DJ,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );

        const data = await response.json();

        //
        setPlaylists(JSON.parse(data));
        setLoading(false);
      } catch (error) {}
    };

    fetchDataPlaylists();
    setTimeout(() => {
      handleWidth();
    }, "200");
  }, []);

  const fetchReturnNullObject = !playlists;

  //
  if (!fetchReturnNullObject.valueOf() && fetched) {
    //
    _content = (
      <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna playlist" />
    );
  }

  if (isSearching && playlists && playlists.filter(filterSearch).length > 0) {
    _content = playlists.filter(filterSearch).map((playlist) => (
      <CardSmall
        key={playlist.PlayListID}
        image={playlist.CoverUrlSharing}
        private={playlist.Private}
        itemsName={playlist.Nome}
        itemsDesc={playlist.Descrizione}
        idPlaylist={playlist.PlayListID}
        btnContent={
          <li>
            <span
              onClick={() => {           
                setPlaylistToDeleteID(playlist.PlayListID);
                setPlaylistToDeleteName(playlist.Cover);                
                setIsPopupOpen2(true);
              }}
            >
              <h6>Rimuovi playlist</h6>
            </span>
          </li>
        }
      />
    ));
  } else {
    _content = (
      <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna playlist" />
    );
  }

  if (!isSearching && playlists && playlists.filter(filterSearch).length > 0) {
    _content = playlists.map((playlist) => (
      <CardSmall
        key={playlist.PlayListID}
        image={playlist.CoverUrlSharing}
        itemsName={playlist.Nome}
        itemsDesc={playlist.Descrizione}
        idPlaylist={playlist.PlayListID}
        btnContent={
          <li>
            <span
              onClick={() => {
                setPlaylistToDeleteID(playlist.PlayListID);
                setPlaylistToDeleteName(playlist.Cover);                
                setIsPopupOpen2(true);
              }}
            >
              <h6>Rimuovi playlist</h6>
            </span>
          </li>
        }
      />
    ));
  }
  return (
    <>
      {/*  */}
      {loading && <Loader />}

      {!loading && (
        <div className="container">
          {/*  */}
          <div className={style.searchBar}>
            <InputSearch
              autofocus={true}
              width={isSearchActive}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              setSearch={setSearch}
              search={search}
            />
          </div>

          {/* rende visibile il bottone di creazione di una nuova playlist pubblica solo all'amministratore */}
          {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
            <div className={style.createPlaylist_box}>
              <BtnGreen
                onClick={() => setIsPopupOpen(true)}
                // placeholder={btnGreenPlaceholder}
              />
            </div>
          )}

          {/*  */}
          {/* {isSearching && (
            // <CategorieRicerca
            //   songs={songs}
            //   playlists={playlists}
            //   searchQuery={search}
            // />
            <></>
          )} */}
          <div className={style.playlists_box}>{_content}</div>
        </div>
      )}
      {isPopupOpen && (        
        <PopupCreaPlaylist
          setIsOpen={setIsPopupOpen}
          onConfirm={handlerAddPlaylist}
          isPlaylistPrivate={isPlaylistPrivate}
          setIsPlaylistPrivate={setIsPlaylistPrivate}
        />
      )}
      {isPopupOpen2 && (
        <PopupAlert
          setIsOpen={setIsPopupOpen2}
          onConfirmAlert={() => {
            setIsPopupOpen2(false);
            setIsPopupOpen3(true);
          }}
        />
      )}
      {isPopupOpen3 && (
        <PopupConferma
          setIsOpen={setIsPopupOpen3}
          onConfirm={() => {
            setIsPopupOpen3(false);
            handlerDeletePlaylist();
          }}
        />
      )}
      <Footer />
    </>
  );
};

export default Categorie;
