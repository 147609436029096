//https://www.robinwieruch.de/react-hook-detect-click-outside-component/

import { useRef, useEffect } from "react"

const UseOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref])

  return ref
}
export default UseOutsideClick