import React, { useState, useEffect } from "react";

// Styles
import style from "./Tabs.module.css"

function Tabs(props) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };



  return (
    <div className={style.tabsContainer}>

      <div className={style.blocTabs}>
        <button className={toggleState === 1 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(1)} >
          <h4>aggiunte</h4>
        </button>
        <button className={toggleState === 2 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(2)} >
          <h4>programmazione</h4>
        </button>
        <button className={toggleState === 3 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(3)}>
          <h4>pubblicità</h4>
        </button>
      </div>

      <div className={style.contentTabs}>
        <div className={toggleState === 1 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tab1}
        </div>

        <div className={toggleState === 2 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tab2}
        </div>

        <div className={toggleState === 3 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tab3}
        </div>
      </div>

    </div >
  );
}

export default Tabs;