import React, { useContext, useState } from "react";
import BtnDots from "../buttons/BtnDots";
import PopupConferma from "../modals/PopupConferma";
import CoverDispositivo from "../../_assets/immagini/cover-dispositivo.png";
import { success, warning } from "../modals/PopupNotification";

// Styles
import style from "./RowDispositivi.module.css";
import AuthContext from "../../_store/auth-context";

const RowDispositivi = (props) => {
  //Context
  const authCtx = useContext(AuthContext);

  //useState
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);

  const unpairDevice = () => {
    const removeDeviceFromPlayer = async () => {
      try {
        //
        const params = {
          DeviceID: props.ID,
          PlayerID: props.PlayerID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/Utilizzatore/player/device_delete",
          options
        );

        const data = await response.json();

        //
        if (response.status === 200) {
          //
          props.setDeviceUpdateDelete(props.ID);
          success("Dispositivo dissociato con successo", true);
        } else {
          //
          warning(
            "Si è verificato un problema, si prega di riprovare più tardi",
            true
          );
        }
      } catch (error) {}
    };

    //
    removeDeviceFromPlayer();

    setIsPopupOpen(false);
  };

  function disabilitaDispositivo() {
    const disableDeviceFromPlayer = async () => {
      try {
        //
        const params = {
          DeviceID: props.ID,
          PlayerID: props.PlayerID,
          DeviceCode: null,
          DeviceDescription: props.DeviceDescription,
          Telephone: props.DeviceTelephone,
          Email: props.DeviceEmail,
          Geolocation: "false",
          Status: "2",
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/Utilizzatore/player/device_update",
          options
        );

        const data = await response.json();

        //
        if (response.status === 200) {
          //
          props.setDeviceStatusUpdate(props.ID, "2");
          success("Dispositivo disabilitato con successo", true);
        } else {
          //
          warning(
            "Si è verificato un problema, si prega di riprovare più tardi",
            true
          );
        }
      } catch (error) {}
    };

    //
    disableDeviceFromPlayer();

    setIsPopupOpen2(false);
  }

  function abilitaDispositivo() {
    const enableDeviceFromPlayer = async () => {
      try {
        //
        const params = {
          DeviceID: props.ID,
          PlayerID: props.PlayerID,
          DeviceCode: null,
          DeviceDescription: props.DeviceDescription,
          Telephone: props.DeviceTelephone,
          Email: props.DeviceEmail,
          Geolocation: "false",
          Status: "0",
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/Utilizzatore/player/device_update",
          options
        );

        const data = await response.json();

        //
        if (response.status === 200) {
          //
          props.setDeviceStatusUpdate(props.ID, "0");
          success("Dispositivo abilitato con successo", true);
        } else {
          //
          warning(
            "Si è verificato un problema, si prega di riprovare più tardi",
            true
          );
        }
      } catch (error) {}
    };

    //
    enableDeviceFromPlayer();

    setIsPopupOpen3(false);
  }

  let _deviceDescription = props.DeviceDescription;
  const _deviceEmail = props.DeviceEmail;
  const _DeviceDescriptionWithoutEmail =_deviceDescription.replace(_deviceEmail, '')

  let _formattedDate = "attivato il " + new Date(props.CreatedDate).toLocaleTimeString('it-IT', {year: 'numeric', month: 'numeric', day: 'numeric'}). replace(',', ' alle') 
  return (
    <div className={style.row}>
      <div className={style.rowHead}>
        <div class={style.tooltip}>
          <div
            className={`${style.deviceStatus} ${
              props.isActive == 0
                ? style.pending
                : props.isActive == 1
                ? style.active
                : props.isActive == 2
                ? style.notActive
                : style.notActive
            }`}
          ></div>
          <span class={style.tooltiptext}>
            {props.isActive == 0
              ? "In Attesa"
              : props.isActive == 1
              ? "Attivo"
              : props.isActive == 2
              ? "Disabilitato"
              : "Disabilitato"}{" "}
          </span>
        </div>
        {/* <div className={style.rowSubHeadImage}>
          <img
            src={CoverDispositivo}
            width={50}
            height={50}
            alt="Immagine dispositivo"
          />
        </div> */}
        <div className={style.rowSubHeadTitle}>
          <div>
            <h4>{props.DeviceID} - {props.DeviceOS ? (props.DeviceOS + " - ") : ""} {props.DeviceTelephone} - {_formattedDate} - {_DeviceDescriptionWithoutEmail} - {props.DeviceEmail}</h4>            
          </div>
          {/* <div className={style.rowSubHeadTitle}>
            <h4>-</h4>
            <h4 className={style.regular}>Telefono: {props.DeviceTelephone}</h4>
          </div> */}
        </div>
      </div>
      <div className={style.rowTail}>
        <BtnDots>
          <ul>
            {props.isActive != 2 && (
              <li>
                <span onClick={() => setIsPopupOpen2(true)}>
                  <h6>disabilita</h6>
                </span>
              </li>
            )}
            {props.isActive == 2 && (
              <li>
                <span onClick={() => setIsPopupOpen3(true)}>
                  <h6>abilita</h6>
                </span>
              </li>
            )}
            {
              <li>
                <span
                  onClick={() => {
                    props.setToggleState(5);
                  }}
                >
                  <h6>log</h6>
                </span>
              </li>
            }
          </ul>
        </BtnDots>
      </div>
      {/* {isPopupOpen && <PopupConferma header="Sei sicuro di voler dissociare questo dispositivo?" setIsOpen={setIsPopupOpen} onConfirm={unpairDevice} />} */}
      {isPopupOpen2 && (
        <PopupConferma
          header="Sei sicuro di voler disabilitare questo dispositivo?"
          setIsOpen={setIsPopupOpen2}
          onConfirm={disabilitaDispositivo}
        />
      )}
      {isPopupOpen3 && (
        <PopupConferma
          header="Sei sicuro di voler abilitare questo dispositivo?"
          setIsOpen={setIsPopupOpen3}
          onConfirm={abilitaDispositivo}
        />
      )}
    </div>
  );
};
export default RowDispositivi;
