import React, { useState, useEffect } from "react";
import PlaylistCustomHead from "./PlaylistCustomHead";
import PlaylistCustomBody from "./PlaylistCustomBody";


const PlaylistCustomContainer = (playlist) => {  
  //todo verificare il perchè
  const _playlist = playlist["playlist"];
  
  let _name, _id;

  //#region UseState

  const [songs, setSongs] = useState(_playlist.Tracce);

  //#endregion

  // #region Variables

  if (_playlist.Name !== undefined) _name = _playlist.Name;
  else _name = _playlist.Nome;

  if (_playlist.Id !== undefined) _id = _playlist.Id;
  else _id = _playlist.PlayListID;

  //#endregion

  return (
    <>
      <PlaylistCustomHead
        playlist={_playlist}
        playlistName={_name}
        playlistDescription={_playlist.Descrizione}
        playlistExplicitContent={_playlist.TipologiaContenuto}
        playlistCoverUrl={_playlist.CoverUrlSharing}
        playlistId={_id}
        setSongs={setSongs}
        songs={songs}
      />    
      <PlaylistCustomBody
        songs={songs}
        setSongs={setSongs}
        lastUpdate={_playlist.UltimoAggiornamento}
        hours={_playlist.DurataOre}
        minutes={_playlist.DurataMinuti}
        seconds={_playlist.DurataSecondi}           
      />
    </>
  );
};
export default PlaylistCustomContainer;
