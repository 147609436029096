import React, { useState, useContext } from "react";
import Title from "../../commons/Title";
import ExplicitLabel from "../../commons/ExplicitLabel";
import CoverImage from "../../../_assets/icone/custom-playlist.svg";
import BtnDots from "../../buttons/BtnDots";
import BtnGreenPlaylist from "../../buttons/BtnGreenPlaylist";
import PopupAddBrano from "../../modals/PopupAddBrano";
import PopupModificaPlaylistInfo from "../../modals/PopupModificaPlaylistInfo";
import PopupConferma from "../../modals/PopupConferma";
import PopupAlert from "../../modals/PopupAlert";
import { useNavigate } from "react-router-dom";
import audioPlayerCtx from "../../../_store/audioPlayer/audioPlayerCtx";
import { success } from "../../modals/PopupNotification";
import { warning } from "../../modals/PopupNotification";
import jwt_decode from "jwt-decode";

//Styles
import style from "./PlaylistCustomHead.module.css";
import AuthContext from "../../../_store/auth-context";

// Variabili statiche
// style={{ backgroundImage: `url(${playlistCoverUrl})` }}
const ownerPlaylist = "Utente Test";
const explicit = "ESPLICITA";

const PlaylistHead = ({
  playlistName,
  playlistDescription,
  playlistExplicitContent,
  playlistCoverUrl,
  playlistId,
  playlist,
  setSongs,
  songs,
}) => {
  //#region  context  

  const image =
    playlistCoverUrl !== undefined && playlistCoverUrl !== null
      ? playlistCoverUrl
      : CoverImage;
  const imageIsNotDefault =
    playlistCoverUrl !== undefined && playlistCoverUrl !== null ? true : false;
  //
  const authCtx = useContext(AuthContext);

  //useContext
  const {
    SetCurrentPlaylist,
    SetSongsList,
    SetAudioPlayer,
    SetCurrentTime,
    SetCurrentTimePlaylist,
    SetEmptyPlaylist,
  } = useContext(audioPlayerCtx);

  //#endregion

  //#region UseState

  const [playlistHeaderName, setplaylistHeaderName] = useState(playlistName);
  const [playlistHeaderDescription, setplaylistHeaderDescription] = useState(
    playlistDescription
  );

  //
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);

  //
  const [isRandom, setIsRandom] = useState(true);

  //#endregion

  let navigate = useNavigate();

  //#region Functions

  const handlerDeletePlaylist = (PlaylistID) => {
    const deleteDataFromFavourites = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    const deleteData = async () => {
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    deleteDataFromFavourites();
    deleteData();
  };

  const removePlaylist = (e) => {
    //
    e.preventDefault();

    //
    handlerDeletePlaylist(playlistId);

    //
    setIsPopupOpen3(false);
    navigate("/lamiamusica");
    success("playlist rimossa con successo", true);
  };

  const test = () => {
    setIsRandom((prev) => !prev);
  };

  const handlePlayPlaylist = (e) => {
    e.preventDefault();
    SetAudioPlayer(false);
    SetCurrentTime(0);
    SetCurrentPlaylist(playlist);
    SetCurrentPlaylist(playlist);
    if (playlist.Tracce && playlist.Tracce.length > 0) {
      SetEmptyPlaylist(false);
      SetSongsList(playlist.Tracce);
    } else {
      SetEmptyPlaylist(true);
      SetCurrentTimePlaylist(0);
      warning(
        "Al momento non è presente nessuna traccia in questa playlist",
        true
      );
    }
  };
  //#endregion

  return (
    <div className={style.headerContainer}>
      <div className={style.headerContent}>
        <div className={style.headerContentText}>
          <div className={style.playlistType}>
            <h6>PLAYLIST</h6>
            {playlistExplicitContent === "Esplicito" && (
              <ExplicitLabel value={playlistExplicitContent} />
            )}
          </div>
          <Title
            title={playlistHeaderName}
            textTransform="capitalize"
            color="var(--white)"
          />
          <h6 className={style.playlistAuthor}>{ownerPlaylist}</h6>
          <div className={style.playlistBtn}>
            <BtnGreenPlaylist onClick={handlePlayPlaylist} />
            {/* se la company id loggata non corrisponde con quella della playlist aperta */}
            {(playlist.Playlist_CompanyID === undefined ||
              jwt_decode(authCtx.token).unique_name.split("|")[2] ==
                playlist.Playlist_CompanyID) && (
              <div
                className={style.btnPlus}
                onClick={() => setIsPopupOpen(true)}
              ></div>
            )}
            <BtnDots
              dropdownWidth="200px"
              dropdownLeft="100px"
              width="60px"
              height="60px"
              background="var(--white)"
            >
              <ul>
                {(playlist.Playlist_CompanyID === undefined ||
                  jwt_decode(authCtx.token).unique_name.split("|")[2] ==
                    playlist.Playlist_CompanyID) && (
                  <li>
                    <span onClick={() => setIsPopupOpen2(true)}>
                      <h6>modifica playlist</h6>
                    </span>
                  </li>
                )}
                <li>
                  <span onClick={() => setIsPopupOpen3(true)}>
                    <h6>rimuovi playlist</h6>
                  </span>
                </li>
                <li>
                  <span onClick={test} className={style.playOption}>
                    <h6 className={isRandom ? style.checked : ""}>
                      riproduzione casuale
                    </h6>
                  </span>
                </li>
              </ul>
            </BtnDots>
          </div>
          <h4 className={style.playlistDescription}>
            {" "}
            {playlistHeaderDescription}
          </h4>
        </div>
        {imageIsNotDefault && (
          <div
            className={style.headerImageBackground}
            style={{ backgroundImage: `url(${playlistCoverUrl})` }}
          ></div>
        )}
        {!imageIsNotDefault && (
          <div className={style.headerImage}>
            <img src={image} width={150} height={150} alt="Playlist Cover" />
          </div>
        )}
      </div>
      {isPopupOpen && (
        <PopupAddBrano
          setIsOpen={setIsPopupOpen}
          setSongsPlaylist={setSongs}
          songsPlaylist={songs}
        />
      )}
      {isPopupOpen2 && (
        <PopupModificaPlaylistInfo
          setIsOpen={setIsPopupOpen2}
          playlistName={playlistHeaderName}
          playlistDescription={playlistHeaderDescription}
          setPlaylistName={setplaylistHeaderName}
          setPlaylistDescription={setplaylistHeaderDescription}
        />
      )}
      {/* {isPopupOpen3 && (
        <PopupConferma
          header="Sei sicuro di voler rimuovere questa playlist?"
          setIsOpen={setIsPopupOpen3}
          onConfirm={removePlaylist}
        />
      )} */}
      {isPopupOpen3 && (
        <PopupAlert
          setIsOpen={setIsPopupOpen3}
          onConfirmAlert={() => {
            setIsPopupOpen3(false);
            setIsPopupOpen4(true);
          }}
        />
      )}
      {isPopupOpen4 && (
        <PopupConferma
          setIsOpen={setIsPopupOpen4}
          header="Sei sicuro di voler rimuovere questa playlist?"
          onConfirm={removePlaylist}
        />
      )}
    </div>
  );
};
export default PlaylistHead;
