import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import arrowBottom from "../../_assets/icone/arrow-bottom.svg";
import arrowTop from "../../_assets/icone/arrow-top.svg";
import AuthContext from "../../_store/auth-context";

//Styles
import styles from "./BtnProfile.module.css";

const BtnProfile = () => {

  //#region useState
  
  //
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();
  
  //#endregion

  const authCtx = useContext(AuthContext);

  let accountName = "";
  let accountEmail = "";
  
  //
  if(authCtx.name.length > 0)
    accountName = authCtx.name;
  else
    accountName = localStorage.getItem("name");
 
  //  
  if(authCtx.referenteEmail && authCtx.referenteEmail.length > 0)
    accountEmail = authCtx.referenteEmail;
  else
    accountEmail = localStorage.getItem("referenteEmail");

  //onPageLoad
  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.composedPath()[0] !== btnRef.current) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);

  return (
    <>
      <button className={styles.btn} ref={btnRef} onClick={() => setIsOpen((prev) => !prev)}>
        <h4 className="ellipsis">{accountName}</h4>
        <img src={isOpen ? arrowTop : arrowBottom} alt="account-options" />
      </button>
      <div className={styles.dropdown + " " + (isOpen ? styles.open : styles.close)}>
        {/* <h5>{accountName}</h5>
        <ul>
          <li>
            <Link to="/lamiamusica">
              <h6>La mia musica</h6>
            </Link>
          </li>
          <li>
            <Link to="/">
              <h6>Impostazioni account</h6>
            </Link>
          </li>
        </ul>
        <div className={styles.separator}></div> */}
        <h5>{accountEmail}</h5>
        <ul>
          <li>
            <Link to="/impostazioni">
              <h6>Impostazioni</h6>
            </Link>
          </li>
          <li>
            <span style={{ cursor: "pointer" }} onClick={() => alert("Verrà integrato un sistema di ticketing con zoho desk")} >
              <h6>Supporto</h6>
            </span>
          </li>
          <li>
            <Link to="/logout">
              <span>                
                {/* <a href="http://test.liberobit.biz:8011/grm_v3_01/master/Utilizzatore/Login.aspx?logout=1"  rel="noreferrer" style={{ cursor: "pointer", fontSize:"14px", fontFamily:"Poppins, sans-serif", color:"gray", fontWeight:"bold"}}> */}
                <a href="https://grm-app.it//Utilizzatore/Login.aspx?logout=1"  rel="noreferrer" style={{ cursor: "pointer", fontSize:"14px", fontFamily:"Poppins, sans-serif", color:"gray", fontWeight:"bold"}}>                
                Disconnetti
                </a>
              </span>
              {/* <h6>Disconnetti</h6> */}
            </Link>
          </li>
        </ul>
      </div >
    </>
  )
};
export default BtnProfile;
