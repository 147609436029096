import React from "react";
import { NavLink } from "react-router-dom";
import BtnProfile from "../buttons/BtnProfile";
import styles from "./Nav.module.css";

const Nav = () => {
  return (
    <ul className={styles.nav}>
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      {/* <li>
        <NavLink to="/prog">ProgTest</NavLink>
      </li> */}
      <li>
        <NavLink end to="/categorie">
          Playlist
        </NavLink>
      </li>
      <li>
        <NavLink to="/lamiamusica">La Mia Musica</NavLink>
      </li>
      <li>
        <NavLink to="/canali">Canali</NavLink>
      </li>
      <li style={{ height: 35 }}>
        <BtnProfile />
      </li>
    </ul>
  );
};
export default Nav;
