import React, { useState, useEffect, useContext, useRef } from "react"
import { createPortal } from "react-dom"
import IconClose from "../../_assets/icone/close.svg"
import IconPlay from "../../_assets/icone/play-icon-player.svg"
import IconPause from "../../_assets/icone/pause-icon-player.svg"
import audioPlayerCtx from "../../_store/audioPlayer/audioPlayerCtx";

// Styles
import style from "./MusicPlayerPlaylist.module.css"

const MusicPlayerPlaylist = () => {
  // useContext
  const {
    songsList,
    currentTimePlaylist,
    songsListCurrent,
    isAudioPlayerPlaylist,
    playingPlaylist,
    toggleAudioPlayerPlaylist,
    togglePlayingPlaylist,
    SetCurrentPlaylist,
    SetCurrentTimePlaylist,
    nextSong,
    SetPlayingPlaylist,
    isEmptyPlaylist
  } = useContext(audioPlayerCtx)

  //useState
  const [dur, setDuration] = useState(0)
  const [prevSongUrl, setPrevSongUrl] = useState("")

  // useRef
  let audioRefPlaylist = useRef()
  let progressBarPlaylist = useRef()

  // set how many seconds play the audio preview 
  let previewTime = 5;

  // // toggle audio play/pause
  // const toggleAudio = () => {
  //   if (!playingPlaylist) {
  //     console.log("if")
  //     SetCurrentTimePlaylist(0)
  //     audioRefPlaylist.current.paused ? audioRefPlaylist.current.play() : audioRefPlaylist.current.pause()
  //   }
  //   audioRefPlaylist.current.play()
  //   // audioRefPlaylist.current.paused ? audioRefPlaylist.current.play() : audioRefPlaylist.current.pause()
  // }

  const toggleAudio = () => {
    audioRefPlaylist.current.paused ? audioRefPlaylist.current.play() : audioRefPlaylist.current.pause()
  }

  // reset data when the music player is closed
  const handleCloseMusicPlayer = () => {
    SetCurrentTimePlaylist(0)
    SetCurrentPlaylist({})
    togglePlayingPlaylist()
    toggleAudioPlayerPlaylist()
  }

  // update progressbarPlaylist ui when song is playing
  // reset data when currentSong change
  useEffect(() => {
    if (playingPlaylist && isEmptyPlaylist === false) {
      setPrevSongUrl(songsListCurrent.track.SongUrl)
      progressBarPlaylist.current.style.setProperty('--seek-before-width', currentTimePlaylist / previewTime * 100 + "%")
      if (currentTimePlaylist === 0) {
        audioRefPlaylist.current.play()
      }
      else if (currentTimePlaylist > previewTime) {
        audioRefPlaylist.current.play()
        togglePlayingPlaylist()
        SetCurrentTimePlaylist(0)
        audioRefPlaylist.current.currentTime = 0
        if (songsList.length - 1 !== songsListCurrent.position) {
          nextSong()
        } else {
          // Reset
          audioRefPlaylist.current.pause()
          togglePlayingPlaylist()
          SetCurrentTimePlaylist(0)
          SetCurrentPlaylist({})
          togglePlayingPlaylist()
        }
      }
    }
  }, [songsListCurrent, currentTimePlaylist, playingPlaylist])

  // Restart audio if same url
  useEffect(() => {

    if (playingPlaylist && songsListCurrent.track.SongUrl === prevSongUrl) {      
      SetPlayingPlaylist(true)
      audioRefPlaylist.current.currentTime = 0
    }
  }, [songsListCurrent])

  if (isAudioPlayerPlaylist && isEmptyPlaylist === false) {
    
    //
    return createPortal(
      <div className={`${style.container} ${isAudioPlayerPlaylist ? style.slideIn : style.slideOut}`} >
        <audio
          ref={audioRefPlaylist}
          onTimeUpdate={(e) => SetCurrentTimePlaylist(e.target.currentTime)}
          onCanPlay={(e) => setDuration(e.target.duration)}
          preload="true"
          src={songsListCurrent.track.SongUrl}
        />
        <div>
          <input
            type="range" />
          <span
            ref={progressBarPlaylist}
            className={style.progressBarPlaylist}>
          </span>
        </div>

        <div className={style.containerContent}>
          <div className={style.head}>
            {/* <button onClick={() => { toggleAudio(); togglePlayingPlaylist() }}> */}
            <button onClick={() => { toggleAudio(); togglePlayingPlaylist() }}>
              {playingPlaylist ? <img src={IconPlay} alt="play" /> : <img src={IconPause} alt="pause" />}
            </button>
            <div className={style.headText}>
              <h4>Anteprima Playlist</h4>
              <div className={style.headTextDetails}>
                <h5>{songsListCurrent.track.Registrazioni_Titolo}</h5>
                <h5>- {songsListCurrent.track.SoggettiAIE_NomeArtistico}</h5>
              </div>

            </div>

          </div>
          <div className={style.tail}>
            {/* <button className={style.btnAggiungi}><h5>aggiungi playlist</h5></button> */}
            <button onClick={() => handleCloseMusicPlayer()} className={style.btnClose}>
              <img width={15} height={15} src={IconClose} alt="chiudi popup player" />
            </button>
          </div>
        </div>
      </div>
      , document.getElementById('root-player-playlist'))
  } else return null;
}
export default MusicPlayerPlaylist

