import React, { useState } from "react";
import IconPlusGreen from "../../_assets/icone/plus-green.svg"
import BtnGreen from "../buttons/BtnGreen";
import IconExport from "../../_assets/icone/export.svg";
// Styles
import style from "./TabsPlayer.module.css"
import { warning } from "../modals/PopupNotification";

function Tabs(props) {  


  //#region functions
  
  //
  const toggleTab = (index) => {
    props.setToggleState(index);
  };
  
  //#endregion



  return (
    <div className={style.tabsContainer}>
      <div className={style.blocTabs} style={{gap:20}}>
        <button className={props.toggleState === 1 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(1)} >
          <h4>Playlist</h4>          
          <button onClick={props.onClickAddPlaylist} className={props.toggleState === 1 ? `${style.btnAddPlaylist + " " + style.showBtnAddPlaylist}` : style.btnAddPlaylist}>
            <img src={IconPlusGreen} width={20} height={20} alt="aggiungi playlist" />
            <h4>aggiungi playlist</h4>
          </button>
        </button>
        <button className={props.toggleState === 2 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(2)} >
          <h4>Programmazione</h4>
          <button onClick={props.onClickAddProg} className={props.toggleState === 2 ? `${style.btnAddProg + " " + style.showBtnAddProg}` : style.btnAddProg}>
            <img src={IconPlusGreen} width={20} height={20} alt="aggiungi programmazione" />
            <h4>aggiungi programmazione</h4>
          </button>
        </button>
        <button className={props.toggleState === 3 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(3)}>
          <h4>Dispositivi</h4>
          <button onClick={props.onClickAddDisp} className={props.toggleState === 3 ? `${style.btnAddDisp + " " + style.showBtnAddDisp}` : style.btnAddDisp} style={{right:280}}>
            <img src={IconPlusGreen} width={20} height={20} alt="aggiungi dispositivi" />
            <h4>aggiungi dispositivi</h4>
          </button>
          {/* <button onClick={props.onClickExportLog} className={toggleState === 3 ? `${style.btnAddDispDisabled + " " + style.showBtnAddDisp}` : style.btnAddDisp} style={{right:150}}> */}
          <button onClick={()=>{}} className={props.toggleState === 3 ? `${style.btnAddDispDisabled + " " + style.showBtnAddDisp}` : style.btnAddDisp} style={{right:150}}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Filtra</h4>
          </button>  
          <button onClick={()=>{}} className={props.toggleState === 3 ? `${style.btnAddDispDisabled + " " + style.showBtnAddDisp}` : style.btnAddDisp} style={{right:0}}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Esporta</h4>
          </button>        
        </button>
        <button className={props.toggleState === 4 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(4)}>
          <h4>Riproduzioni</h4>
          <button onClick={()=>{}} className={props.toggleState === 4 ? `${style.btnAddDispDisabled + " " + style.showBtnExportLog}` : style.btnExportLog} style={{right:0}}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Filtra</h4>
          </button>
          {/* <button onClick={()=>{}} className={props.toggleState === 4 ? `${style.btnAddDispDisabled + " " + style.showBtnExportLog}` : style.btnExportLog}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Esporta</h4>
          </button> */}
        </button>
        <button className={props.toggleState === 5 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(5)}>
          <h4>Log</h4>
          <button onClick={()=>{}} className={props.toggleState === 5 ? `${style.btnAddDispDisabled + " " + style.showBtnExportLog}` : style.btnExportLog} style={{right:150}}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Filtra</h4>
          </button>
          <button onClick={()=>{}} className={props.toggleState === 5 ? `${style.btnAddDispDisabled + " " + style.showBtnExportLog}` : style.btnExportLog}>
            <img src={IconExport} width={20} height={20} alt="Esporta i dati" style={{opacity: 0.6}} />
            <h4>Esporta</h4>
          </button>
        </button>
      </div>

      <div className={style.contentTabs}>
        <div className={props.toggleState === 1 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabPlaylist}
        </div>

        <div className={props.toggleState === 2 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabProg}
        </div>

        <div className={props.toggleState === 3 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabDispositivi}
        </div>

        <div className={props.toggleState === 4 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabRiproduzioni}
        </div>

        <div className={props.toggleState === 5 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabLog}
        </div>
      </div>

    </div >
  );
}

export default Tabs;