import React from "react"
import IconAudio from "../../_assets/icone/audio.svg"
import IconUpload from "../../_assets/icone/upload.svg"
import { success } from "./PopupNotification"

// Styles
import style from "./PopupCaricaAudio.module.css"

const PopupCaricaAudio = (props) => {
  function nascondiPopup(e) {
    props.setIsOpen(false)
    success(`Il file con percorso ${e.target.value} è stato caricato correttamente`, true)
  }
  return (<>
    <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
    <div className={style.centered}>
      <div className={style.modal}>
        <div className={style.modalHeader}>
          <img src={IconAudio} width={25} height={25} alt="Icon" /> ;
          <h4 className={style.heading}>Carica audio pubblicitario</h4>
        </div>
        <button className={style.closeBtn} onClick={() => props.setIsOpen(false)}>
          x
        </button>
        <div className={style.modalContent}>
          <div className={style.fileUploadInput}>
            <input type="file" onChange={nascondiPopup} />
            <span>
              <img src={IconUpload} width={50} height={50} alt="upload" />
              <h6>Trascina qui il file audio</h6>
            </span>
          </div>
          <div className={style.fileUploadInfo}>
            <h6>Formato file: <span>mp3</span></h6>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}
export default PopupCaricaAudio