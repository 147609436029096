import React, { useContext, useEffect, useState } from "react";
import BtnGreen from "../_components/buttons/BtnGreen";
import BtnOptions from "../_components/buttons/BtnOptions";
import LabelTotalDevice from "../_components/commons/LabelTotalDevice";
import Title from "../_components/commons/Title";
import PlayerContainer from "../_components/containers/PlayerContainer";

import Footer from "../_components/layout/Footer";
import PopupAddPlaylistToPlayer from "../_components/modals/PopupAddPlaylistToPlayer";
import PopupAddProgToPlayer from "../_components/modals/PopupAddProgToPlayer";
import PopupCreaPlayer from "../_components/modals/PopupCreaPlayer";
import PopupModificaPlayerInfo from "../_components/modals/PopupModificaPlayerInfo";
import AuthContext from "../_store/auth-context";
import MessageCover from "../_components/commons/MessageCover";
import PopupCreaDispositivo from "../_components/modals/PopupCreaDispositivo";

import jwt_decode from "jwt-decode";

// Styles
import style from "./player.module.css";
import PopupDettaglioRegistrazione from "../_components/modals/PopupDettaglioRegistrazione";

const Player = () => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //
  const [channels, setChannels] = useState();

  //
  const [active, setActive] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen6, setIsPopupOpen6] = useState(false);
  //dati per le differenti tabs
  const [playlists, setPlaylists] = useState(null);
  const [devices, setDevices] = useState(null);
  const [riproduzioni, setRiproduzioni] = useState(null);
  const [logs, setLogs] = useState(null);

  //onComponentLoad
  useEffect(() => {
    //
    const fetchCanali = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player_list?" +
            new URLSearchParams({
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              PlayerID: null,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        //
        const data = await response.json();
        if (JSON.parse(data).length > 0) {
          setActive(JSON.parse(data)[0]);
          setChannels(JSON.parse(data));
        }
      } catch (error) {}
    };

    //
    fetchCanali();
  }, []);

  //onActiveChange
  useEffect(() => {
    //
    const fetchDataPlaylist = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/playlist_list?" +
            new URLSearchParams({
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              PlayerID: active.PlayerID,
              PlayerCode: active.PlayerCode,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();      
        setPlaylists(JSON.parse(data));
      } catch (error) {}
    };

    //
    const fetchDevices = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/device_list?" +
            new URLSearchParams({
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              PlayerID: active.PlayerID,
              Start: 1,
              End: 999999,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();        
        setDevices(JSON.parse(data));
      } catch (error) {}
    };
  
    //
    const fetchRiproduzioni = async () => {  
      if(active.PlayerID === undefined){return;}

      //
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/log?" +
            new URLSearchParams({
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              DeviceID: "",
              PlayerID: active.PlayerID,
              Start: 1,
              End: 100,
              Filter: "1",
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();            
        if(response.status !== 200){                    
          setRiproduzioni();
        }
        else{                    
          setRiproduzioni(JSON.parse(data));
        }
      } catch (error) {}
    };

    //
    const fetchLogs = async () => {  
      if(active.PlayerID === undefined){return;}
          
      console.log("active.PlayerID", active.PlayerID);
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/log?" +
            new URLSearchParams({
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              DeviceID: "",
              PlayerID: active.PlayerID,
              Start: 1,
              End: 100,
              Filter: "",
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();            
        if(response.status !== 200){          
          setLogs();
        }
        else{                              
          setLogs(JSON.parse(data));
        }
      } catch (error) {}
    };

    //
    fetchDataPlaylist();

    //
    fetchDevices();

    //
    fetchRiproduzioni();

    //
    fetchLogs();
  }, [active]);

  const setDeviceStatusUpdate = (ID, Status) => {
    
    //    
    setDevices(
      devices.map((item) => {
        //
        let _item = item;
        _item.PlayerDevicesID === ID
          ? (_item.Status = Status)
          : (_item.Status = item.Status);

        //
        // if (_item.PlayerDevicesID === ID) console.log(_item);

        //
        return _item;
      })
    );
  };

  const setDeviceUpdateDelete = (ID) => {
    setDevices(
      devices.map((item) => {
        //
        if (item != undefined && item !== undefined && item.ID !== ID)
          return item;
        else return;
      })
    );
  };

  //
  const handleConfirmNewPlayer = () => {
    //
    setIsPopupOpen(false);
  };

  const addNewChannel = (Channel) => {
    if(channels != null && channels!= undefined && channels.length > 0)
      setChannels((prev) => [...prev, Channel]);
    else
      setChannels([Channel]);
  };

  const addNewDevice = (Device) => {      
    setDevices((prev) => [Device, ...prev]);
  };

  return (
    <>
      <div className="container">
        <div className={style.containerHead}>
          <Title title="Canali" />
          <LabelTotalDevice value="3" />
        </div>
        <div className={style.selectPlayerContainer}>
          <div className={style.selectPlayer}>
            {channels &&
              channels.map((player) => (
                <BtnOptions
                  key={player.Nome}
                  placeholder={player.Nome}
                  isActive={active.Nome === player.Nome}
                  onClick={() => setActive(player)}
                />
              ))}
          </div>        
          <BtnGreen
            placeholder="aggiungi canale"
            onClick={() => setIsPopupOpen(true)}
          />
        </div>
        {active && (
          <PlayerContainer
            channel={active}
            onClickEditIcon={() => setIsPopupOpen2(true)}
            onClickAddPlaylist={() => setIsPopupOpen3(true)}
            onClickAddProg={() => setIsPopupOpen4(true)}
            onClickAddDisp={() => setIsPopupOpen5(true)}
            onClickExportLog={() => setIsPopupOpen6(true)}
            onClickISRC={() => setIsPopupOpen6(true)}
            playlists={playlists}
            devices={devices}
            riproduzioni={riproduzioni}
            logs={logs}
            setPlaylists={setPlaylists}
            addNewDevice={addNewDevice}
            setDeviceStatusUpdate={setDeviceStatusUpdate}
            setDeviceUpdateDelete={setDeviceUpdateDelete}
          />
        )}
        {!active && (
          <MessageCover content="Non sono ancora stati creati dei canali" />
        )}
      </div>
      {isPopupOpen && (
        <PopupCreaPlayer
          setIsOpen={setIsPopupOpen}
          onConfirm={handleConfirmNewPlayer}
          addNewChannel={addNewChannel}
        />
      )}
      {isPopupOpen2 && (
        <PopupModificaPlayerInfo
          active={active}
          setActive={setActive}
          setIsOpen={setIsPopupOpen2}
          onConfirm={handleConfirmNewPlayer}
        />
      )}
      {isPopupOpen3 && (
        <PopupAddPlaylistToPlayer
          setIsOpen={setIsPopupOpen3}
          active={active}
          setPlaylists={setPlaylists}
        />
      )}
      {isPopupOpen4 && <PopupAddProgToPlayer setIsOpen={setIsPopupOpen4} />}
      {isPopupOpen5 && (
        <PopupCreaDispositivo
          setIsOpen={setIsPopupOpen5}
          PlayerID={active.PlayerID}
          addNewDevice={addNewDevice}
        />
      )}
      {isPopupOpen6 && (
         <PopupDettaglioRegistrazione
         setIsOpen={setIsPopupOpen6}
         active={active}
         setPlaylists={setPlaylists}
       />
      )}
      <Footer />
    </>
  );
};

export default Player;
