import React from "react"
import IconAssocia from "../../_assets/icone/associa.svg"

// STyles
import style from "./CodeLabel.module.css"

const CodeLabel = (props) => {
  return (
    <div className={style.container}>
      <img width={25} height={25} src={IconAssocia} alt="associa" />
      <span className={style.subcontainer}>
        <h4>{props.value}</h4>
      </span>
    </div>

  )
}


export default CodeLabel