import React from "react"

// Styles
import style from "./BtnAddTrack.module.css"



const BtnAddTrack = ({playlistName, onClick}) => {
  return (
    <div className={style.btnAdd} onClick={onClick}>
      <h6>{playlistName}</h6>
    </div>
  )
};

BtnAddTrack.defaultProps = {
  playlistName: "Playlist #1"
}


export default BtnAddTrack

