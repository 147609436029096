import React, { useState } from "react";

// Styles
import style from "./TabsImpostazioni.module.css"

function TabsImpostazioni(props) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };



  return (
    <div className={style.tabsContainer}>

      <div className={style.blocTabs}>
        <button className={toggleState === 1 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(1)} >
          <h4>generali</h4>
        </button>
        <button className={toggleState === 2 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(2)} >
          <h4>account</h4>
        </button>
        <button className={toggleState === 3 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(3)}>
          <h4>sottoscrizione</h4>
        </button>
        <button className={toggleState === 4 ? `${style.tabs + " " + style.activeTabs}` : style.tabs} onClick={() => toggleTab(4)}>
          <h4>cronologia</h4>
        </button>
      </div>

      <div className={style.contentTabs}>
        <div className={toggleState === 1 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabGenerale}
        </div>

        <div className={toggleState === 2 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabAccount}
        </div>

        <div className={toggleState === 3 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabSottoscrizione}
        </div>

        <div className={toggleState === 4 ? `${style.content} + " " + ${style.activeContent}` : style.content}>
          {props.tabCronologia}
        </div>
      </div>

    </div >
  );
}

export default TabsImpostazioni;