import React, { useContext } from "react";
import playRoundIcon from "../../_assets/icone/playRoundIcon.svg";
import { useNavigate } from "react-router-dom";
import audioPlayerCtx from "../../_store/audioPlayer/audioPlayerCtx";
import { warning } from "../modals/PopupNotification";
import CoverImageDefaultProg from "../../_assets/immagini/defaultPlaylistProgrammazione.png";
import { useDrag, DragSource, DragPreviewImage } from "react-dnd";
import slotPreviewImage from "../../_assets/immagini/slot.png";
import jwt_decode from "jwt-decode"

// Styles
import style from "./CardSmallProgrammazione.module.css";
import AuthContext from "../../_store/auth-context";

const CardSmallProgrammazione = (props) => {
  
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //useContext
  const {
    SetSongsList,
    currentPlaylist,
    SetCurrentPlaylist,
    SetCurrentTimePlaylist,
    SetCurrentTime,
    SetAudioPlayer,
    SetEmptyPlaylist,
  } = useContext(audioPlayerCtx);

  //#endregion
  
  const fetchData = async (playlistID) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_GRM_SERVICES +
          "api/v1/utilizzatore/playlist/?" +
          new URLSearchParams({
            PlaylistID: playlistID,
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],

          }),
        {
          method: "get",
          headers: new Headers({
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      );
      const data = await response.json();
      SetCurrentPlaylist(JSON.parse(data));
      if (JSON.parse(data).Tracce.length > 0) {
        SetEmptyPlaylist(false);
        SetSongsList(JSON.parse(data).Tracce);
      } else {
        SetEmptyPlaylist(true);
        SetCurrentTimePlaylist(0);
        warning(
          "Al momento non è presente nessuna traccia in questa playlist",
          true
        );
      }

      // setLoading(false);
      // setFetched(true);
    } catch (error) {}
  };

  const handlePlayPlaylist = (e) => {
    e.stopPropagation();
    SetAudioPlayer(false);
    SetCurrentTime(0);
    fetchData(props.idPlaylist);
  };

  function setBackgroundImage() {
    let defaultImage = CoverImageDefaultProg;
    let imageUrl = props.image;
    if (imageUrl != undefined) {
      return imageUrl;
    } else {
      return defaultImage;
    }
  }

  let navigate = useNavigate();
  const navLink = () => {
    navigate(`/playlist/${props.idPlaylist}`);
  };

  // Make playlist draggable
  const [{ isDragging }, connectDragSource, connectDragPreview] = useDrag(
    () => ({
      type: "playlist",
      item: {
        id: props.idPlaylist,
        image: props.image,
        nome: props.itemsName,
        height: props.height,
        start: 0,
        end: 0,
        day: 0,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    })
  );

  return (
    <>
      <DragPreviewImage
        connect={connectDragPreview}
        src={slotPreviewImage}
        width="20px"
      />
      <div
        className={style.cardS}
        ref={connectDragSource}
        style={{
          opacity: isDragging ? 0.7 : 1,
          height: props.height,
          marginBottom: props.marginBottom,
        }}
      >
        <a onClick={navLink}>
          <div
            className={style.cardS_bg}
            style={{ backgroundImage: `url(${setBackgroundImage()})` }}
          >
            <button
              className={`${style.btnPreview} ${
                currentPlaylist.Id && currentPlaylist.Id === props.idPlaylist
                  ? style.show
                  : ""
              }`}
              onClick={handlePlayPlaylist}
            >
              <img
                src={playRoundIcon}
                width={20}
                height={20}
                alt="Ascolta anteprima"
              />
            </button>
          </div>
        </a>
        <div className={style.cardS_description}>
          <div className={style.cardS_description_text}>
            <h6 className="ellipsis">{props.itemsName}</h6>
            {/* <h6 className="ellipsis">{props.itemsDesc}</h6> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSmallProgrammazione;
