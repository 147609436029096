import React, { useState } from "react";
import PlaylistHead from "./PlaylistHead";
import PlaylistBody from "./PlaylistBody";
import CoverImageDefault from "../../../_assets/immagini/defaultPlaylist.png";

const PlaylistContainer = ({ playlist, similarPlaylist }) => {  
  let _name, _id;
  
  // gestione tra playlist con tracce e senza

  //#region UseState

  const [songs, setSongs] = useState(playlist.Tracce);

  //#endregion

  // #region Variables

  if (playlist.Name !== undefined)
    _name = playlist.Name
  else
    _name = playlist.Nome

  if (playlist.Id !== undefined)
    _id = playlist.Id
  else
    _id = playlist.PlayListID
      
  //#endregion

  return (
    <>
      <PlaylistHead
        playlist={playlist}
        playlistName={_name}
        playlistDescription={playlist.Descrizione}
        playlistExplicitContent={playlist.TipologiaContenuto}
        playlistCoverUrlPlaylist={playlist.CoverUrlSharing}
        playlistCoverNamePlaylist={playlist.Cover}
        playlistIsFavourite={playlist.isFavourite}
        playlistId={_id}
        setSongs={setSongs}
        songs={songs}
      />      
      <PlaylistBody
        songs={songs}
        setSongs={setSongs}
        lastUpdate={playlist.UltimoAggiornamento}
        similarPlaylist={similarPlaylist}
        playlistId={playlist.Id}
        hours={playlist.DurataOre}
        minutes={playlist.DurataMinuti}
        seconds={playlist.DurataSecondi}
      />
    </>
  );
};
export default PlaylistContainer;
