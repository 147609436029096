import React, { useState } from "react";
import { Link } from "react-router-dom";
import BtnDots from "../buttons/BtnDots";
import BtnGreen from "../buttons/BtnGreen";
import InputSearch from "../commons/InputSearch";

// Styles
import style from "./LaMiaMusicaTabOption.module.css";

const LaMiaMusicaTabOption = ({btnDotsDisplay, btnGreenPlaceholder, btnGreenOnClick, search, setSearch}) => {
  const [isSearching, setIsSearching] = useState(false);

  return (
    <div className={style.optionContainer}>
      <InputSearch
        background="transparent"
        width="200px"
        height="40px"
        border="1px solid #ccc"
        placeholder="cerca"
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        setSearch={setSearch}
        search={search}
      />
      <BtnGreen
        onClick={btnGreenOnClick}
        placeholder={btnGreenPlaceholder}
      />
      <BtnDots display={btnDotsDisplay}>
        <ul>
          <li>
            <Link to="/categorie">
              <h6>aggiungi playlist</h6>
            </Link>
          </li>
        </ul>
      </BtnDots>
    </div>
  );
};

LaMiaMusicaTabOption.defaultProps = {
  btnDotsDisplay: "flex",
  btnGreenPlaceholder: "crea",
  btnGreenOnClick: null,
};
export default LaMiaMusicaTabOption;
