import React from "react"
import Btn from "../buttons/Btn"


// Styles
import style from "./PopupConferma.module.css"

const PopupConferma = (props) => {


  return (<>
    <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
    <div className={style.centered}>
      <div className={style.modal}>
        <div className={style.modalHeader}>
          <h4 className={style.heading}>{props.header}</h4>
        </div>
        <button className={style.closeBtn} onClick={() => props.setIsOpen(false)}>
          x
        </button>
        <div className={style.modalContent}>
          <div className={style.actionsContainer}>
            <Btn placeholder="ANNULLA" btnBackground="var(--sidebar-background" transform="scale(.99)" width="100%" onClick={() => props.setIsOpen(false)} />
            <Btn placeholder="CONFERMA" transform="scale(.99)" width="100%" onClick={props.onConfirm} />
          </div>

        </div>
      </div>
    </div>
  </>
  )
}

PopupConferma.defaultProps = {
  header: "Sei sicuro di voler procedere?"
}
export default PopupConferma