import React, { useState, useEffect, useContext } from "react";
import InputSearch from "../../commons/InputSearch";
import PlaylistBrano from "./PlaylistBrano";
import MessageCover from "../../commons/MessageCover";
import CardSmall from "../../cards/CardSmall";

// Styles
import style from "./PlaylistBody.module.css";
import AuthContext from "../../../_store/auth-context";

const PlaylistBody = ({
  songs,
  setSongs,
  lastUpdate,
  hours,
  minutes,
  seconds,
  similarPlaylist,
}) => {
  const authCtx = useContext(AuthContext);
  //Content
  let _content = null;
  let _contentLastUpdate = "";
  let _contentSimilarPlaylist = null;

  //Exist
  let _existLastUpdate = lastUpdate;
  let _existContent = false;

  //#region UseState
  const [inputSearchWidth, setInputSearchWidth] = useState("50px");
  const [songsToDisplay, setSongsToDisplay] = useState(10);
  const [inputSearchBorder, setInputSearchBorder] = useState("none");
  const [inputSearchPlaceholder, setInputSearchPlaceholder] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [
    displayAllSongsButtonVisibility,
    setdisplayAllSongsButtonVisibility,
  ] = useState(true);
  const [search, setSearch] = useState("");
  //#endregion

  useEffect(() => {
    //nasconde il bottone che permette di vedere più brani
    if (!songs) {
      setdisplayAllSongsButtonVisibility(false);
      return;
    }
    if (songs.length < 11) {
      setdisplayAllSongsButtonVisibility(false);
    }
  }, []);

  //#region functions
  
  //
  const handleClick = () => {
    setInputSearchWidth("300px");
    setInputSearchBorder("1px solid #ccc");
    setInputSearchPlaceholder("cerca un brano");
  };

  //
  const countTracks = () => {
    if (songs === undefined) {
      return "0 Brani";
    }
    if (songs.length === 1) {
      return songs.length + " Brano";
    }
    if (songs.length > 1) {
      return songs.length + " Brani";
    } else {
      return "Non sono presenti brani";
    }
  };

  //
  const formatPlaylistDuration = () => {
    if (hours === undefined) {
      hours = 0;
    }
    if (minutes === undefined) {
      minutes = 0;
    }
    if (seconds === undefined) {
      seconds = 0;
    }

    if (hours !== 0) {
      if (hours === 1) {
        return hours + " Ora " + minutes + " Minuti " + seconds + " Secondi";
      } else {
        return hours + " Ore " + minutes + " Minuti " + seconds + " Secondi";
      }
    }
    if (minutes !== 0) {
      return minutes + " Minuti " + seconds + " Secondi";
    }
    if (seconds !== 0) {
      return seconds + " Secondi";
    } else return "0 Secondi";
  };

  //
  const filterSearch = (item) => {
    if (
      item.Registrazioni_Titolo.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    } else {
      return;
    }
  };

  //
  const displayAllSongsButtonVisibilityFn = () => {
    setdisplayAllSongsButtonVisibility(false);
    setSongsToDisplay(songs.length);
  };

  const handlerRemoveSong = (RegistrazioneID) => {
    setSongs((Songs) => [
      ...Songs.filter((item) => item.RegistrazioneID != RegistrazioneID),
    ]);
  };
  //#endregion

  //#region Build content

  // _content
  if (songs && songs.length != 0) {
    if (search.length > 0) {
      _content = songs
        .filter(filterSearch)
        .map((traccia, index) => (
          <PlaylistBrano
            key={traccia.RegistrazioneID}
            index={index}
            track={traccia}
            RegistrazioneID={traccia.RegistrazioneID}
            onDelete={handlerRemoveSong}
          />
        ));

      if (_content.length === 0) {
        //
        _content = (
          <>
            <MessageCover content="Non ci sono tracce che corrispondono alla stringa di ricerca" />
          </>
        );

        //
        _existContent = false;
      }

      if (_content.length !== 0) {
        //
        _existContent = true;
      }
    } else {
      _content = songs
        .slice(0, songsToDisplay)
        .map((traccia, index) => (
          <PlaylistBrano
            key={traccia.RegistrazioneID}
            index={index}
            track={traccia}
            RegistrazioneID={traccia.RegistrazioneID}
            onDelete={handlerRemoveSong}
          />
        ));

      //
      _existContent = true;
    }
  } else {
    _content = "Non ci sono tracce in questa playlist";
  }

  // _content
  if (songs === undefined || songs.length === 0) {
    //
    _existContent = false;

    _content = (
      <MessageCover content="Ops.. Il Disc Jockey sta lavorando su questa playlist.. " />
    );
  }

  // _contentLastUpdate
  if (_existLastUpdate) {
    _contentLastUpdate = lastUpdate;
  }

  // _contentLastUpdate
  if (!_existLastUpdate) {
    _contentLastUpdate = "Nessun aggiornamento recente";
  }

  // _similarContent
  if (!similarPlaylist) {
    _contentSimilarPlaylist = "";
  } else {
    _contentSimilarPlaylist = similarPlaylist
      .map((playlist) => (
        <CardSmall
          key={playlist.PlaylistID}
          image={playlist.Cover_Playlist}
          itemsName={playlist.Nome_Playlist}
          itemsDesc={playlist.Descrizione_Playlist}
          idPlaylist={playlist.PlaylistID}
          nestedCard={true}
          btnContent={
            <span>
              <h6>aggiungi</h6>
            </span>
          }
        />
      ))
      .slice(0, 6);
  }
  //#endregion

  //
  return (
    <div className={style.container}>
      <div className={style.playlistBodyHead}>
        <div className={style.playlistInfo}>
          <h6>{countTracks()}</h6>
          <h6>|</h6>
          <h6>{formatPlaylistDuration()}</h6>
          <h6>|</h6>
          {_existLastUpdate && <h6>{_contentLastUpdate}</h6>}
          {!_existLastUpdate && <h6>{_contentLastUpdate} </h6>}
        </div>
        <InputSearch
          background="transparent"
          width={inputSearchWidth}
          border={inputSearchBorder}
          placeholder={inputSearchPlaceholder}
          onClick={handleClick}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          setSearch={setSearch}
          search={search}
        />
      </div>
      {_existContent && (
        <div className={style.messageHeight}>
          <div className={style.playlistBodyTracks}>{_content}</div>
        </div>
      )}
      {/* TODO aggiungere style per messaggi  */}
      {!_existContent && (
        <div
          className={style.messageHeight}
          >
          {_content}
        </div>
      )}
      {displayAllSongsButtonVisibility && (
        <div
          className={style.loadMore}
          onClick={displayAllSongsButtonVisibilityFn}
        >
          <h5>visualizza tutti i brani</h5>
        </div>
      )}
      {(authCtx.isAdmin === false || authCtx.isAdmin === "false") && (
        <div className={style.playlistSimili}>
          <h3>Playlist simili che potrebbero piacerti</h3>
          <div className={style.playlistContainer}>
            {/* {props.similarPlaylists.map((playlist) => (
            <CardSmall
              key={playlist.PlaylistID}
              image={playlist.Cover_Playlist}
              itemsName={playlist.Nome_Playlist}
              itemsDesc={playlist.Descrizione_Playlist}
              idPlaylist={playlist.PlaylistID}
              btnContent={
                <span onClick={props.onClickAggiungi}>
                  <h6>aggiungi</h6>
                </span>
              }
            />
          ))} */}
            {_contentSimilarPlaylist}
          </div>
        </div>
      )}
    </div>
  );
};
export default PlaylistBody;
