import React, { useState, useContext } from "react";
import ExplicitLabel from "../../commons/ExplicitLabel";
import BtnDots from "../../buttons/BtnDots";
import PopupSelezionaPlaylist from "../../modals/PopupSelezionaPlaylist";
import audioPlayerCtx from "../../../_store/audioPlayer/audioPlayerCtx";
import CoverImageDefault from "../../../_assets/immagini/defaultPlaylist.png";

//Styles
import style from "./PlaylistBrano.module.css";
import PopupConferma from "../../modals/PopupConferma";
import { success } from "../../modals/PopupNotification";
import { useParams } from "react-router-dom";
import AuthContext from "../../../_store/auth-context";

// Variabili statiche
const src =
  "https://images.unsplash.com/photo-1658210399320-3e5cac496b8d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw1NDM4NnwwfDF8cmFuZG9tfHx8fHx8fHx8MTY2MDU1MTMxOA&ixlib=rb-1.2.1&q=80&w=1080";
const explicit = "ESPLICITO";

const PlaylistBrano = ({
  track,
  onDelete,
  RegistrazioneID,
}) => {
  //useContext
  const { currentSong, SetCurrent } = useContext(audioPlayerCtx);
  const authCtx = useContext(AuthContext);

  //useState

  const [isHover, setIsHover] = useState(false);
  const [hoverVisibility, setHoverVisibility] = useState("hidden");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  

  //#endregion

  //
  const searchParams = useParams();

  //#region Functions

  //
  const handlerRemoveTrackFromPlaylist = () => {

    //do something
    setIsPopupOpen2(false);
    success("brano rimosso dalla playlist con successo", true);

    //
    const fetchData = async () => {
      try {
        //
        const params = {
          PlaylistID: searchParams.id,
          RegistrazioneID: RegistrazioneID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/registrazione_delete",
          options
        );
        const data = await response.json();

        onDelete(RegistrazioneID);
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  function handleClick() {
    SetCurrent(track);
  }

  const handleMouseEnter = () => {
    setIsHover(true);
    setHoverVisibility("visible");
  };

  //
  const handleMouseLeave = () => {
    setIsHover(false);
    setHoverVisibility("hidden");
  };

  //
  const formatDurationTrack = () => {
    
    if(track.RegistrazioniMultimedia_Durata !== null)
    {
      try{
        return track.RegistrazioniMultimedia_Durata.split(':').slice(1).join(':');
      }catch(exception){
        return "00:00";
      }            
    }else{
      return "00:00";
    }
    
  };

  //#endregion

  return (
    <div
      className={style.row}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ background: isHover ? "var(--hover)" : "var(--white)" }}
    >
      <div className={style.rowHead}>
        <div className={style.rowSubHead}>
          <div
            onClick={() => handleClick()}
            className={`${style.rowSubHeadImage} ${
              currentSong.RegistrazioneID === track.RegistrazioneID
                ? style.show
                : style.hide
            }`}
          >                        
            <img
              src={(track.CoverUrl != null && track.CoverUrl != "" && track.CoverUrl != undefined) ? track.CoverUrl : CoverImageDefault}
              width={50}
              height={50}
              alt="Cover Brano"
            />
            <span data-id={track.RegistrazioneID}></span>
          </div>
          <h5>{track.Registrazioni_Titolo}</h5>
        </div>
        {track.Delivery_TipologiaContenuto === "Esplicito" && (
          <ExplicitLabel
            value={explicit}
            border="1px solid var(--text-secondary)"
            background="transparent"
            font="var(--h7-r)"
            color="var(--text-secondary)"
          />
        )}
      </div>
      <div className={style.rowTail}>
          <h5 className={style.grey}>
            {(track.Pubblicazione_ArtistaPrincipale !== undefined && track.Pubblicazione_ArtistaPrincipale !== null) ? track.Pubblicazione_ArtistaPrincipale.toUpperCase() : track.Pubblicazione_ArtistaPrincipale }
          </h5>
        {/* <div class={style.tooltip}> 
          <h5 className={style.rowAlbum}>{track.Pubblicazione_TitoloAlbum}</h5>
          <span class={style.tooltiptext}>{track.Pubblicazione_TitoloAlbum ? track.Pubblicazione_TitoloAlbum : "TITOLO ALBUM (vuoto)"} </span>        
        </div> */}
        <div className={style.rowTailEnd}>
          <h5 className={style.grey}>{formatDurationTrack()}</h5>
          <BtnDots visibility={hoverVisibility}>
            <ul>
              <li>
                <span onClick={() => setIsPopupOpen(true)}>
                  <h6>aggiungi alla playlist</h6>
                </span>
              </li>
              {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<li>
                <span onClick={() => setIsPopupOpen2(true)}>
                  <h6>rimuovi brano</h6>
                </span>
              </li>)}
            </ul>
          </BtnDots>
        </div>
      </div>
      {isPopupOpen && (
        <PopupSelezionaPlaylist
          RegistrazioneID={track.RegistrazioneID}
          setIsOpen={setIsPopupOpen}
        />
      )}
      {isPopupOpen2 && (
        <PopupConferma
          header="Sei sicuro di voler rimuovere questo brano dalla playlist?"
          setIsOpen={setIsPopupOpen2}
          onConfirm={handlerRemoveTrackFromPlaylist}
        />
      )}
    </div>
  );
};

export default PlaylistBrano;
