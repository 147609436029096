import React from "react";
import ImpostazioniContainer from "../_components/containers/ImpostazioniContainer"

// Styles
import style from "./impostazioni.module.css"



const Impostazioni = () => {

  return (
    <div style={{ marginTop: "40px" }} className="container" >
      <div className={style.subContainer}>
        <h1 style={{ font: "var(--title-m" }}>Impostazioni</h1>
        <ImpostazioniContainer />
      </div>

    </div >
  );
};

export default Impostazioni;
