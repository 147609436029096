import React, { useContext, useState } from "react";
import Btn from "../buttons/Btn";
import IconPlayer from "../../_assets/icone/player2.svg";
import { success, warning } from "../modals/PopupNotification";

// Styles
import style from "./PopupCreaPlayer.module.css";
import AuthContext from "../../_store/auth-context";

const PopupCreaPlayer = ({ setIsOpen, onConfirm, addNewDevice, PlayerID }) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //#region UseState
  const [Description, setDescription] = useState("");
  const [Telephone, setTelephone] = useState("");
  const [Email, setEmail] = useState("");

  //#region functions

  const HandleDescriptionChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const HandleTelephoneChange = (e) => {
    e.preventDefault();
    setTelephone(e.target.value);
  };

  const HandleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const saveChanges = () => {
    const fetchData = async () => {
      try {
        //
        const params = {
          PlayerID: PlayerID,
          DeviceCode: null,  
          DeviceDescription: Description,
          Telephone: Telephone,
          Email: Email,
          Status: 0,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES + "api/v1/utilizzatore/player/device_add",
          options
        );
        const data = await response.json();

        // if (response.status === 200) {
          //
          addNewDevice({
            CreatedDate: Date.now(),
            Description: Description,
            DeviceID: null,
            Email: Email,
            ExpireDate: null,
            ID: Math.floor(data),
            LastUpdate: null,
            PlayerID: PlayerID,
            Status: 0,
            Telephone: Telephone,
            UserIDLastUpdate: null,
            DeviceBrand: null,
            DeviceModel: null,
            DeviceOS: null,
          });

          //
          success("Dispositivo aggiunto con successo", true);
        // } else {
        //   warning(
        //     "Si è presentato un errore in fase di creazione del dispositivo, si prega di riprovare più tardi",
        //     true
        //   );
        // }
      } catch (error) {}
    };

    //
    fetchData();

    setIsOpen(false);
  };

  const onClickSave = (e) => {
    e.preventDefault();
    //    
    const _isValidEmail =Email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ )
    const _isValidPhoneNumber =Telephone.toLowerCase().match(/^\+(?:[0-9]●?){6,14}[0-9]$/ )
    const _isValidDescription =Description.length > 0
    
    if(_isValidDescription === true && _isValidEmail !== null && _isValidPhoneNumber!==null){
      saveChanges();
    }
    else if(_isValidPhoneNumber !== null && _isValidDescription === false && _isValidEmail !== null){
      warning("Attenzione, il campo Descrizione è obbligatorio", true)
    }
    else if(_isValidPhoneNumber !== null && _isValidDescription === true && _isValidEmail === null){
      warning("Attenzione, il campo email non è stato formattato correttamente", true)
    }
    else if(_isValidPhoneNumber === null && _isValidDescription === true && _isValidEmail !== null){
      warning("Attenzione, il numero deve contenere il prefisso internazionale", true)
    }
    else{
      warning("Attenzione, controlla che i dati inseriti siano validi", true)
      }
    //onConfirm();
  };

  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <img src={IconPlayer} width={30} height={30} alt="Icon" /> ;
            <h4 className={style.heading}>Aggiungi un nuovo Dispositivo</h4>
          </div>
          <button className={style.closeBtn} onClick={() => setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>
            <div className={style.actionsContainer}>
              <label htmlFor="playlistName">Descrizione </label>
              <input
                id="deviceDescription"
                type="text"
                value={Description}
                onChange={HandleDescriptionChange}
                placeholder="Inserisci una descrizione per il device"
              />
              <label htmlFor="playlistDesc">Telefono</label>              
              <input
                id="deviceTelephone"
                type="text"
                value={Telephone}
                onChange={HandleTelephoneChange}
                placeholder="+39"
              />
              <label htmlFor="playlistDesc">Email</label>
              <input
                id="deviceEmail"
                type="email"
                value={Email}
                onChange={HandleEmailChange}
                placeholder="Inserisci la mail collegata"
              />
              <Btn
                onClick={onClickSave}
                placeholder="CREA"
                width="100%"
                transform="scale(.99)"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupCreaPlayer;
