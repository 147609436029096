import React, { useEffect, useMemo, useState } from "react";
import style from "./Footer.module.css";
import PopUpGeneric from "../modals/PopUpGeneric";
const Footer = () => {
  const [isPopupPrivacyOpen, setIsPopupPrivacyOpen] = useState(false);
  const [isPopupLegalOpen, setIsPopupLegalOpen] = useState(false);

  const [contentLegal, setContentLegal] = useState("");
  const [contentPrivacy, setContentPrivacy] = useState("");

  //function that return current year
  const currentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  //#region ajax calls

  const getLegal = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Cookie",
      "ARRAffinity=ef7d0d8c656e0279454cff2a2cae2050de6f23be05adf6a2780ab83934572e22; ARRAffinitySameSite=ef7d0d8c656e0279454cff2a2cae2050de6f23be05adf6a2780ab83934572e22"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://www.liberobit.it/TemplateHtml/col-legal_it-it.txt",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {      
        result = result.replace('<div class="row">','<div class="row" style="display:flex">')        
        result = result.replace('<div class="row">','<div class="row" style="display:flex; flex-wrap: wrap;">')        
        result = result.replace('<div class="col-md-6 text-center">','<div class="col-md-6 text-center" style="flex:1;">')                
        result = result.replace('<div class="col-md-6 text-center">','<div class="col-md-6 text-center" style="flex:1;">')                        
        result = result.replace('<div class="col-md-4 text-center">','<div class="col-md-4 text-center" style="flex-basis: 100%; margin-top:40px ">')                        
        
        
        setContentLegal(result)
      })
      .catch((error) => console.log("error", error));    
  };

  const getPrivacy = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Cookie",
      "ARRAffinity=ef7d0d8c656e0279454cff2a2cae2050de6f23be05adf6a2780ab83934572e22; ARRAffinitySameSite=ef7d0d8c656e0279454cff2a2cae2050de6f23be05adf6a2780ab83934572e22"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://www.liberobit.it/TemplateHtml/col-privacy_it-it.txt",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        result = result.replace('<div class="row">','')
        result = result.replace('<div class="col-md-12">','')
        result = result.replace('<h3 class="title" style="margin-bottom:0px;height:45px;">','')
        result = result.replace('<!-- <img src="Images/col-privacy_it-IT.png" class="img-responsive" style="max-height:40px"> -->','')
        result = result.replace('</h3>','')
        result = result.replace('</div>','')
        result = result.replace('</div>','')        
                          
        result = result.replace('<div class="col-md-6">','<div class="col-md-6" style="text-align: left;">')
        result = result.replace('<div class="col-md-6">','<div class="col-md-6" style="text-align: left; margin-top: 50px;">')        
        result = result.replace('<strong>','<strong style="font-size:15px; color:red;">')
        result = result.replace('<strong>','<strong style="font-size:15px; color:red;">')
        result = result.replace('<strong>','<strong style="font-size:15px; color:red;">')
        result = result.replace('<a ','<a style="color:#428bca;" ')

        
        setContentPrivacy(result)})
      .catch((error) => console.log("error", error));    
  };

  //#endregion
  useEffect(() => {
    getLegal();
    getPrivacy();
  }, [contentLegal, contentPrivacy]);

  return (
    <div className={style.footer}>
      <div className={style.footerContainer}>
        <h6>
          GRM Global Rights Management v3.3.5 Copyrights © 2019-{currentYear()}{" "}
          - LiberoBit Srl - All rights reserved -
          <button onClick={() => setIsPopupPrivacyOpen(true)} style={{background:"none", color:"white"}}> Privacy </button> -
          <button onClick={() => setIsPopupLegalOpen(true)} style={{background:"none", color:"white"}}>Legal</button>{" "}
        </h6>
      </div>
      {isPopupPrivacyOpen && (
        <PopUpGeneric
          title="Informativa Privacy e Cookie"
          content={contentPrivacy}
          setIsOpen={setIsPopupPrivacyOpen}
        />
      )}
      {isPopupLegalOpen && (
        <PopUpGeneric
          title="Note legali"
          content={contentLegal}
          setIsOpen={setIsPopupLegalOpen}
        />
      )}
    </div>
  );
};

export default Footer;
