import React, { useContext, useState } from "react";
import Btn from "../buttons/Btn";
import IconPlayer from "../../_assets/icone/player2.svg";
import { success, warning } from "../modals/PopupNotification";
import jwt_decode from "jwt-decode"


// Styles
import style from "./PopupCreaPlayer.module.css";
import AuthContext from "../../_store/auth-context";

const PopupCreaPlayer = ({ setIsOpen, onConfirm, addNewChannel }) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //#region UseState
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");

  //#region functions

  const HandleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const HandleDescriptionChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const saveChanges = () => {
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlayerName: Name,
          PlayerDescription: Description,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES + "api/v1/utilizzatore/player_add",
          options
        );
        const data = await response.json();

        if (response.status === 200) {
          //
          addNewChannel({
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            CreatedDate: Date.now(),
            Descrizione: Description,
            LastUpdate: null,
            Nome: Name,
            PlayerCode: JSON.parse(data).PlayerCode,
            PlayerID: JSON.parse(data).PlayerID, //ricavare player id
          });

          //
          success("Canale aggiunto con successo", true);
        } else {
          warning(
            "Assicurarsi che il nome del canale sia univoco o riprovare successivamente",
            true
          );
        }
      } catch (error) {}
    };

    //
    fetchData();

    setIsOpen(false);
  };

  const onClickSave = (e) => {
    e.preventDefault();
    //
    saveChanges();
    onConfirm();
  };

  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <img src={IconPlayer} width={30} height={30} alt="Icon" /> ;
            <h4 className={style.heading}>Aggiungi un nuovo canale</h4>
          </div>
          <button className={style.closeBtn} onClick={() => setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>
            <div className={style.actionsContainer}>
              <label htmlFor="playlistName">Nome </label>
              <input
                id="channelName"
                type="text"
                value={Name}
                onChange={HandleNameChange}
                placeholder="Inserisci il nome del nuovo canale"
              />
              <label htmlFor="playlistDesc">Descrizione</label>
              <input
                id="channelDesc"
                type="text"
                value={Description}
                onChange={HandleDescriptionChange}
                placeholder="Inserisci una descrizone del canale"
              />
              <Btn
                onClick={onClickSave}
                placeholder="CREA"
                width="100%"
                transform="scale(.99)"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupCreaPlayer;
