import React, { useRef, useContext } from "react";
import BtnDots from "../buttons/BtnDots";
import playRoundIcon from "../../_assets/icone/playRoundIcon.svg";
import { useNavigate, NavLink, Link } from "react-router-dom";
import audioPlayerCtx from "../../_store/audioPlayer/audioPlayerCtx";
import { warning } from "../modals/PopupNotification";
import CoverImageDefault from "../../_assets/immagini/defaultPlaylist.png";
import { success } from "../modals/PopupNotification";

// Styles
import style from "./CardSmall.module.css";
import AuthContext from "../../_store/auth-context";
import IconLock from "../../_assets/icone/lock.svg";

const CardSmall = (props) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //
  const {
    SetSongsList,
    currentPlaylist,
    SetCurrentPlaylist,
    SetCurrentTimePlaylist,
    SetCurrentTime,
    SetAudioPlayer,
    SetEmptyPlaylist,
  } = useContext(audioPlayerCtx);

  //#endregion

  //useRef
  const playlistRef = useRef();

  //#region Functions
  const fetchData = async (playlistID) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_GRM_SERVICES +
          "api/v1/utilizzatore/playlist/?" +
          new URLSearchParams({
            PlaylistID: playlistID,
            CompanyID: 1000,
          }),
        {
          method: "get",
          headers: new Headers({
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      );
      const data = await response.json();
      SetCurrentPlaylist(JSON.parse(data));
      if (JSON.parse(data).Tracce.length > 0) {
        SetEmptyPlaylist(false);
        SetSongsList(JSON.parse(data).Tracce);
      } else {
        SetEmptyPlaylist(true);
        SetCurrentTimePlaylist(0);
        warning(
          "Al momento non è presente nessuna traccia in questa playlist",
          true
        );
      }
    } catch (error) {}
  };

  const handlePlayPlaylist = (e) => {
    e.stopPropagation();
    SetAudioPlayer(false);
    SetCurrentTime(0);
    const playlistID = playlistRef.current.getAttribute("data-id");
    fetchData(playlistID);
  };

  const setBackgroundImage = () => {
    let defaultImage = CoverImageDefault;
    let imageUrl = props.image;
    if (imageUrl != undefined) {
      return imageUrl;
    } else {
      return defaultImage;
    }
  };

  let navigate = useNavigate();
  const navLink = () => {
    navigate(`/playlist/${props.idPlaylist}`);
    if (props.nestedCard === true) {
      window.location.reload();
    }
  };

  //
  const _svgLock = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg width="100%" height="100%" viewBox="0 0 500 500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M160.854,204.253l0,-23.39c0,-46.922 39.945,-85.017 89.146,-85.017c49.201,-0 89.146,38.095 89.146,85.017l-0,23.39l16.264,0c16.55,0 29.986,13.436 29.986,29.985l-0,139.931c-0,16.549 -13.436,29.985 -29.986,29.985l-210.82,0c-16.55,0 -29.986,-13.436 -29.986,-29.985l0,-139.931c0,-16.549 13.436,-29.985 29.986,-29.985l16.264,0Zm154.277,0l-0,-23.39c-0,-8.368 -1.778,-16.347 -4.998,-23.62c-3.325,-7.512 -8.188,-14.27 -14.217,-19.924c-11.779,-11.046 -28.008,-17.877 -45.916,-17.877c-35.947,0 -65.131,27.522 -65.131,61.421l0,23.39l130.262,0Zm-40.941,87.013c-0,-8.01 -6.504,-14.514 -14.514,-14.514l-19.352,0c-8.01,0 -14.514,6.504 -14.514,14.514l0,36.486c0,8.01 6.504,14.514 14.514,14.514l19.352,-0c8.01,-0 14.514,-6.504 14.514,-14.514l-0,-36.486Z"/></svg>`;

  //
  const _isAdmin = authCtx.isAdmin === true || authCtx.isAdmin === "true";
  const _isWindowLaMiaMusica = window.location.href.includes("lamiamusica");
  const _isWindowCategorie = window.location.href.includes("categorie");

  //#endregion

  return (
    <div data-id={props.idPlaylist} ref={playlistRef} className={style.cardS}>
      <a onClick={navLink}>
        <div
          className={style.cardS_bg}
          style={{ backgroundImage: `url(${setBackgroundImage()})` }}
        >
          <button
            className={`${style.btnPreview} ${
              currentPlaylist.Id && currentPlaylist.Id === props.idPlaylist
                ? style.show
                : ""
            }`}
            onClick={handlePlayPlaylist}
          >
            <img
              src={playRoundIcon}
              width={40}
              height={40}
              alt="Ascolta anteprima"
            />
          </button>
        </div>
      </a>
      <div className={style.cardS_description}>
        <div className={style.cardS_description_text}>
          <h5 className="ellipsis">            
            {props.private && 
            (<img
              src={IconLock}
              alt="privata"
              title="privata"              
              style={{height:"20px" , width:"20px", position:"relative", top:"4px"}}
            />)
            }
            {props.itemsName}
          </h5>
          <h6 className="ellipsis">{props.itemsDesc}</h6>
        </div>
        {(_isWindowLaMiaMusica || (_isAdmin && _isWindowCategorie)) && (
          <BtnDots>
            {/* onClick={postHanlderFavorite_Add} */}
            <ul>{props.btnContent}</ul>
          </BtnDots>
        )}
      </div>
    </div>
  );
};

CardSmall.defaultProps = {
  nestedCard: false,
};

export default CardSmall;
