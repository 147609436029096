import React, { useContext, useEffect, useState } from "react";
import BtnOptions from "../buttons/BtnOptions"
import CardSmall from "../cards/CardSmall"
import SearchBox from "../commons/SearchBox"
import RowSmallTrack from "../commons/RowSmallTrack";
import MessageCover from "../commons/MessageCover"

// Styles
import style from "./CategorieRicerca.module.css"
import { success } from "../modals/PopupNotification";
import AuthContext from "../../_store/auth-context";
import jwt_decode from "jwt-decode"

//
const types = ["tutti", "playlist", "brani"]

//
let _contentSongs, _contentPlaylist;

const CategorieRicerca = ({ songs, playlists, searchQuery }) => {
  const [active, setActive] = useState(types[0]);
  const [fetched, setFetched] = useState(false);

  const authCtx = useContext(AuthContext);  

  //#region Functions

  //
  const postHanlderFavorite_Add = (PlaylistID, e) => {

    e.stopPropagation();
    e.preventDefault();

    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();

        success("Playlist aggiunta con successo", true);
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  const filterSearchSongs = (item) => {
    if (
      item.Registrazioni_Titolo.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return item;
    } else {
      return;
    }
  };

  //
  const filterSearchPlaylists = (item) => {
    if (
      item.Nome.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      return item;
    } else {
      return;
    }
  };

  //#endregion

  //#region Content

  //

  if (playlists === undefined || playlists.filter(filterSearchPlaylists).length === 0) {
    _contentPlaylist = <MessageCover content="Non sono presenti playlist che soddisfano i parametri di ricerca" />
  } else {
    _contentPlaylist = playlists.filter(filterSearchPlaylists).map(playlist =>
    (<CardSmall
      key={playlist.PlayListID}
      image={playlist.CoverUrlSharing}
      itemsName={playlist.Nome}
      itemsDesc={playlist.Descrizione}
      idPlaylist={playlist.PlayListID}
      btnContent={
        <li>
          <span
            onClick={(e)=>postHanlderFavorite_Add(playlist.PlayListID ,e)}>
            <h6>aggiungi</h6>
          </span>
        </li>
      }
    />)).slice(0, 10)
  }

  //content Brani Search
  if (songs === null || songs === undefined || songs.filter(filterSearchSongs).length === 0) {
    _contentSongs = <MessageCover content="Non sono presenti brani che soddisfano i parametri di ricerca" />
  } else {
    _contentSongs = songs.filter(filterSearchSongs).map(song =>
      (<RowSmallTrack track={song} />)).slice(0, 20)
  }



  //#endregion


  return (
    <div className={style.searchContainer}>
      <div className={style.searchOptions}>
        {types.map(type => (
          <BtnOptions key={type} placeholder={type} isActive={active === type} onClick={() => setActive(type)} />
        ))}
      </div>
      {active === "tutti" && (<>
        <SearchBox title="Playlist">
          <div className={style.playlistContainer}>
            {_contentPlaylist}
          </div>
        </SearchBox>

        <SearchBox title="Brani">
          <div className={style.trackContainer}>
            {_contentSongs}
          </div>
        </SearchBox>
      </>
      )}
      {active === "playlist" && (<>
        <SearchBox title="Playlist">
          <div className={style.playlistContainer}>
            {_contentPlaylist}
          </div>

        </SearchBox>
      </>
      )}
      {active === "brani" && (<>
        <SearchBox title="Brani">
          <div className={style.trackContainer}>
            {_contentSongs}
          </div>
        </SearchBox>
      </>
      )}
    </div>
  )
}
export default CategorieRicerca