import React from "react";
import style from "./Header.module.css";
import logo from "../../_assets/loghi/grm.png";
import Nav from "./Nav";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className={style.headerContainer}>
      <div className={style.headerContent}>
        <div className={style.logoContainer}>
          <Link to="/">
            <img src={logo} alt="GRM Utilizzatore logo" className={style.logo} />
          </Link>
        </div>
        <Nav />
      </div>
    </div>
  );
};

export default Header;
