import React, { useContext, useState } from "react";
import BtnDots from "../buttons/BtnDots";
import IconAdd from "../../_assets/icone/plus-green.svg";
import { success, warning } from "../modals/PopupNotification";
import { useNavigate } from "react-router-dom";
import CoverImageDefault from "../../_assets/immagini/defaultPlaylist.png";
import jwt_decode from "jwt-decode"


//Styles
import style from "./RowPlayer.module.css";
import PopupConferma from "../modals/PopupConferma";
import AuthContext from "../../_store/auth-context";

const RowPlayer = (props) => { 

  //#region UseContex

  //
  const authCtx = useContext(AuthContext);

  //#endregion
  //#region UseState

  //
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);

  //#endregion

  let navigate = useNavigate();
  const navLink = () => {
    navigate(`/playlist/${props.PlaylistID}`);
  };

  //#region Functions

  //
  const postHanlderPlaylist_Add = () => {
    
    const fetchData = async () => {
      try {
        //
        const params = {          
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlayerID: props.activePlayer.PlayerID,
          PlaylistID: props.PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/playlist_add",
          options
        );
        const data = await response.json();

        if (response.status === 200) {
          //

          const rowToAdd = {            
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            Cover: props.coverSrc ? props.coverSrc : CoverImageDefault,
            CreatedDate: null,
            Descrizione: props.desc,
            LastUpdate: null,
            Nome: props.nome,
            PlayListID: props.PlaylistID,
            PlayerPlaylist_PlayerID: props.activePlayer.PlayerID,
            PlayerPlaylist_PlaylistID: props.PlaylistID,
            UserIDLastUpdate: null,
            tag: null,
          }

          props.setPlaylists((prev) => [
            ...prev,
            rowToAdd
          ]);

          //
          success("Playlist aggiunta con successo", true);
        } else {
          warning("La playlist è gia contenuta nel player", true);
        }
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  const hdnalerDeletePlaylistFromPlaer = () => {
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlayerID: props.activePlayer.PlayerID,
          PlaylistID: props.PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/player/playlist_delete",
          options
        );
        const data = await response.json();

        props.setPlaylists((prev) => [
          ...prev.filter((item) => item.PlayListID != props.PlaylistID),
        ]);
      } catch (error) {}
    };

    //
    fetchData();
  };
  //#endregion

  return (
    <div className={style.row}>
      <a onClick={navLink}>
        <div className={style.rowHead}>
          <div className={style.rowSubHeadImage}>
            <img
              src={props.coverSrc ? props.coverSrc : CoverImageDefault}
              width={50}
              height={50}
              alt="Cover Brano"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h4>{props.nome}</h4>
            <h5>{props.desc}</h5>
          </div>
        </div>
      </a>
      <div style={{ display: props.btnDotsDisplay }}>
        <BtnDots>
          <ul>
            <li>
              <span onClick={() => setIsPopupOpen(true)}>
                <h6>rimuovi dal player</h6>
              </span>
            </li>
          </ul>
        </BtnDots>
      </div>
      <div
        className={style.btnAddDisplay}
        style={{ display: props.btnAddDisplay }}
        onClick={() => postHanlderPlaylist_Add()}
      >
        <img width={20} height={20} src={IconAdd} alt="aggiungi playlist" />
      </div>
      {isPopupOpen && (
        <PopupConferma
          setIsOpen={setIsPopupOpen}
          onConfirm={() => {
            setIsPopupOpen(false);
            hdnalerDeletePlaylistFromPlaer();
            success(props.successMessage, true);
          }}
        />
      )}
    </div>
  );
};

RowPlayer.defaultProps = {
  btnDotsDisplay: "flex",
  btnAddDisplay: "none",
  btnAddPlaylistClick: () => {},
};

export default RowPlayer;
