import React from "react";
import style from "./Subtitle.module.css";
const Subtitle = (props) => {
  return (
    <div className={style.subtitle}>
      <h3>{props.subtitle}</h3>
    </div>
  );
};
export default Subtitle;
