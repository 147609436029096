import React, { useContext, useEffect, useState } from "react"
import Btn from "../buttons/Btn"
import IconPlaylist from "../../_assets/icone/list-playlist.svg"
import jwt_decode from "jwt-decode"
import ToggleSwitch from "../commons/ToggleSwitch"

// Styles
import style from "./PopupCreaPlaylist.module.css"
import AuthContext from "../../_store/auth-context"

const PopupCreaPlaylist = (props) => {  
  const authCtx = useContext(AuthContext)

  //#region useState
  
  const [playlistName, setPlaylistName] = useState();
  const [playlistDescription, setPlaylistDescription] = useState();
  const [fileName, setFileName] = useState({
    "--contentR": "'Scegli la cover ideale'"
  });
  
  //#endregion

  // useEffect(()=>{
  //   //
  //   // const fetchCollezioniList = async () => {
  //   //   try {
  //   //     const response = await fetch(
  //   //       process.env.REACT_APP_GRM_SERVICES +
  //   //         "/api/v1/Utilizzatore/collection_list?" +
  //   //         new URLSearchParams({
  //   //           CompanyID: process.env.REACT_APP_COMPANYID_DJ,
  //   //           IsTrending: false
  //   //         }),
  //   //       {
  //   //         method: "get",
  //   //         headers: new Headers({
  //   //           "Content-type": "application/json",
  //   //           "Access-Control-Allow-Origin": "*",
  //   //           // Authorization:
  //   //           //   "Bearer " +
  //   //           //   authCtx.token,
  //   //         }),
  //   //       }
  //   //     );
  //   //     const data = await response.json();
        
  //   //     let _collections = [
  //   //       ...new Map(
  //   //         JSON.parse(data)
  //   //         .map((item) => [item.CollezioneID, item])
  //   //         .values()
  //   //         ),
  //   //       ];
           
  //   //     //      
  //   //     _collections.sort((a,b) => {if(a[1].Nome_Collezione < b[1].Nome_Collezione){return -1} if(a[1].Nome_collezione > b[1].Nome_Collezione){return 1}})
        
  //   //     const sel = document.getElementById("ddlcollections");

  //   //     if(document.getElementById("ddlcollections").options.length == 0)
  //   //       for(let i = 0; i < _collections.length; i++ ){
  //   //         const opt = document.createElement("option");
          
  //   //         opt.value = _collections[i][1].CollezioneID;
  //   //         opt.text = _collections[i][1].Nome_Collezione;

  //   //         sel.add(opt, null);
  //   //       }
          
  //   //     setTimeout(() => {
  //   //     }, "200");
  //   //   } catch (error) {}
  //   // };

  //   //
  //   // fetchCollezioniList();
  // },[])

  //#region functions
  
  const onChangePlaylistName = (e) =>{
    setPlaylistName(e.target.value);
  }

  const onChangePlaylistDescription = (e) =>{
    setPlaylistDescription(e.target.value);
  }

  const onFileChange = (e) =>{
    
    if(document.getElementById(e.target.id).files[0] !==undefined && document.getElementById(e.target.id).files[0] !==null){
            
      // setPlaylistDescription(e.target.value);
      
      setFileName({
        "--contentR": document.getElementById(e.target.id).files[0].name
      })
    }
    else{
      setFileName({
        "--contentR": "'Scegli la cover ideale'"
      })
    }
  }

  //#endregion

  return (<>
    <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
    <div className={style.centered}>
      <div className={style.modal}>
        <div className={style.modalHeader}>
          <img src={IconPlaylist} width={25} height={25} alt="Icon" /> ;
          <h4 className={style.heading}>Crea una nuova playlist</h4>
        </div>
        <button className={style.closeBtn} onClick={() => props.setIsOpen(false)}>
          x
        </button>
        <div className={style.modalContent}>
          <div className={style.actionsContainer}>
            <label htmlFor="playlistName">Nome </label>
            <input id="playerName" type="text" placeholder="Inserisci il nome della nuova playlist" onChange={onChangePlaylistName}/>
            <label htmlFor="playlistDesc">Descrizione</label>
            <input id="playerDesc" type="text" placeholder="Inserisci una descrizone del player" onChange={onChangePlaylistDescription} required/>
            
            {/* {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<label htmlFor="playlistDesc">Collezione</label>)} */}
            {/* {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
              <select name="ddlcollections" id="ddlcollections">                
              </select>
            )} */}
            
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<label htmlFor="playlistDesc">Cover</label>)}
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<input id="playerCover" type="File" className={style.custom_file_input} onChange={onFileChange} style={fileName}/>)}
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<label htmlFor="playlistDesc">Playlist privata</label>)}          
            {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (<ToggleSwitch isPlaylistPrivate={props.isPlaylistPrivate} setIsPlaylistPrivate={props.setIsPlaylistPrivate}/>)}            
            <Btn onClick={() => props.onConfirm(playlistName, playlistDescription)} placeholder="CREA" width="100%" transform="scale(.99)" />
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
export default PopupCreaPlaylist