import React from "react"
import style from "./ExplicitLabel.module.css"

const ExplicitLabel = (props) => {
  return (
    <span className={style.explicit}><h6 style={{ background: props.background, font: props.font, color: props.color, border: props.border }}>{props.value}</h6></span>
  )
}

ExplicitLabel.defaultProps = {
  background: "rgba(0, 0, 0, 0.6)",
  font: "var(--h6-m)",
  color: "var(--white)",
  border: "none",
  value: ""
}
export default ExplicitLabel