import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BtnBack from "../_components/buttons/BtnBack";
import Title from "../_components/commons/Title";
import Subtitle from "../_components/commons/Subtitle";
import Loader from "../_components/commons/Loader";
import CardSmall from "../_components/cards/CardSmall";
import Footer from "../_components/layout/Footer";
import jwt_decode from "jwt-decode"

//Style
import style from "./collection.module.css";
import AuthContext from "../_store/auth-context";
import { success } from "../_components/modals/PopupNotification";

const Collection = () => {

  //#region  context

  //
  const authCtx = useContext(AuthContext);  

  //#endregion
  
  let _content = null;

  const [playlists, setPlaylists] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);

  const searchParams = useParams();

  //
  const postHanlderFavorite_Add = (PlaylistID, e) => {

    e.stopPropagation();
    e.preventDefault();

    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();

        success("Playlist aggiunta con successo", true);
      } catch (error) {}
    };

    //
    fetchData();
  };


  //onPageLoad
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/Collection/?" +
            new URLSearchParams({
              CollectionID: searchParams.id,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();

        setPlaylists(JSON.parse(data));

        setLoading(false);
        setFetched(true);
      } catch (error) {}
    };

    fetchData();
  }, []);

  //
  const fetchReturnNullObject = !playlists && fetched;
  
  const fetchReturnEmptyArray =
    playlists !== null && playlists.length === 0 && fetched;

  const fetchReturnValidObject = fetched && playlists && playlists.length > 0;

  if (fetchReturnNullObject.valueOf()) {
    _content = "Non sembrano essere presenti playlist in questa collezione";
  }

  if (fetchReturnEmptyArray.valueOf()) {
    _content = "Non sembrano essere presenti playlist in questa collezione";
  }

  if (fetchReturnValidObject.valueOf()) {
    _content = (
      <>
        <Title title={playlists[0].Nome_Collezione} />
        <Subtitle subtitle={`${playlists.length} playlist`} />
        <div className={style.playlistContainer}>
          {playlists.map((playlist) => (
            <CardSmall
              key={playlist.PlaylistID}
              image={playlist.Cover_Playlist}
              itemsName={playlist.Nome_Playlist}
              itemsDesc={playlist.Descrizione_Playlist}
              idPlaylist={playlist.PlaylistID}
              btnContent={
                <li>
                  <span
                    onClick={(e)=>postHanlderFavorite_Add(playlist.PlaylistID,e)}>
                    <h6>aggiungi</h6>
                  </span>
                </li>
              }
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="container">
          <BtnBack text="indietro" to="/categorie" />
          {_content}
        </div>
      )}
      <Footer />
    </>
  );
};

export default Collection;
