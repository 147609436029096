import React from "react"
import { useDrag } from "react-dnd";

// Styles
import style from "./CardSlot.module.css"

const CardSlot = (props) => {


  // // Make playlist draggable
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: "playlist",
  //   item: {
  //     id: props.idPlaylist,
  //     image: props.image,
  //     nome: props.itemsName,
  //     height: props.height,
  //     positionTop: props.positionTop,
  //     _start: 0,
  //     _end: 0,
  //     day: 0
  //   },
  //   collect: (monitor) => ({
  //     isDragging: !!monitor.isDragging()
  //   })
  // }))

  return (
    <div className={style.slotContainer} style={{
      cursor: 'move',
      height: props.height,
      top: props.positionTop,
      marginBottom: props.marginBottom
    }}>

      <div className={style.slotHead}>
        <span></span>
      </div>
      <div className={style.slotBody}>
        <p>{props.itemsName}</p>
      </div>
      <div className={style.slotFooter}></div>
    </div>
  )
}
export default CardSlot