import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CardSmall from "../_components/cards/CardSmall";
import Loader from "../_components/commons/Loader";
import Title from "../_components/commons/Title";
import LaMiaMusicaTabOption from "../_components/containers/LaMiaMusicaTabOption";
import Footer from "../_components/layout/Footer";
import PopupCaricaAudio from "../_components/modals/PopupCaricaAudio";
import PopupCreaPlaylist from "../_components/modals/PopupCreaPlaylist";
import Tabs from "../_components/tabs/Tabs";
import { success, warning } from "../_components/modals/PopupNotification";
import PopupAlert from "../_components/modals/PopupAlert";
import PopupConferma from "../_components/modals/PopupConferma";
import MessageCover from "../_components/commons/MessageCover";
import jwt_decode from "jwt-decode";


//Style
import style from "./laMiaMusica.module.css";
import AuthContext from "../_store/auth-context";

const LaMiaMusica = () => {
  //#region  context

  const DefaultCollectionID = 4;

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  let _playlists = null;
  let _programmazioni = null;
  let _pubblicità = null;

  const [playlists, setPlaylists] = useState(null);
  const [playlistToDeleteID, setPlaylistToDeleteID] = useState(null);
  const [playlistToDeleteName, setPlaylistToDeleteName] = useState(null);
  const [programmazioni, setProgrammazioni] = useState(null);
  const [pubblicità, setPubblicità] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [search, setSearch] = useState("");

  const [isPlaylistPrivate, setIsPlaylistPrivate] = useState(true);

  const navigate = useNavigate();

  //onPageLoad
  useEffect(() => {
    //
    const fetchData = async (Private) => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_list?" +
            new URLSearchParams({
              All: 1,
              Private:Private,
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              playlistID: null,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();          
        //
        setPlaylists(JSON.parse(data));
        setLoading(false);
        setFetched(true);
      } catch (error) {}
    };

    //se l'utente non è amministratore non passo alcun parametro alla funzione, così da farmi tornare le playlist pubbliche(dell'amministratore)
    //e quelle private(dell'utente). Nel caso dell'amministratore viene invece passato il parametro true così da far tornare unicamente le proprie
    //playlist private.
    if(authCtx.isAdmin === false || authCtx.isAdmin === "false")
      fetchData();
    else
      fetchData(true);
  }, []);

  //#region Functions

  const handlerDeletePlaylist = () => {
    const POSTdeleteFromFavourites = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistToDeleteID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_delete",
          options
        );

        //const data = await response.json();

        success("Elemento rimosso con successo", true);

        setPlaylists((prev) => [
          ...prev.filter((item) => item.PlayListID != playlistToDeleteID),
        ]);
      } catch (error) {}
    };

    const POSTdeleteFromCollezioniPlaylist = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistToDeleteID,
          CollectionID: null,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/Playlist/collection_delete",
          options
        );
      } catch (error) {}
    };

    const POSTdeletePlaylist = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: playlistToDeleteID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    const POSTdeleteCoverFromBlob = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          Cover: playlistToDeleteName.replace(" ", "_"),
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/cover_delete",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    POSTdeleteFromFavourites();

    //cancellazione playlist
    POSTdeletePlaylist();

    // cancellazione relazione con collezione
    POSTdeleteFromCollezioniPlaylist();

    //cancellazione copertina da blob
    POSTdeleteCoverFromBlob();
  };

  const POSTHanlderFavorite_Add = (PlaylistID) => {
    const POSTaddPlaylistToFavourite = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    POSTaddPlaylistToFavourite();
  };

  //
  const handlerAddPlaylist = (PlaylistName, PlaylistDescription) => {
    //
    const POSTaddCoverToPlaylist = async () => {
      //
      try {
        let bodyData = new FormData();
        bodyData.append(
          "File",
          document.getElementById("playerCover").files[0]
        );
        bodyData.append("Playlist_name", PlaylistName.replace(" ", "_"));
        //
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: bodyData,
        };        

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/utilizzatore/playlist/cover_add",
          options
        );

        const data = await response.json();

        //ritorna l'url
        return data;
      } catch (error) {}
    };

    //
    const POSTaddPlaylistCollezioni = async (PlaylistID) => {
      //
      try {
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          // CollectionID: document.getElementById("ddlcollections").value,
          CollectionID: DefaultCollectionID,
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/Utilizzatore/Playlist/collection_add",
          options
        );

        const data = await response.json();

        setTimeout(() => {}, "200");
      } catch (error) {}
    };

    //
    const POSTupdatePlaylist = async (
      PlaylistID,
      Name,
      Description,
      CoverUrlSharing
    ) => {      
      //
      try {        
        //        
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: PlaylistID,
          PlaylistName: Name,
          PlaylistDescription: Description,
          Cover:
            Name.toLowerCase().replace(" ", "_") +
            "_" +
            document.getElementById("playerCover").files[0].name,
          CoverUrlSharing: CoverUrlSharing.slice(1, -1),
          Private: isPlaylistPrivate.toString(),
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
               "Bearer " +
                authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/Utilizzatore/playlist_update",
          options
        );

        const data = await response.json();

        setTimeout(() => {}, "200");
      } catch (error) {}
    };

    //
    const addPlaylist = async () => {
      try {        
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistName: PlaylistName,
          PlaylistDescription: PlaylistDescription,
          Private: isPlaylistPrivate.toString(),
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_add",
          options
        );

        //ritorna Id della playlist creata
        const data = await response.json();

        //Aggiunge la playlist a una collezione
        POSTaddPlaylistCollezioni(Math.trunc(data));

        //Aggiunge la nuova playlist alla sezione "Mia Musica"
        POSTHanlderFavorite_Add(Math.trunc(data));

        //Carica la cover sul blob
        let _coverUrl = await POSTaddCoverToPlaylist();

        //update del record della playlist aggiungendo il nome del file e la cover
        POSTupdatePlaylist(
          Math.trunc(data),
          PlaylistName,
          PlaylistDescription,
          _coverUrl
        );

        //Aggiunge la playlist appena creata in locale
        if (
          document.getElementById("playerCover") !== undefined &&
          document.getElementById("playerCover") !== null &&
          document.getElementById("playerCover").files[0] !== undefined &&
          document.getElementById("playerCover").files[0] !== null
        )

          setPlaylists((prev) => [
            ...prev,
            {
              PlayListID: Math.trunc(data),
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              // Playlist_CompanyID: process.env.REACT_APP_COMPANYID_USER,
              Playlist_CompanyID: jwt_decode(authCtx.token).unique_name.split(
                "|"
              )[2],
              Nome: PlaylistName,
              Descrizione: PlaylistDescription,
              CoverUrlSharing: _coverUrl,
              Private: true,
              Cover:
                PlaylistName +
                "_" +
                document.getElementById("playerCover").files[0].name,
              tag: null,
            },
          ]);
        else
          setPlaylists((prev) => [
            ...prev,
            {
              PlayListID: Math.trunc(data),
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              Playlist_CompanyID: jwt_decode(authCtx.token).unique_name.split(
                "|"
              )[2],
              Nome: PlaylistName,
              Descrizione: PlaylistDescription,
              Private: true,
              tag: null,
            },
          ]);

        setIsPopupOpen(false);
        success("Nuova playlist salvata con successo", true);
      } catch (error) {}
    };

    //
    const _isValidName = PlaylistName != undefined && PlaylistName.length > 0
    const _isValidDescription = PlaylistDescription != undefined && PlaylistDescription.length > 0

    if(_isValidName && _isValidDescription){
      addPlaylist();
    }else{
      warning("Attenzione, tutti i campi devono essere compilati", true)
    }
  };

  //
  const filterSearch = (item) => {
    if (item.Nome.toLowerCase().includes(search.toLowerCase())) {
      return item;
    } else {
      return;
    }
  };

  //#endregion
  //#region build Content

  //content Playlist
  const fetchReturnNullObjectPlaylist = !playlists && fetched;
  const fetchReturnEmptyArrayPlaylist =
    playlists !== null && playlists.length === 0 && fetched;
  const fetchReturnValidObjectPlaylist =
    fetched && playlists && playlists.length > 0;

  if (
    fetchReturnNullObjectPlaylist.valueOf() ||
    fetchReturnEmptyArrayPlaylist.valueOf()
  ) {
    _playlists = (
      <>
        <LaMiaMusicaTabOption
          search={search}
          btnGreenOnClick={() => setIsPopupOpen(true)}
        />
        <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna playlist ai tuoi preferiti" />
      </>
    );
  }

  if (fetchReturnValidObjectPlaylist.valueOf()) {
    if (search.length > 0) {
      if (playlists.filter(filterSearch).length > 0) {
        _playlists = (
          <>
            <LaMiaMusicaTabOption
              search={search}
              setSearch={setSearch}
              btnGreenOnClick={() => setIsPopupOpen(true)}
            />
            {playlists.filter(filterSearch).map((playlist) => (  
              <CardSmall
                key={playlist.PlayListID}
                image={playlist.CoverUrlSharing}
                private={playlist.Private}
                itemsName={playlist.Nome}
                itemsDesc={playlist.Descrizione}
                idPlaylist={playlist.PlayListID}
                btnContent={
                  <li>
                    <span
                      onClick={() => {
                        setPlaylistToDeleteID(playlist.PlayListID);
                        setPlaylistToDeleteName(playlist.Cover);
                        setIsPopupOpen3(true);
                      }}
                    >
                      <h6>Rimuovi playlist</h6>
                    </span>
                  </li>
                }
              />
            ))}
          </>
        );
      } else {
        _playlists = (
          <>
            <LaMiaMusicaTabOption
              search={search}
              setSearch={setSearch}
              btnGreenOnClick={() => setIsPopupOpen(true)}
            />
            <MessageCover content="Sembra che la playlist che stai cercndo non sia presente" />
          </>
        );
      }
    } else {
      _playlists = (
        <>
          <LaMiaMusicaTabOption
            search={search}
            setSearch={setSearch}
            btnGreenOnClick={() => setIsPopupOpen(true)}
          />
          {playlists.map((playlist) => (            
            <CardSmall
              key={playlist.PlayListID}
              image={playlist.CoverUrlSharing}
              private={playlist.Private}
              itemsName={playlist.Nome}
              itemsDesc={playlist.Descrizione}
              idPlaylist={playlist.PlayListID}
              btnContent={
                <li>
                  <span
                    onClick={() => {
                      setPlaylistToDeleteID(playlist.PlayListID);
                      setPlaylistToDeleteName(playlist.Cover);
                      setIsPopupOpen3(true);
                    }}
                  >
                    <h6>Rimuovi playlist</h6>
                  </span>
                </li>
              }
            />
          ))}
        </>
      );
    }
  }

  //#endregion

  //Programmazioni
  const fetchReturnNullObjectProg = !programmazioni && fetched;
  const fetchReturnEmptyArrayProg =
    programmazioni !== null && programmazioni.length === 0 && fetched;

  const fetchReturnValidObjectProg =
    fetched && programmazioni && programmazioni.length > 0;

  if (fetchReturnNullObjectProg.valueOf()) {
    _programmazioni = (
      <>
        <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna programmazione nella tua musica preferita" />
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenOnClick={() => navigate("/programmazione")}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }
  if (fetchReturnEmptyArrayProg.valueOf()) {
    _programmazioni = (
      <>
        <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna programmazione nella tua musica preferita" />
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenOnClick={() => navigate("/programmazione")}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }
  // if (fetchReturnValidObjectProg.valueOf()) {
  if (false) {
    _programmazioni = (
      <>
        {programmazioni.map((playlist) => (
          <CardSmall
            key={playlist.PlayListID}
            image={playlist.Cover}
            itemsName={playlist.Nome}
            itemsDesc={playlist.Descrizione}
            idPlaylist={playlist.PlayListID}
          />
        ))}
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenOnClick={() => navigate("/programmazione")}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }

  //Pubblicità
  const fetchReturnNullObjectPubblicità = !pubblicità && fetched;
  const fetchReturnEmptyArrayPubblicità =
    pubblicità !== null && pubblicità.length === 0 && fetched;
  const fetchReturnValidObjectPubblicità =
    fetched && pubblicità && pubblicità.length > 0;
  if (fetchReturnNullObjectPubblicità.valueOf()) {
    _pubblicità = (
      <>
        <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna pubblicità nella tua musica preferita" />
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenPlaceholder="carica"
          btnGreenOnClick={() => setIsPopupOpen2(true)}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }
  if (fetchReturnEmptyArrayPubblicità.valueOf()) {
    _pubblicità = (
      <>
        <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna pubblicità nella tua musica preferita" />
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenPlaceholder="carica"
          btnGreenOnClick={() => setIsPopupOpen2(true)}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }
  // if (fetchReturnValidObjectPubblicità.valueOf()) {
  if (false) {
    _pubblicità = (
      <>
        {pubblicità.map((playlist) => (
          <CardSmall
            key={playlist.PlayListID}
            image={playlist.Cover}
            itemsName={playlist.Nome}
            itemsDesc={playlist.Descrizione}
            idPlaylist={playlist.PlayListID}
          />
        ))}
        <LaMiaMusicaTabOption
          btnDotsDisplay="none"
          btnGreenPlaceholder="carica"
          btnGreenOnClick={() => setIsPopupOpen2(true)}
          search={search}
          setSearch={setSearch}
        />
      </>
    );
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="container">
          <div style={{ marginTop: 40 }}>
            <Title title="La mia musica" />
          </div>
          <div className={style.playlistContainer}>
            <Tabs tab1={_playlists} tab2={_programmazioni} tab3={_pubblicità} />
          </div>
        </div>
      )}
      {isPopupOpen && (
        <PopupCreaPlaylist
          setIsOpen={setIsPopupOpen}
          onConfirm={handlerAddPlaylist}
          isPlaylistPrivate={isPlaylistPrivate}
          setIsPlaylistPrivate={setIsPlaylistPrivate}
        />
      )}
      {isPopupOpen2 && <PopupCaricaAudio setIsOpen={setIsPopupOpen2} />}
      {isPopupOpen3 && (
        <PopupAlert
          setIsOpen={setIsPopupOpen3}
          onConfirmAlert={() => {
            setIsPopupOpen3(false);
            setIsPopupOpen4(true);
          }}
        />
      )}
      {isPopupOpen4 && (
        <PopupConferma
          setIsOpen={setIsPopupOpen4}
          onConfirm={() => {
            setIsPopupOpen4(false);
            handlerDeletePlaylist();
          }}
        />
      )}
      <Footer />
    </>
  );
};

export default LaMiaMusica;
