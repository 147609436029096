import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExplicitLabel from "../commons/ExplicitLabel";
import { success, warning } from "../../_components/modals/PopupNotification";
import CoverImageDefault from "../../_assets/immagini/defaultPlaylist.png";
import greenCheckMark from "../../_assets/icone/spunta-verde.svg";

// Styles
import style from "./RowTrack.module.css";
import AuthContext from "../../_store/auth-context";

// Variabili statiche
const src =
  "https://images.unsplash.com/photo-1658210399320-3e5cac496b8d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw1NDM4NnwwfDF8cmFuZG9tfHx8fHx8fHx8MTY2MDU1MTMxOA&ixlib=rb-1.2.1&q=80&w=1080";
const explicit = "ESPLICITO";

const RowTrack = (props) => {
  const authCtx = useContext(AuthContext);

  //#region UseState
  const [isHover, setIsHover] = useState(false);
  const [hoverVisibility, setHoverVisibility] = useState("hidden");
  const [hasBeenAdded, setHasBeenAdded] = useState(false);
  //#endregion

  const searchParams = useParams();

  // duration

  const formatTrackLength = (length) => {
    try {
      return length
        .split(":")
        .slice(1)
        .join(":");
    } catch (exception) {
      return "00:00";
    }
  };

  let _length;
  if (props.length === undefined) {
    _length = "0:00";
  } else {
    _length = formatTrackLength(props.length);
  }

  //#region Functions

  const handleMouseEnter = () => {
    setIsHover(true);
    setHoverVisibility("visible");
  };
  const handleMouseLeave = () => {
    setIsHover(false);
    setHoverVisibility("hidden");
  };

  function addTrack(SongID) {
     
    //
    const addTrckToPlaylist = async () => {      
      try {
        //
        const params = {
          PlaylistID: searchParams.id,
          RegistrazioneID: SongID,
        };
        console.log("params ",params)

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/registrazione_add",
          options
        );

        const temp = await response;
        console.log("temp ",temp)
        const data = await response.json();

        if (response.status !== 404) {
          // Assegna url della canzone e della copertina all'oggetto che viene introdotto nella lista
          if (JSON.parse(data).length >= 2) {
            props.song.SongUrl = JSON.parse(data)[0];
            props.song.CoverUrl = JSON.parse(data)[1];
          } else {
            props.song.SongUrl = JSON.parse(data)[0];
          }

          //Aggiunge la traccia nella lista di canzoni della playlist
          if (
            !(
              props.songsPlaylist.filter(
                (e) => e.RegistrazioneID === props.song.RegistrazioneID
              ).length > 0
            )
          ) {
            props.setSongsPlaylist((prev) =>
              prev !== undefined ? [...prev, props.song] : [props.song]
            );
          }

          //rimuove il brano aggiunto dalla lista
          let first = true;

          props.setSearchSongsFiltered((prev) => [
            ...prev.filter((item) => {
              if(item.props.songID !== SongID){
                return item;
              }
              else{
                if(first){
                  first = false;
                  return item;
                }
              }
            }),
          ])

          setHasBeenAdded(true);

          success("Elemento aggiunto con successo", true);
        } else {
          warning("Traccia non inserita, riprovare più tardi", true);
        }
      } catch (error) {
        console.log("sono un errore: ", error)
      }
    };

    //
    addTrckToPlaylist();
  }

  //#endregion
  return (
    <div
      className={style.row}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={style.rowHead}>
        <div className={style.rowSubHead}>
          <div className={style.rowSubHeadImage}>
            <img
              src={
                props.song.CoverUrl ? props.song.CoverUrl : CoverImageDefault
              }
              width={50}
              height={50}
              alt="Cover Brano"
            />
            <span style={{ visibility: hoverVisibility }}></span>
          </div>
          <div class={style.tooltip}>
            <h5 className={style.rowTitle}>
              {props.title ? props.title : "--"}
            </h5>
            <span class={style.tooltiptext}>
              {props.title ? props.title : "Titolo"}{" "}
            </span>
          </div>
        </div>

        <div class={style.tooltip}>
          <h5 className={style.grey}>
            {props.isrc ? props.isrc : "--"}
          </h5>
          <span class={style.tooltiptext}>
            {props.isrc ? props.isrc : "ISRC"}{" "}
          </span>
        </div>

        <div class={style.tooltip}>
          <h5
            className={style.grey}            
          >
            {props.genre ? props.genre : "--"}
          </h5>
          <span class={style.tooltiptext}>
            {props.genre ? props.genre : "Genere"}{" "}
          </span>
        </div>

        <div class={style.tooltip}>
          <h5 className={style.grey}>
            {props.subgenre ? props.subgenre : "--"}
          </h5>
          <span class={style.tooltiptext}>
            {props.subgenre ? props.subgenre : "Sottogenere"}{" "}
          </span>
        </div>

        <div class={style.tooltip}>
          <h5
            className={style.grey}
            style={{marginLeft: "10px"}}
          >
            {props.artist ? props.artist : " -- "}
          </h5>
          <span class={style.tooltiptext}>
            {props.artist ? props.artist : "Artista"}{" "}
          </span>
        </div>

        {/* <div class={style.tooltip}>
          <h5
            className={style.rowAlbum}
            style={{
              textAlign: props.album ? "" : "center",
            }}
          >
            {props.album ? props.album : "--"}
          </h5>
          <span class={style.tooltiptext}>
            {props.album ? props.album : "Album"}{" "}
          </span>
        </div> */}

        {props.explicitContent === "Esplicito" && (
          <ExplicitLabel
            value={explicit}
            border="1px solid var(--text-secondary)"
            background="transparent"
            font="var(--h7-r)"
            color="var(--text-secondary)"
          />
        )}
      </div>
      <div className={style.rowTail}>
        <div className={style.rowTailEnd}>
          <h5 className={style.grey}>{_length}</h5>
          {(props.songsPlaylist &&
            props.songsPlaylist.some(
              (o) => o.RegistrazioneID === props.songID
            )) ||
          hasBeenAdded ? (
            <img src={greenCheckMark} width={20} height={20} title="Aggiunto" />
          ) : (
            <div
              onClick={() => addTrack(props.songID)}
              className={style.btnAdd}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default RowTrack;
