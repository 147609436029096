import React, { useContext, useState } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  name: "",
  referenteEmail: "",
  isAdmin: "",
  isLoggedIn: false,
  login: (token, name, referenteEmail, IsAdmin) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  
  //
  const initialToken = localStorage.getItem("token");  
  const initialIsAdmin = localStorage.getItem("isAdmin") 
  const [token, setToken] = useState(initialToken);
  const [name, setName] = useState("");
  const [referenteEmail, setReferenteEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(initialIsAdmin);

  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    setName(null);
    setReferenteEmail(null);
    setIsAdmin(null);
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("referenteEmail");
    localStorage.removeItem("isAdmin");
  };

  const loginHandler = (token, name, referenteEmail, IsAdmin) => {    
        
    // let _isAdmin = false;
    

    // if(jwt_decode(token).unique_name.split('|')[2] == process.env.REACT_APP_COMPANYID_DJ){
    //   _isAdmin = true
    // }else{
    //   _isAdmin = false;    
    // }

    //
    setToken(token);    
    setName(name);
    setReferenteEmail(referenteEmail); 
    setIsAdmin(IsAdmin);
    
    //
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("referenteEmail");
    localStorage.removeItem("isAdmin");

    //
    localStorage.setItem("token", token);    
    localStorage.setItem("name", name);    
    localStorage.setItem("referenteEmail", referenteEmail);    
    localStorage.setItem("isAdmin", IsAdmin);    
  };

  const contextValue = {
    token: token,
    name: name,
    referenteEmail: referenteEmail,
    isAdmin: isAdmin,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
