import React from "react";
import LogoCover from "../_assets/loghi/grm_logo_omino.png"
import Footer from "../_components/layout/Footer";
import { useNavigate } from 'react-router-dom';

// Styles
import style from "./notFound.module.css"


function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div onClick={() => navigate(-1)} className={style.button}>
          <div className={style.arrowLeft}></div>
          <div className={style.text}>
            <h5>torna indietro</h5>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.containerBg}>
            <img src={LogoCover} alt="Logo Grm Fun" width={200} />
          </div>
          <div className={style.messageContent}>
            <h1>404</h1>
            <h2>Pagina non trovata</h2>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
