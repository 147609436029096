import React, { useState } from "react"

// Styles
import style from "./LabelTotalDevice.module.css"

const LabelTotalDevice = (props) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <div className={style.container} onMouseEnter={() => { setIsHover(true) }} onMouseLeave={() => { setIsHover(false) }}>
      <h5 className={`${style.info} ${isHover ? style.show : ""}`}>Numero dispositivi disponibili per utilizzo contemporaneo</h5>
      <button className={style.btnDeviceNumber}>
        <h3>{props.value}</h3>
      </button>
    </div>
  )
}
export default LabelTotalDevice
