import React, { useState } from "react";
import CoverInfoIcon from "../../_assets/icone/circle-info.svg";
import Pause from "../../_assets/icone/pause.svg";
import Play from "../../_assets/icone/play.svg";
import Jump from "../../_assets/icone/jump.svg";
// Styles
import style from "./RowLog.module.css";
import { warning } from "../modals/PopupNotification";

const date = new Date().toLocaleString();

const nomeDeviceEmpty = "Dispositvo non ancora associato";
const statoDeviceEmpty =
  "accedi all’app nativa ed inserisci il codice a sei cifre";

const formatTime = (duration) => {
  const seconds = Math.round(duration);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

const RowLog = (props) => {
  let _icon;
  let _title;

  if (props.DeviceEventID === 1) {
    _icon = Play;
    _title = "Play";
  } else if (props.DeviceEventID === 2) {
    _icon = Pause;
    _title = "Pause";
  } else if (props.DeviceEventID === 4) {
    _icon = Jump;
    _title = "Jump";
  }

  let _formattedDate = "";
  if (props.date)    
    _formattedDate =
      props.date.split("T")[1].split(".")[0] +
      " " +
      props.date.split("T")[0].split("-")[2] +
      "/" +
      props.date.split("T")[0].split("-")[1] +
      "/" +
      props.date.split("T")[0].split("-")[0];

  return (
    <div className={style.row}>
      <div className={style.rowHead}>
        {/* viene mostrata se mi trovo nel tab log */}
        {!props.trackLength && (
          <div className={style.rowSubHeadImage}>
            <img
              src={_icon}
              width={17}
              height={17}
              alt="Immagine dispositivo"
              title={_title}
            />
          </div>
        )}
        {!props.trackLength && (
          <div>
            <h4>
              {props.DeviceID} - {props.ISRC} - {props.nomeLog}
            </h4>
          </div>
        )}
        {props.trackLength && (
          <div>
            <h4>
            {_formattedDate} - <button onClick={()=>{warning("In attesa dell'abilitazione della funzionalià \"Filtro\"",true);}} className={style.listBtn}>{props.DeviceID}</button> - <button onClick={()=>{warning("Il dettaglio della traccia è in fase di integrazione",true);}} className={style.listBtn}>{props.ISRC}</button> - {props.nomeLog} - Durata:{" "}
              {formatTime(props.trackLength)}
            </h4>
          </div>
        )}
      </div>
      {!props.trackLength && (
        <div className={style.rowTail}>
          <span>
            <h6>{_formattedDate}</h6>
          </span>
        </div>
      )}
    </div>
  );
};
export default RowLog;
