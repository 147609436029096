import React from "react"

// Styles
import style from "./RowSettings.module.css"
import ToggleSwitch from "./ToggleSwitch"

const RowSettings = (props) => {
  return (
    <div className={style.settingsContainer}>
      <div className={style.settingsHead}>
        <h5>{props.optionTitle}</h5>
        <h6>{props.optionDesc}</h6>
      </div>
      <ToggleSwitch />
    </div>
  )
}
export default RowSettings