import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import IconClose from "../../_assets/icone/close.svg";
import { createRoot } from "react-dom/client";

// Styles
import style from "./PopupNotification.module.css";

// Notification Type
const Type = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

// Creates a container element for the notifications (if it doesn't exist already) and append it directly to the document's body:
export function createNotificationContainer() {
  const portalId = "notifyContainer";
  let element = document.getElementById(portalId);

  if (element) {
    return element;
  }

  element = document.createElement("div");
  element.setAttribute("id", portalId);
  element.className = style.notificationContainer;
  document.body.appendChild(element);
  return element;
}

const container = createNotificationContainer();


// Function
const PopupNotification = (props) => {
  const [isClosing, setIsClosing] = useState(false);
  let timeToClose = 4000

  useEffect(() => {
    if (props.autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [props.autoClose]);

  // We can use createPortal to render the notification in the container we create previously
  return createPortal(
    <div className={`${style.notificationWrapper}  ${isClosing ? style.shrink : ""} `}>
      <div className={`${style.notification} ${style[props.type]}  ${isClosing ? style.slideOut : style.slideIn} `}>
        {props.children}
        <button onClick={() => setIsClosing(true)} className={style.closeButton}>
          <img src={IconClose} width={10} height={10} alt="close notification" />
        </button>
      </div>
    </div>,
    container
  );
}

// We want to be able to create notifications imperatively, by calling functions such as success() or error().The trick is to create a component that is rendered by default and provides us a function to create notifications.
function NotificationsManager(props) {
  let [notifications, setNotifications] = React.useState([]);

  let createNotification = ({ type, autoClose, children }) => {
    setNotifications((prevNotifications) => {
      return [
        ...prevNotifications,
        {
          children,
          type,
          autoClose,
          id: prevNotifications.length,
        },
      ].slice(-3);
    });
  };

  useEffect(() => {
    props.setNotify(({ type, autoClose, children }) =>
      createNotification({ type, autoClose, children })
    );
  }, [props.setNotify]);

  let deleteNotification = (id) => {
    const filteredNotifications = notifications.filter(
      (_, index) => id !== index,
      []
    );
    setNotifications(filteredNotifications);
  };

  return notifications.map(({ id, ...props }, index) => (
    <PopupNotification
      key={id}
      onDelete={() => deleteNotification(index)}
      {...props}
    />
  ));
}


// Notification creation functions
export function info(children, autoClose) {
  return notify({
    type: Type.info,
    children,
    autoClose,
  });
}

export function success(children, autoClose) {
  return notify({
    type: Type.success,
    children,
    autoClose,
  });
}

export function warning(children, autoClose) {
  return notify({
    type: Type.warning,
    children,
    autoClose,
  });
}

export function error(children, autoClose) {
  return notify({
    type: Type.error,
    children,
    autoClose,
  });
}

// Render NotificationsManager in the same container as PopupNotification
const containerElement = createNotificationContainer();
let notify;

const root = createRoot(containerElement)
root.render(<NotificationsManager
  setNotify={(notifyFn) => {
    notify = notifyFn;
  }}
/>)



// https://tinloof.com/blog/how-to-create-react-notifications-with-0-dependencies