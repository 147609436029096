import React, { useState } from "react"
import InputSearch from "../commons/InputSearch"
import RowPlayer from "../commons/RowPlayer"
import CoverImageProg from "../../_assets/immagini/cover-default-programmazione.png"

// Styles
import style from "./PopupAddProgToPlayer.module.css"


const PopupAddProgToPlayer = (props) => {
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState("");
  return (
    <>
      <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <h4 className={style.heading}>Aggiungi programmazione</h4>
          </div>
          <button className={style.closeBtn} onClick={() => props.setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>
            <InputSearch width="100%" background="var(--sidebar-background" placeholder="cerca tra le programmazioni create in La Mia Musica" isSearching={isSearching} setIsSearching={setIsSearching} setSearch={setSearch} search={search} />
            <div className={style.modalContentScroll}>
              <RowPlayer btnAddDisplay="flex" btnDotsDisplay="none" coverSrc={CoverImageProg} nome="Nome Programmazione" desc="Descrizione della programmazione" successMessage="Programmazione aggiunta con successo" />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupAddProgToPlayer