import React, { useState } from "react";

// Styles
import style from "./ToggleSwitch.module.css";

function ToggleSwitch(props) {    
  const _isChecked = (props.isPlaylistPrivate === true || props.isPlaylistPrivate === "True");  
  
  // const [isToggled, setIsToggled] = useState(props.active);
  const onToggle = () => props.setIsPlaylistPrivate(!_isChecked);
  return (
    <label className={style.toggleSwitch}>
      <input type="checkbox" checked={_isChecked} onChange={onToggle} />
      <span className={style.switch} />
    </label>
  );
}
export default ToggleSwitch;