import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../_store/auth-context";
import InputSearch from "../commons/InputSearch";
import RowTrack from "../commons/RowTrack";
import jwt_decode from "jwt-decode";

// Styles
import style from "./PopupAddBrano.module.css";
import Loader from "../commons/Loader";

const PopupAddBrano = (props) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //
  const [songs, setSongs] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState("");
  const [searchSongsFiltered, setSearchSongsFiltered] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [firstRender, setFirstRender] = useState(true);

  //
  let _contentSongsToAdd = "";
  //onComponentLoad
  useEffect(() => {
    //se utente business recupero le tracce unicamente dalle playlist già create dal grm musica
    const fetchDataSongsPlaylists = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist?" +
            new URLSearchParams({
              PlaylistID: "",
              CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
              DeviceID: "",
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-type": "application/json",
            }),
          }
        );

        const data = await response.json();

        const _tracce = Array.from(
          new Set(JSON.parse(data).Tracce.map((a) => a.RegistrazioneID))
        ).map((id) => {
          return JSON.parse(data).Tracce.find((a) => a.RegistrazioneID === id);
        });

        //#region content

        _contentSongsToAdd = "";

        _contentSongsToAdd = _tracce
          // .filter(filterSearch)
          .map((item) => (
            <RowTrack
              key={item.RegistrazioneID + Math.random() * 1000}
              song={item}
              isrc={item.Registrazioni_ISRC}
              songID={item.RegistrazioneID}
              length={item.RegistrazioniMultimedia_Durata}
              artist={item.Pubblicazione_ArtistaPrincipale}
              album={item.Pubblicazione_TitoloAlbum}
              minutes={item.Registrazioni_DurataMinuti}
              seconds={item.Registrazioni_DurataSecondi}
              title={item.Registrazioni_Titolo}
              explicitContent={item.Delivery_TipologiaContenuto}
              setSongsPlaylist={props.setSongsPlaylist}
              songsPlaylist={props.songsPlaylist}
              genre={item.Delivery_Genre}
              subgenre={item.Delivery_SubGenre}
              setSearchSongsFiltered={setSearchSongsFiltered}
            />
          ));
        // .slice(0, 30);

        //
        setSearchSongsFiltered(_contentSongsToAdd);

        //#endregion

        setSongs(_tracce);
        setLoading(false);
        setSearchDisabled(false);
      } catch (error) {}
    };

    //se diskjokey
    const fetchDataSongsDelivery = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/registrazioni_delivery?" +
            new URLSearchParams({
              CompanyID: "",
              Start: 1,
              End: 100,
              Titolo: search.toUpperCase(),
              Isrc: search.toUpperCase(),
              Artista: search.toUpperCase(),
              Formato: "MP3",
              Filter: search.toUpperCase(),
              Culture: "",
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-type": "application/json",
            }),
          }
        );

        const data = await response.json();
        const _tracce = JSON.parse(data).Tracce;

        //#region content

        _contentSongsToAdd = "";

        _contentSongsToAdd = _tracce.map((item) => (
          <RowTrack
            key={item.RegistrazioneID + Math.random() * 1000}
            song={item}
            isrc={item.Registrazioni_ISRC}
            songID={item.RegistrazioneID}
            artist={item.Pubblicazione_ArtistaPrincipale}
            album={item.Pubblicazione_TitoloAlbum}
            length={item.RegistrazioniMultimedia_Durata}
            title={item.Registrazioni_Titolo}
            explicitContent={item.Delivery_TipologiaContenuto}
            setSongsPlaylist={props.setSongsPlaylist}
            songsPlaylist={props.songsPlaylist}
            genre={item.Delivery_Genre}
            subgenre={item.Delivery_SubGenre}
            setSearchSongsFiltered={setSearchSongsFiltered}
          />
        ));

        //
        setSearchSongsFiltered(_contentSongsToAdd);

        //#endregion

        //
        setLoading(false);
        setSongs(_tracce);
        setSearchDisabled(false);
      } catch (error) {}
    };

    let timer;

    //controllo se è la prima volta che viene renderizzato il componente
    if (!firstRender) {
      //
      timer = setTimeout(() => {
        //
        setLoading(true);

        //
        if (
          jwt_decode(authCtx.token).unique_name.split("|")[2] ==
          process.env.REACT_APP_COMPANYID_DJ
        ) {
          fetchDataSongsDelivery();
        } else {
          fetchDataSongsPlaylists();
        }
      }, 1500);
    } else {
      //
      setFirstRender(false);
      setLoading(false);
      setSearchDisabled(false);
    }

    return () => clearTimeout(timer);
  }, [search, reload]);

  //#region Functions

  const filterSearch = (item) => {
    if (
      (item.Registrazioni_Titolo !== undefined &&
        item.Registrazioni_Titolo !== null &&
        item.Registrazioni_Titolo.toLowerCase().includes(
          search.toLowerCase()
        )) ||
      (item.genre !== undefined &&
        item.genre !== null &&
        item.genre.toLowerCase().includes(search.toLowerCase())) ||
      (item.subgenre !== undefined &&
        item.subgenre !== null &&
        item.subgenre.toLowerCase().includes(search.toLowerCase())) ||
      (item.Pubblicazione_TitoloAlbum !== undefined &&
        item.Pubblicazione_TitoloAlbum !== null &&
        item.Pubblicazione_TitoloAlbum.toLowerCase().includes(
          search.toLowerCase()
        )) ||
      (item.Registrazioni_ISRC !== undefined &&
        item.Registrazioni_ISRC !== null &&
        item.Registrazioni_ISRC.toLowerCase().includes(search.toLowerCase()))
    ) {
      return item;
    } else {
      return;
    }
  };

  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <h4 className={style.heading}>Aggiungi brani alla playlist</h4>
          </div>
          <button
            className={style.updateBtn}
            onClick={() => {
              setLoading(true);
              setSearchDisabled(true);
              setReload(Math.random())
            }}
          >
            Aggiorna
          </button>
          <button
            className={style.closeBtn}
            onClick={() => props.setIsOpen(false)}
          >
            x
          </button>
          <div className={style.modalContent}>
            <InputSearch
              width="100%"
              background="var(--sidebar-background"
              placeholder="cerca un brano (I campi interessati dalla ricerca sono: titolo, ISRC, Artista, Pubblicazione Genere, Sottogenere)"
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              setSearch={setSearch}
              search={search}
              searchDisabled={searchDisabled}
            />
            {loading && (
              <div className={style.loaderContainer}>
                <Loader />
              </div>
            )}
            {!loading && searchSongsFiltered}
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupAddBrano;
