import React from "react"
import IconAlert from "../../_assets/icone/alert.svg"

// Styles
import style from "./PopupAlert.module.css"
import Btn from "../buttons/Btn"

const PopupAlert = (props) => {

  return (<>
    <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
    <div className={style.centered}>
      <div className={style.modal}>
        <div className={style.modalHeader}>
          <img src={IconAlert} width={25} height={25} alt="Alert Icon" />
          <h4 className={style.heading}>{props.titleHead}</h4>
        </div>
        <button className={style.closeBtn} onClick={() => props.setIsOpen(false)}>
          x
        </button>
        <div className={style.modalContent}>
          <div className={style.alertMessage}>
            <h4>{props.alertMessage}</h4>
            <br />
            <h4>{props.alertMessage2}</h4>
          </div>
          <div className={style.actionsContainer}>
            <Btn placeholder="ANNULLA" btnBackground="var(--sidebar-background)" transform="scale(.99)" width="100%" onClick={() => props.setIsOpen(false)} />
            <Btn placeholder={props.btnAlert} btnBackground="var(--error)" textColor="var(--sidebar-background)" transform="scale(.99)" width="100%" onClick={props.onConfirmAlert} />
          </div>


        </div>
      </div>
    </div>
  </>
  )
}

PopupAlert.defaultProps = {
  titleHead: "Attenzione!",
  alertMessage: `L'elemento che si sta per eliminare è già utilizzato all'interno di una o più configurazioni (player, programmazione).`,
  alertMessage2: `Si consiglia di procedere con l'eliminazione manuale nella sezione player prima di rimuovere definitivamente questo elemento.`,
  btnAlert: "RIMUOVI TUTTO",
  onConfirmAlert: () => { }
}
export default PopupAlert