import React from "react";
import style from "./BtnBack.module.css";
import { NavLink } from "react-router-dom";
const BtnBack = (props) => {
  return (
    <div className={style.button}>
      <NavLink to={props.to}>
        <div className={style.arrowLeft}></div>
        <div className={style.text}>
          <h5>{props.text}</h5>
        </div>
      </NavLink>
    </div>
  );
};
export default BtnBack;
