import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import jwt_decode from "jwt-decode";
import AuthContext from "../_store/auth-context";

const Login = () => {
  //get token from url get request  
  const [searchParams, setSearchParams] = useSearchParams();
  
  let navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  const retriveToken = () => {
    const _token = searchParams.get("token")
    return _token
  }

const saveContext = (Name, ReferenteEmail) =>{
  
    //
    const _token = retriveToken();

    //
    if (_token) {

      //
      try {
        
        //verifica che il token sia valido
        jwt_decode(_token);


        const _isAdmin = jwt_decode(_token).unique_name.split('|')[2] == process.env.REACT_APP_COMPANYID_DJ;        

        //
        //passa il token al context
        authCtx.login(_token, Name, ReferenteEmail, _isAdmin);

        //
        navigate("/", { replace: true });

      } catch (error) {

        //TODO introdurre redirect a pagina di errore
        
      }
    }
  }
  
  useEffect(() => {
    
    let _name = "";
    let _referenteEmail = "";
    
    const __token = retriveToken();      
    const fetchAccountInfo = async () => {
      try {        
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
          "api/v1/utilizzatore/Company?" +
          new URLSearchParams({
            ApplicationID: process.env.REACT_APP_APPID,
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(__token).unique_name.split('|')[2],
            SiteID:process.env.REACT_APP_SITEID,
            start:"1",
            end:"2",
            OrderColumnID:"1",
            OrderSort:"asc",
            Culture:"it-IT",
            }),
            {
              method: "get",
              headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                Authorization:
                "Bearer " +
                __token,
                "Content-type": "application/json",
              }),
            }
            );
            
            //
            const data = await response.json();
                        
            if(response.status != 200){
              window.location.replace('https://grm-app.it//Utilizzatore/Login.aspx?logout=1')
            }                      

            //
            _name = JSON.parse(data).Name;
            _referenteEmail = JSON.parse(data).ReferenteEmail;
            

            if(_name === null){
              _name = "Utente";
            }
            if(_referenteEmail === null){
              _referenteEmail = "";
            }
            saveContext(_name,_referenteEmail)
            //
            
          } catch (error) { 
            window.location.replace('https://grm-app.it//Utilizzatore/Login.aspx?logout=1')
          }
        };
        
        //
        fetchAccountInfo();          
  }, [])

};

export default Login;
