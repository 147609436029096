import React from "react";
import { useState, useRef, useEffect } from "react";
import dots from "../../_assets/icone/dots.svg";
import styles from "./BtnDots.module.css";

const BtnDots = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.composedPath()[0] !== btnRef.current) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);

  return (
    <div className={styles.btnDotsContainer} style={{ visibility: props.visibility, background: props.background, width: props.width, height: props.height, display: props.display }} ref={btnRef} onClick={() => setIsOpen((prev) => !prev)}>
      <img className={styles.btnDots} src={props.src} height={20} alt="Seleziona Opzione" />

      <div style={{ width: props.dropdownWidth, left: props.dropdownLeft }} className={styles.dropdown + " " + (isOpen ? styles.open : styles.close)} onClick={props.onClick}>
        {props.children}
      </div>
    </div>
  );
};

BtnDots.defaultProps = {
  display: "flex",
  visibility: "visible",
  dropdownWidth: "181px",
  dropdownLeft: "-121px",
  background: "transparent",
  width: "30px",
  height: "30px",
  src: dots
};
export default BtnDots;
