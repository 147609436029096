import React, { useContext, useState, Redirect } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./_components/layout/Header";
import Home from "./_pages/home";
import Login from "./_pages/login";
import Logout from "./_pages/logout";
import LaMiaMusica from "./_pages/laMiaMusica";
import Canali from "./_pages/player";
import Playlist from "./_pages/playlist";
import Categorie from "./_pages/categorie";
import Programmazione from "./_pages/programmazione";
import Collection from "./_pages/collection";
import NotFound from "./_pages/notFound";
import AuthContext from "./_store/auth-context";
import Impostazioni from "./_pages/impostazioni";
import MusicPlayer from "./_components/audioPlayer/MusicPlayer";
import MusicPlayerPlaylist from "./_components/audioPlayer/MusicPlayerPlaylist";
import ProgTest from "./_components/_test/ProgContainer"

//Styles
import "./App.css";
import RedirectLogin from "./_pages/redirect";



function App() {
  const authCtx = useContext(AuthContext);

  return (
    <div className="app">
      <Header />
      <Routes>

        {/* dev */}

        {/* <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />}/>
        {authCtx.isLoggedIn && <Route path="/logout" element={<Logout />} />}
        <Route path="/lamiamusica" element={<LaMiaMusica />} />
        <Route path="/canali" element={<Canali />} />
        <Route path="/categorie" element={<Categorie />} />
        <Route path="/collection/:id" element={<Collection />} />
        <Route path="/playlist/:id" element={<Playlist />} />
        <Route path="/programmazione" element={<Programmazione />} />
        <Route path="/impostazioni" element={<Impostazioni />} />
        <Route path="/prog" element={<ProgTest />} />
        <Route path="/redirect" element={<ProgTest />} />
        <Route path="*" element={<NotFound />} /> */}



        {/* production */}
        {authCtx.isLoggedIn ? (<Route path="/" element={<Home />} />) : (<Route path="/" element={<RedirectLogin />} />)}
        {!authCtx.isLoggedIn ? (<Route path="/login" element={<Login />}/> ) : (<Route path="/" element={<Home />} />)}        
        {authCtx.isLoggedIn ? (<Route path="/login*" element={<Login />}/> ) : (<Route path="/" element={<Home />} />)}                
        {authCtx.isLoggedIn && <Route path="/logout" element={<Logout />} />}
        {authCtx.isLoggedIn ? (<Route path="/lamiamusica" element={<LaMiaMusica />} />):(<Route path="/lamiamusica" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/canali" element={<Canali />} />):(<Route path="/canali" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/categorie" element={<Categorie />} />):(<Route path="/categorie" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/collection/:id" element={<Collection />} />):(<Route path="/collection/:id" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/playlist/:id" element={<Playlist />} />):(<Route path="/playlist/:id" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/programmazione" element={<Programmazione />} />):(<Route path="/programmazione" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/impostazioni" element={<Impostazioni />} />):(<Route path="/impostazioni" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/prog" element={<ProgTest />} />):(<Route path="/prog" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="/redirect" element={<ProgTest />} />):(<Route path="/redirect" element={<RedirectLogin />} />)}
        {authCtx.isLoggedIn ? (<Route path="*" element={<NotFound />} />):(<Route path="*" element={<RedirectLogin />} />)}

      </Routes>
      <MusicPlayer />
      <MusicPlayerPlaylist />
    </div>
  );
}
export default App;
