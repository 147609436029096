import React, { useRef, useContext } from "react";
import BtnDots from "../buttons/BtnDots";
import playRoundIcon from "../../_assets/icone/playRoundIcon.svg";
import { useNavigate } from "react-router-dom";
import audioPlayerCtx from "../../_store/audioPlayer/audioPlayerCtx";
import { warning } from "../modals/PopupNotification";
import { success } from "../modals/PopupNotification";
import jwt_decode from "jwt-decode"

// Styles
import style from "./CardBig.module.css";
import AuthContext from "../../_store/auth-context";

const CardBig = (props) => {

  //#region  context

  //
  const authCtx = useContext(AuthContext);  

  //
  const {
    SetSongsList,
    currentPlaylist,
    SetCurrentPlaylist,
    SetCurrentTime,
    SetAudioPlayer,
    SetEmptyPlaylist,
    SetCurrentTimePlaylist,
  } = useContext(audioPlayerCtx);

  //#endregion

  //useRef
  const playlistRef = useRef();

  //#region Functions
  const fetchData = async (playlistID) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_GRM_SERVICES +
          "api/v1/utilizzatore/playlist/?" +
          new URLSearchParams({
            PlaylistID: playlistID,
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],

          }),
        {
          method: "get",
          headers: new Headers({
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      );
      const data = await response.json();
      SetCurrentPlaylist(JSON.parse(data));
      if (JSON.parse(data).Tracce.length > 0) {
        SetEmptyPlaylist(false);
        SetSongsList(JSON.parse(data).Tracce);
      } else {
        SetEmptyPlaylist(true);
        SetCurrentTimePlaylist(0);
        warning(
          "Al momento non è presente nessuna traccia in questa playlist",
          true
        );
      }
    } catch (error) {}
  };

  //
  const postHanlderFavorite_Add = () => {
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: props.idPlaylist,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();

        success("Playlist aggiunta con successo", true);
      } catch (error) {}
    };

    //
    fetchData();
  };
  //#endregion

  const handlePlayPlaylist = (e) => {
    e.stopPropagation();
    e.preventDefault();
    SetAudioPlayer(false);
    SetCurrentTime(0);
    const playlistID = playlistRef.current.getAttribute("data-id");
    fetchData(playlistID);
  };

  let navigate = useNavigate();
  const navLink = () => {
    navigate(`/playlist/${props.idPlaylist}`);
  };

  return (
    <div data-id={props.idPlaylist} ref={playlistRef} className={style.cardB}>
      <a onClick={navLink}>
        <div
          className={style.cardB_bg}
          style={{ backgroundImage: ` url(${props.image})` }}
        >
          <div className={style.cardB_title}>
            <h3>{props.itemsName}</h3>
          </div>
          <button
            className={`${style.btnPreview} ${
              currentPlaylist.Id && currentPlaylist.Id === props.idPlaylist
                ? style.show
                : ""
            } `}
            onClick={handlePlayPlaylist}
          >
            <img
              src={playRoundIcon}
              width={50}
              height={50}
              alt="Ascolta anteprima"
            />
          </button>
        </div>
      </a>
      <div className={style.cardB_description}>
        <h5 className="ellipsis">{props.itemsDesc}</h5>
        {(authCtx.isAdmin === false || authCtx.isAdmin === "false") && <BtnDots dropdownWidth="241px" dropdownLeft="-181px">
          <ul>
            <li>
              <span onClick={postHanlderFavorite_Add}>
                <h6>aggiungi</h6>
              </span>
            </li>
          </ul>
        </BtnDots>}
      </div>
    </div>
  );
};

export default CardBig;
