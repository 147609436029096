import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../_store/auth-context";
import InputSearch from "../commons/InputSearch";
import RowPlayer from "../commons/RowPlayer";
import CoverImageDefault from "../../_assets/immagini/defaultPlaylist.png";
import jwt_decode from "jwt-decode"

// Styles
import style from "./PopupAddPlaylistToPlayer.module.css";

// Static
const CoverImagePlaylist =
  "https://images.unsplash.com/photo-1658210399320-3e5cac496b8d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw1NDM4NnwwfDF8cmFuZG9tfHx8fHx8fHx8MTY2MDU1MTMxOA&ixlib=rb-1.2.1&q=80&w=1080";

const PopupAddPlaylistToPlayer = (props) => {
  
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //#region  UseState

  //
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [playlists, setPlaylists] = useState("");

  //#endregion

  //On component Load
  useEffect(() => {
    //
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_list?" +
            new URLSearchParams({
              All: 1,
              Private:"",
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
              playlistID: null,
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();
        setPlaylists(JSON.parse(data));
      } catch (error) {}
    };

    //
    fetchData();
  }, []);

  //#region Content

  let _content;

  if (playlists && isSearching === false) {
    _content = playlists.map((item) => (            
      <RowPlayer
        btnAddDisplay="flex"
        btnDotsDisplay="none"
        coverSrc={item.CoverUrlSharing ? item.CoverUrlSharing : CoverImageDefault}
        nome={item.Nome}
        desc={item.Descrizione}
        successMessage="Playlist aggiunta con successo"
        PlaylistID={item.PlayListID}
        activePlayer={props.active}
        setPlaylists={props.setPlaylists}
      />
    ));
  }

  if (playlists && isSearching === true) {
    _content = playlists.map((item) => (            
      <RowPlayer
        btnAddDisplay="flex"
        btnDotsDisplay="none"
        coverSrc={item.CoverUrlSharing ? item.CoverUrlSharing : CoverImageDefault}
        nome={item.Nome}
        desc={item.Descrizione}
        successMessage="Playlist aggiunta con successo"
        PlaylistID={item.PlayListID}
        activePlayer={props.active}
        setPlaylists={props.setPlaylists}
      />
    )).filter((item) => (item !== undefined && item !== null && item.props.nome.toLocaleLowerCase().includes(search.toLocaleLowerCase())));
  }
  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <h4 className={style.heading}>Aggiungi playlist</h4>
          </div>
          <button
            className={style.closeBtn}
            onClick={() => props.setIsOpen(false)}
          >
            x
          </button>
          <div className={style.modalContent}>
            <InputSearch
              width="100%"
              background="var(--sidebar-background"
              placeholder="cerca tra le playlist salvate in La Mia Musica"
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              setSearch={setSearch}
              search={search}
            />
            <div className={style.modalContentScroll}>
              {_content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupAddPlaylistToPlayer;
