import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../_components/commons/Loader";
import Footer from "../_components/layout/Footer";
import PlaylistCustomContainer from "../_components/containers/playlistCustom/_PlaylistCustomContainer";
import PlaylistContainer from "../_components/containers/playlist/_PlaylistContainer";
import AuthContext from "../_store/auth-context";
import jwt_decode from "jwt-decode"

const Playlist = () => {  
  //#region  context

  //
  const authCtx = useContext(AuthContext);  

  //#endregion

  //#region UseState
  const [playlist, setPlaylist] = useState(null);
  const [similarPlaylist, setSimilarPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);
  //#endregion

  //
  let navigate = useNavigate();
  const searchParams = useParams();

  //
  let _similarPlaylistID;

  //#region Functionss
  //#endregion

  //onComponentLoad
  useEffect(() => {    
    //
    const fetchData = async () => {      
      console.log("fetchData")
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist?" +
            new URLSearchParams({
              PlaylistID: searchParams.id,
              CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
              DeviceID: "",
            }),
            {
              method: "get",
              headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                Authorization:
                  "Bearer " +
                  authCtx.token,
                  "Content-type": "application/json",
              }),
            }
        );

        const data = await response.json();   
        console.log("data ", data)
        
        //se la playlist contiene canzoni
        if (response.status === 200 && JSON.parse(data) !== null && JSON.parse(data).Name !== null) {          
          //
          setPlaylist(JSON.parse(data));
          
        } else {
          //
          const resp = await fetch(
            process.env.REACT_APP_GRM_SERVICES +
              "api/v1/utilizzatore/playlist_list?" +
              new URLSearchParams({
                All: 1,
                Private: "",
                CompanyID: "",
                playlistID: searchParams.id,
              }),
              {
                method: "get",
                headers: new Headers({
                  "Access-Control-Allow-Origin": "*",
                  Authorization:
                    "Bearer " +
                    authCtx.token,
                  "Content-Type": "application/x-www-form-urlencoded",
                }),
              }
          );

          const data = await resp.json();          

          //playlist not found
          if (JSON.parse(data).length === 0) {
            navigate("/");
          }

          //

          setPlaylist(JSON.parse(data)[0]);
        }

        setLoading(false);
        setFetched(true);
      } catch (error) {}
    };

    const fetchDataSimilarPlaylist = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/Collection_list?" +
            new URLSearchParams({
              CompanyID: process.env.REACT_APP_COMPANYID_DJ,
              IsTrending: false,
            }),
            {
              method: "get",
              headers: new Headers({
                "Access-Control-Allow-Origin": "*",
                Authorization:
                  "Bearer " +
                  authCtx.token,
              }),
            }
        );
        const data = await response.json();

        _similarPlaylistID = JSON.parse(data)
          .filter((item) => item.PlaylistID == searchParams.id)
          .map((i) => i.CollezioneID);
        setSimilarPlaylist(
          JSON.parse(data).filter(
            (item) =>
              item.PlaylistID != searchParams.id &&
              item.CollezioneID == _similarPlaylistID[0]
          )
        );
      } catch (error) {}
    };

    //
    fetchData();
    fetchDataSimilarPlaylist();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && fetched && (
        <div style={{ background: "var(--white)" }}>              
          {playlist.CompanyID == process.env.REACT_APP_COMPANYID_DJ && (
            <PlaylistContainer
              playlist={playlist}
              similarPlaylist={similarPlaylist}
            />
          )}
          {playlist.CompanyID != process.env.REACT_APP_COMPANYID_DJ && (
            <PlaylistCustomContainer playlist={playlist} />
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default Playlist;
