import React from "react"
import RowSettings from "../commons/RowSettings"
import ToggleSwitch from "../commons/ToggleSwitch"
import TabsImpostazioni from "../tabs/TabsImpostazioni"

// Styles
import style from "./ImpostazioniContainer.module.css"


const PlayerContainer = (props) => {
  // const [isActive, setIsActive] = useState(true)
  // const [isAssociated, setIsAssociated] = useState(true)

  let _contentGenerali = <div className={style.rowContainer}>
    <RowSettings optionTitle="Opzione 1" optionDesc="Descrizione opzione 1" />
    <RowSettings optionTitle="Opzione 2" optionDesc="Descrizione opzione 2" />
    <RowSettings optionTitle="Opzione 3" optionDesc="Descrizione opzione 3" />
  </div>

  let _contentAccount = <div className={style.rowContainer}>
    <RowSettings optionTitle="Opzione 1" optionDesc="Descrizione opzione 1" />
    <RowSettings optionTitle="Opzione 2" optionDesc="Descrizione opzione 2" />
    <RowSettings optionTitle="Opzione 3" optionDesc="Descrizione opzione 3" />
    <RowSettings optionTitle="Opzione 4" optionDesc="Descrizione opzione 4" />
    <RowSettings optionTitle="Opzione 5" optionDesc="Descrizione opzione 5" />
    <RowSettings optionTitle="Opzione 6" optionDesc="Descrizione opzione 6" />
  </div>

  let _contentSottoscrizione = <div className={style.rowContainer}>
    <RowSettings optionTitle="Opzione 1" optionDesc="Descrizione opzione 1" />
    <RowSettings optionTitle="Opzione 2" optionDesc="Descrizione opzione 2" />
  </div>

  let _contentCronologia = <div className={style.rowContainer}>
    <RowSettings optionTitle="Opzione 1" optionDesc="Descrizione opzione 1" />
    <RowSettings optionTitle="Opzione 2" optionDesc="Descrizione opzione 2" />
    <RowSettings optionTitle="Opzione 3" optionDesc="Descrizione opzione 3" />
  </div>


  return (
    <div className={style.playerContainer}>
      <div id={props.id} className={style.playerContainerHead}>
        <h2>{props.player}</h2>
      </div>
      <TabsImpostazioni
        tabGenerale={_contentGenerali}
        tabAccount={_contentAccount}
        tabSottoscrizione={_contentSottoscrizione}
        tabCronologia={_contentCronologia}
      />
    </div>
  )
}

export default PlayerContainer