import React, { useEffect, useState } from "react";
import InputSearch from "../../commons/InputSearch";
import PlaylistCustomBrano from "./PlaylistCustomBrano";
import MessageCover from "../../commons/MessageCover";

// Styles
import style from "./PlaylistCustomBody.module.css";

const PlaylistCustomBody = ({
  songs,
  setSongs,
  lastUpdate,
  hours,
  minutes,
  seconds,
}) => {
  //Content
  let _content = null;
  let _contentLastUpdate = "";

  //Exist
  let _existLastUpdate = lastUpdate;
  let _existContent = false;

  //#region UseState
  const [inputSearchWidth, setInputSearchWidth] = useState("50px");
  const [songsToDisplay, setSongsToDisplay] = useState(10);
  const [inputSearchBorder, setInputSearchBorder] = useState("none");
  const [inputSearchPlaceholder, setInputSearchPlaceholder] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [
    displayAllSongsButtonVisibility,
    setdisplayAllSongsButtonVisibility,
  ] = useState(true);
  const [search, setSearch] = useState("");
  //#endregion

  //onPageLoad
  useEffect(() => {
    //
    if (!songs) {
      setdisplayAllSongsButtonVisibility(false);
      return;
    }
    //
    if (songs === undefined || songs.length < 11) {
      setdisplayAllSongsButtonVisibility(false);
    }
  }, []);

  //#region Functions

  //
  const handleClickOutside = () => {
    setInputSearchWidth("50px");
    setInputSearchBorder("none");
    setInputSearchPlaceholder("");
    setIsSearching(false);
    setSearch("");
  };

  const handleClick = () => {
    setInputSearchWidth("300px");
    setInputSearchBorder("1px solid #ccc");
    setInputSearchPlaceholder("cerca un brano");
  };

  //
  const countTracks = () => {
    if (songs === undefined) {
      return "0 Brani";
    }
    if (songs.length === 1) {
      return songs.length + " Brano";
    }
    if (songs.length > 1) {
      return songs.length + " Brani";
    } else {
      return "Non sono presenti brani";
    }
  };

  //
  const formatPlaylistDuration = () => {
    if (hours === undefined) {
      hours = 0;
    }
    if (minutes === undefined) {
      minutes = 0;
    }
    if (seconds === undefined) {
      seconds = 0;
    }

    if (hours !== 0) {
      if (hours === 1) {
        return hours + " Ora " + minutes + " Minuti " + seconds + " Secondi";
      } else {
        return hours + " Ore " + minutes + " Minuti " + seconds + " Secondi";
      }
    }
    if (minutes !== 0) {
      return minutes + " Minuti " + seconds + " Secondi";
    }
    if (seconds !== 0) {
      return seconds + " Secondi";
    } else return "0 Secondi";
  };

  //
  const filterSearch = (item) => {
    if (
      item.Registrazioni_Titolo.toLowerCase().includes(search.toLowerCase())
    ) {
      return item;
    } else {
      return;
    }
  };

  //
  const displayAllSongsButtonVisibilityFn = () => {
    setdisplayAllSongsButtonVisibility(false);
    setSongsToDisplay(songs.length);
  };

  const handlerRemoveSong = (RegistrazioneID) => {
    setSongs((Songs) => [
      ...Songs.filter((item) => item.RegistrazioneID != RegistrazioneID),
    ]);
  };
  //#endregion

  //#region Build content

  // _content
  if (songs !== undefined && songs.length != 0) {
    if (search.length > 0) {
      _content = songs
        .filter(filterSearch)
        .map(
          (traccia, index) => (            
            (
              <PlaylistCustomBrano
                key={traccia.RegistrazioneID}
                index={index}
                track={traccia}
                RegistrazioneID={traccia.RegistrazioneID}
                onDelete={handlerRemoveSong}
              />
            )
          )
        );

      if (_content.length === 0) {
        //
        _content = (
          <>
            <MessageCover content="Non ci sono tracce che corrispondono alla stringa di ricerca" />
          </>
        );

        //
        _existContent = false;
      }

      if (_content.length !== 0) {
        //
        _existContent = true;
      }
    } else {
      _content = songs
        .slice(0, songsToDisplay)
        .map((traccia, index) => (
          <PlaylistCustomBrano
            key={traccia.RegistrazioneID}
            index={index}
            track={traccia}
            RegistrazioneID={traccia.RegistrazioneID}
            onDelete={handlerRemoveSong}
          />
        ));

      //
      _existContent = true;
    }
  }

  // _content
  if (songs === undefined || songs.length === 0) {
    //
    _existContent = false;

    _content = (
      <MessageCover content="Ops.. Sembra che questa playlist sia ancora vuota.." />
    );
  }

  // _contentLastUpdate
  if (_existLastUpdate) {
    _contentLastUpdate = lastUpdate;
  }

  // _contentLastUpdate
  if (!_existLastUpdate) {
    _contentLastUpdate = "Nessun aggiornamento recente";
  }
  //#endregion

  //#endregion
  return (
    <div className={`container ${style.container}`}>
      <div className={style.playlistBodyHead}>
        <div className={style.playlistInfo}>
          <h6>{countTracks()}</h6>
          <h6>|</h6>
          <h6>{formatPlaylistDuration()}</h6>
          <h6>|</h6>
          {_existLastUpdate && <h6>{_contentLastUpdate}</h6>}
          {!_existLastUpdate && <h6>{_contentLastUpdate} </h6>}
        </div>
        <InputSearch
          background="transparent"
          width={inputSearchWidth}
          border={inputSearchBorder}
          placeholder={inputSearchPlaceholder}
          onClick={handleClick}
          handleClickOutside={handleClickOutside}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          setSearch={setSearch}
          search={search}
        />
      </div>
      {_existContent && (
        <div className={style.playlistBodyTracks}>{_content}</div>
      )}
      {/* TODO aggiungere style per messaggi  */}
      {!_existContent && <div className="">{_content}</div>}
      {displayAllSongsButtonVisibility && (
        <div
          className={style.loadMore}
          onClick={displayAllSongsButtonVisibilityFn}
        >
          <h5>visualizza tutti i brani</h5>
        </div>
      )}
    </div>
  );
};
export default PlaylistCustomBody;
