import React, { useState } from "react"
import PlusIcon from "../../_assets/icone/plus-dark.svg"

//Styles
import style from "./BtnGreen.module.css"

const BtnGreen = (props) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <button onClick={props.onClick} className={style.btnContainer} style={{ transform: isHover ? props.transform : "none" }} onMouseEnter={() => { setIsHover(true) }} onMouseLeave={() => { setIsHover(false) }}>
      <img src={props.icon} alt="button" width={props.imgWidth} height={props.imgHeight} />
      <h4>{props.placeholder}</h4>
    </button>
  )
}

BtnGreen.defaultProps = {
  icon: PlusIcon,
  imgWidth: "10px",
  imgHeight: "10px",
  placeholder: "crea",
  transform: "translatey(-1px)",
  onClick: null
}
export default BtnGreen