import React, { useContext, useState } from "react";
import IconEdit from "../../_assets/icone/edit.svg";
import AuthContext from "../../_store/auth-context";
import Btn from "../buttons/Btn";
import { success } from "../modals/PopupNotification";
import jwt_decode from "jwt-decode"


// Styles
import style from "./PopupModificaPlayerInfo.module.css";

const PopupModificaPlayerInfo = ({
  active,
  setActive,
  setIsOpen,
  onConfirm,
}) => {
  
  const authCtx = useContext(AuthContext);


  //#region useState
    
  //
  const [channelName, setChannelName] = useState(active.Nome);
  const [channelDescription, setChannelDescription] = useState(active.Descrizione);

  //#endregion

  //#region function
  
  //
  const saveChanges = () => {
    // Do something
    setIsOpen(false);
    
      const fetchData = async () => {
        try {
          
          //
          const params = {
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            PlayerID: active.PlayerID,
            PlayerName: channelName,
            PlayerDescription: channelDescription,
          };

          //
          const options = {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
            },
            body: JSON.stringify(params),
          };

          //
          const response = await fetch(
            process.env.REACT_APP_GRM_SERVICES +
              "api/v1/utilizzatore/player_update",
            options
          );

          const data = await response.json();
          
          success("Elemento modificato con successo", true);

        } catch (error) {}
      };

      //
      fetchData();

    active.Nome = channelName
    active.Descrizione = channelDescription

    setActive(active)
  }

  //
  const handleChangeName = (e) =>{
    e.preventDefault();
    setChannelName(e.target.value);
  }

  //
  const handleChangeDescription = (e) =>{
    e.preventDefault();
    setChannelDescription(e.target.value);
  }

  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <img src={IconEdit} width={25} height={25} alt="Icon" /> ;
            <h4 className={style.heading}>Modifica informazioni del canale</h4>
          </div>
          <button className={style.closeBtn} onClick={() => setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>
            <div className={style.actionsContainer}>
              <label htmlFor="playlistName">Nome </label>
              <input
                id="canaleName"
                type="text"
                value={channelName}
                placeholder="Inserisci il nome del canale"
                onChange={handleChangeName}
              />
              <label htmlFor="playlistDesc">Descrizione</label>
              <input
                id="canaleDesc"
                type="text"
                value={channelDescription}
                placeholder="Inserisci una descrizone del canale"
                onChange={handleChangeDescription}
              />
              <Btn
                onClick={saveChanges}
                placeholder="CONFERMA"
                width="100%"
                transform="scale(.99)"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupModificaPlayerInfo;
