import React from "react"

// Styles
import style from "./BtnNewPlaylist.module.css"



const BtnNewPlaylist = (props) => {
  return (
    <div onClick={props.onClick} className={style.btnAdd}>
      <h6>{props.playlistName}</h6>
    </div>
  )
};

BtnNewPlaylist.defaultProps = {
  playlistName: "Playlist #1"
}


export default BtnNewPlaylist

