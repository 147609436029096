import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import IconEdit from "../../_assets/icone/edit.svg";
import AuthContext from "../../_store/auth-context";
import Btn from "../buttons/Btn";
import { success } from "../modals/PopupNotification";
import jwt_decode from "jwt-decode";

// Styles
import style from "./PopupModificaPlaylistInfo.module.css";
import ToggleSwitch from "../commons/ToggleSwitch";

const PopupModificaPlaylistInfo = ({
  setIsOpen,
  playlistName,
  playlistDescription,
  setPlaylistName,
  setPlaylistDescription,
  playlistCoverName,
  setPlaylistCoverName,
  playlistCoverUrl,
  setPlaylistCoverUrl,
  isPlaylistPrivate,
}) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);
  let _playlistCoverUrl = "";

  //#endregion

  //#region UseState
  const [Name, setName] = useState(playlistName);
  const [Description, setDescription] = useState(playlistDescription);
  const [_isPlaylistPrivate, _setIsPlaylistPrivate] = useState(
    isPlaylistPrivate
  );
  const [fileName, setFileName] = useState({
    "--contentR": "'Scegli la cover ideale'",
  });

  //#endregion

  //
  const searchParams = useParams();

  //#region Functions

  const onFileChange = (e) => {
    if (
      document.getElementById(e.target.id).files[0] !== undefined &&
      document.getElementById(e.target.id).files[0] !== null
    ) {
      // setPlaylistDescription(e.target.value);

      setFileName({
        "--contentR": document.getElementById(e.target.id).files[0].name,
      });
    } else {
      setFileName({
        "--contentR": "'Scegli la cover ideale'",
      });
    }
  };

  const HandleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const HandleDescriptionChange = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const saveChanges = () => {
    
    const POSTdeleteCoverFromBlob = async () => {
      try {      
        //
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          Cover: playlistCoverName,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/cover_delete",
          options
        );
        const data = await response.json();

        if (response.status === 200) {
          POSTaddCoverToPlaylist();
        } else {
          POSTaddCoverToPlaylist();
        }
        // POSTaddCoverToPlaylist();
      } catch (error) {}
    };

    //
    const POSTaddCoverToPlaylist = async () => {
      //
      try {
        let bodyData = new FormData();
        bodyData.append(
          "File",
          document.getElementById("playerCover").files[0]
        );
        bodyData.append("Playlist_name", Name.replace(" ", "_"));
        //
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + authCtx.token,
          },
          body: bodyData,
        };
        
        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/utilizzatore/playlist/cover_add",
          options
        );

        const data = await response.json();
        //ritorna l'url
        _playlistCoverUrl = data;
        setPlaylistCoverUrl(data);
        fetchData();
        return data;
      } catch (error) {}
    };

    const fetchData = async () => {      
      try {

        let _coverUrl =
          _playlistCoverUrl === "" ? playlistCoverUrl : _playlistCoverUrl;
        //

        let _coverName;

        if (document.getElementById("playerCover") === null || document.getElementById("playerCover").files === undefined || document.getElementById("playerCover").files.length === 0) {
          _coverName = playlistCoverName;
        } else {
          _coverName =
            Name.toLowerCase().replace(" ", "_") +
            "_" +
            document.getElementById("playerCover").files[0].name;
        }
        
        // si assicura che _coverUrl non sia undefined o null o vuoto e che la string non sia contenuta tra doppi apici
        if(_coverUrl !== undefined && _coverUrl !== null && _coverUrl !== "" && _coverUrl[0] === "\""){        
          _coverUrl = _coverUrl.slice(1,-1);
        }        
        
        const params = {
          CompanyID: jwt_decode(authCtx.token).unique_name.split("|")[2],
          PlaylistID: searchParams.id,
          PlaylistName: Name,
          PlaylistDescription: Description,
          Cover: _coverName,
          CoverUrlSharing: _coverUrl,
          Private: _isPlaylistPrivate,
        };

        try{
          setPlaylistCoverName(_coverName);
        }catch(exception){}      

        //
        const options = {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + authCtx.token,
            "Content-type": "application/json",
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_update",
          options
        );
        const data = await response.json();
        success("playlist aggiornata con successo", true);
      } catch (error) {        
      }
    };

    //
    setPlaylistName(() => Name);
    setPlaylistDescription(() => Description);

    //if image field has value
    if (document.getElementById("playerCover") !== null && document.getElementById("playerCover").files !== undefined && document.getElementById("playerCover").files.length > 0) {      
      POSTdeleteCoverFromBlob();
    } else {
      fetchData();
    }

    // setIsOpen(false);
  };

  //#endregion
  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <img src={IconEdit} width={25} height={25} alt="Icon" /> ;
            <h4 className={style.heading}>Modifica playlist</h4>
          </div>
          <button className={style.closeBtn} onClick={() => setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>
            <div className={style.actionsContainer}>
              <label htmlFor="playlistName">Nome</label>
              <input
                id="playlistName"
                type="text"
                value={Name}
                onChange={HandleNameChange}
                // placeholder="Inserisci il nome della playlist"
              />
              <label htmlFor="playlistDesc">Descrizione</label>
              <input
                id="playlistDesc"
                type="text"
                value={Description}
                onChange={HandleDescriptionChange}
                // placeholder="Inserisci una descrizone della playlist"
              />
              {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
                <label htmlFor="playlistDesc">Cover</label>
              )}
              {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
                <input
                  id="playerCover"
                  type="File"
                  className={style.custom_file_input}
                  onChange={onFileChange}
                  style={fileName}
                />
              )}
              {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
                <label htmlFor="playlistDesc">Playlist privata</label>
              )}
              {(authCtx.isAdmin === true || authCtx.isAdmin === "true") && (
                <ToggleSwitch
                  isPlaylistPrivate={_isPlaylistPrivate}
                  setIsPlaylistPrivate={_setIsPlaylistPrivate}
                />
              )}
              <Btn
                onClick={saveChanges}
                placeholder="CONFERMA"
                width="100%"
                transform="scale(.99)"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PopupModificaPlaylistInfo;
