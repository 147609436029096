import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import InputSearch from "../_components/commons/InputSearch";
import Title from "../_components/commons/Title";
import Loader from "../_components/commons/Loader";
import Footer from "../_components/layout/Footer";
import CarouselCollection from "../_components/containers/CarouselCollection";

//Style
import style from "./home.module.css";
import AuthContext from "../_store/auth-context";
import jwt_decode from "jwt-decode";

const Home = () => {

  //Home componente

  //#region  context

  //
  const authCtx = useContext(AuthContext);  

  //#endregion

  //useState
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState();
  const [playlists, setPlaylists] = useState(null);

  //
  let _content = null;

  //onPageLoad
  useEffect(() => {    
    //
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "/api/v1/Utilizzatore/collection_list?" +
            new URLSearchParams({
              CompanyID: process.env.REACT_APP_COMPANYID_DJ,
              IsTrending: false
            }),
          {
            method: "get",
            headers: new Headers({
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
            }),
          }
        );
        const data = await response.json();
        const _collections = [
          ...new Map(
            JSON.parse(data)
            .map((item) => [item.CollezioneID, item])
            .values()
            ),
          ];
          const _playlists = JSON.parse(data);
          
        setCollections(_collections.slice(0, 6));
        setPlaylists(_playlists);

        setTimeout(() => {
          setLoading(false);
        }, "200");
      } catch (error) {}
    };

    //
    fetchData();
  }, []);

  //
  const fetchReturnNullObject = new Boolean(!collections);

  if (fetchReturnNullObject.valueOf()) {
    return null;
  }

  if (!fetchReturnNullObject.valueOf()) {
    _content = collections.map((collection) => (
      <CarouselCollection
        key={collection[1].CollezioneID}
        collection={collection}
        playlists={playlists}
      />
    ));
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="container">
          <div className={style.head}>
            <Title
              title={`Ascolta la tua musica!`}
              textTransform="uppercase"
              color="var(--text-primary)"
            />
            <NavLink to="/categorie">
              <InputSearch autofocus="" />
            </NavLink>
          </div>
          {_content}
        </div>
      )}
      <Footer />
    </>
  );
};

export default Home;
