import React, { useEffect, useState, useRef, useContext } from "react";
import CardSmallProgrammazione from "../_components/cards/CardSmallProgrammazione";
import MessageCover from "../_components/commons/MessageCover";
import { useDrop } from 'react-dnd'

// Styles
import style from "./programmazione.module.css"
import CardSlot from "../_components/cards/CardSlot";
import AuthContext from "../_store/auth-context";
import jwt_decode from "jwt-decode"

const dataSource = [{
  EndTime: new Date(2022, 0, 11, 6, 30),
  StartTime: new Date(2022, 0, 11, 4, 0),
}]

//#region Time Settings Functions

// genera elenco ore giornata
const ElencoOre = () => {
  return (
    <>
      {(() => {
        const arr = [];
        for (let hour = 6; hour < 24; hour++) {
          if (hour < 10) {
            arr.push(
              <li key={`a${hour}`}>
                <p>
                  <span>{`0${hour}:00`}</span>
                </p>
              </li>
            );
          } else {
            arr.push(
              <li key={`b${hour}`}>
                <p>
                  <span>{`${hour}:00`}</span>
                </p>
              </li>
            );
          }

        }
        return arr;
      })()}
      {(() => {
        const arr2 = [];
        for (let hour = 0; hour < 7; hour++) {
          arr2.push(
            <li key={`c${hour}`}>
              <p>
                <span>{`0${hour}:00`}</span>
              </p>
            </li>
          );
        }
        return arr2;
      })()}
    </>
  )
}

//Crea la tabella dei giorni della settimana
var giorniArray = new Array();
giorniArray[0] = "Domenica";
giorniArray[1] = "Lunedì";
giorniArray[2] = "Martedì";
giorniArray[3] = "Mercoledì";
giorniArray[4] = "Giovedì";
giorniArray[5] = "Venerdì";
giorniArray[6] = "Sabato";


// sst live time css top value
function setTopPosition(hour) {
  switch (hour) {
    case '06':
      return 0
    case '07':
      return 4.16667
    case '08':
      return 8.33333
    case '09':
      return 12.5
    case '10':
      return 16.6667
    case '11':
      return 20.8333
    case '12':
      return 25
    case '13':
      return 29.1667
    case '14':
      return 33.3333
    case '15':
      return 37.5
    case '16':
      return 41.6667
    case '17':
      return 45.8333
    case '18':
      return 50
    case '19':
      return 54.1667
    case '20':
      return 58.3333
    case '21':
      return 62.5
    case '22':
      return 66.6667
    case '23':
      return 70.8333
    case '00':
      return 75
    case '01':
      return 79.1667
    case '02':
      return 83.3333
    case '03':
      return 87.5
    case '04':
      return 91.6667
    case '05':
      return 95.8333
    default:
      return 0;
  }
}

//#endregion



const Programmazione = () => {

  //#region context
  
  const authCtx = useContext(AuthContext);  
  
  //#endregion

  // useRef
  let liveTime = useRef()
  // useState
  const [scheduledPlaylistMonday, setScheduledPlaylistMonday] = useState([])
  const [scheduledPlaylistTuesday, setScheduledPlaylistTuesday] = useState([])
  const [playlists, setPlaylists] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeNow, setTimeNow] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
  const [currentDay, setCurrentDay] = useState(giorniArray[new Date().getDay()])

  let _playlists = null;
  let _pubblicità = null;


  // live time
  useEffect(() => {
    const topPosition = setTopPosition(timeNow.substring(0, 2))

    // 0.0694445 = single minutes percentage value 
    liveTime.current.style.setProperty('--top-updated', timeNow.substring(3, 5) * 0.0694445 + topPosition + "%")

    //update every minute
    const interval = setInterval(() => {
      setTimeNow(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }, 60000);

    return () => clearInterval(interval);
  }, [timeNow])

  //onPageLoad
  useEffect(() => {

    //
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
          "api/v1/utilizzatore/playlist_list?" +
          new URLSearchParams({
            All: 1,
            Private:"",
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            playlistID: null,
          }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();

        setPlaylists(JSON.parse(data));
        setLoading(false);
        setFetched(true);
      } catch (error) { }
    };

    //
    fetchData();
  }, []);

  //#region build Content

  //content Playlist
  const fetchReturnNullObjectPlaylist = !playlists && fetched
  const fetchReturnEmptyArrayPlaylist = playlists !== null && playlists.length === 0 && fetched
  const fetchReturnValidObjectPlaylist = fetched && playlists && playlists.length > 0

  if (fetchReturnNullObjectPlaylist.valueOf() || fetchReturnEmptyArrayPlaylist.valueOf()) {
    _playlists = <>
      <MessageCover content="Sembra che non sia stata ancora aggiunta nessuna playlist nella tua musica preferit" />
    </>;
  }

  if (fetchReturnValidObjectPlaylist.valueOf()) {
    _playlists = (
      <>
        <h6 className={style.elencoCategory}>LA MIA MUSICA</h6>
        {playlists.map((playlist) => (
          <CardSmallProgrammazione
            key={playlist.PlayListID}
            image={playlist.CoverUrlSharing}
            itemsName={playlist.Nome}
            idPlaylist={playlist.PlayListID}
            height="48px"
          />
        ))}
      </>
    );
  }

  //content Pubblicità

  _pubblicità = (
    <>
      <h6 className={style.elencoCategory}>PUBBLICITA'</h6>
      <CardSmallProgrammazione
        key="1"
        image=""
        itemsName="pubblicità 1"
        idPlaylist="3"
      />
    </>
  );


  //#endregion

  //#region Drag And Drop
  const [{ isOverMonday }, dropMonday] = useDrop(() => ({
    accept: "playlist",
    drop: (item) => setScheduledPlaylistMonday((prev) => [...prev, item]),
    // dropTuesday: (item) => setScheduledPlaylistTuesday((scheduled) => [...scheduled, item]),
    collect: monitor => ({
      isOverMonday: !!monitor.isOver(),
    }),
  }), [])

  const [{ isOverTuesday }, dropTuesday] = useDrop(() => ({
    accept: "playlist",
    drop: (item) => setScheduledPlaylistTuesday((prev) => [...prev, item]),
    // dropTuesday: (item) => setScheduledPlaylistTuesday((scheduled) => [...scheduled, item]),
    collect: monitor => ({
      isOverTuesday: !!monitor.isOver(),
    }),
  }), [])

  return (
    <>
      <div style={{ marginTop: "40px" }} className="container"  >
        <h1 style={{ font: "var(--title-m" }}>Programmazione</h1>
        <div className={style.container}>
          <section>
            <div className={style.elencoPlaylist}>
              {_playlists}
              {_pubblicità}

            </div>
          </section>
          <section>
            <div className={style.scheduler}>
              <div className={style.schedulerEditor}>
                <div className={style.schedulerContainer}>
                  <div className={style.schedulerHead}>
                    <h5>Lunedì</h5>
                    <h5>Martedì</h5>
                    <h5>Mercoledì</h5>
                    <h5>Giovedì</h5>
                    <h5>Venerdì</h5>
                    <h5>Sabato</h5>
                    <h5>Domenica</h5>
                  </div>

                  <div className={style.schedulerBody}>
                    <ul className={style.schedulerDragDrop}>
                      <li ref={liveTime} data-time={timeNow} className={style.schedulerClockLive}>
                        <p>
                          <span>{timeNow}</span>
                        </p>
                      </li>
                      <ElencoOre />
                    </ul>
                    <div ref={dropMonday} className={`${style.schedulerDay} ${currentDay == "Lunedì" ? style.schedulerDayToday : ""}`}>
                      {scheduledPlaylistMonday.map(playlist => <CardSlot
                        key={playlist.id + Math.floor(Math.random() * 99999)}
                        itemsName={playlist.nome}
                        idPlaylist={playlist.id}
                        height="48px"
                        transform="translateY(0px)" />)}
                    </div>
                    <div ref={dropTuesday} className={`${style.schedulerDay} ${currentDay == "Martedì" ? style.schedulerDayToday : ""}`}>
                      {scheduledPlaylistTuesday.map(playlist => <CardSlot
                        key={playlist.id + Math.floor(Math.random() * 99999)}
                        itemsName={playlist.nome}
                        idPlaylist={playlist.id}
                        height="48px"
                        transform="translateY(0px)" />)}
                    </div>
                    <div className={`${style.schedulerDay} ${currentDay == "Mercoledì" ? style.schedulerDayToday : ""}`}></div>
                    <div className={`${style.schedulerDay} ${currentDay == "Giovedì" ? style.schedulerDayToday : ""}`}></div>
                    <div className={`${style.schedulerDay} ${currentDay == "Venerdì" ? style.schedulerDayToday : ""}`}></div>
                    <div className={`${style.schedulerDay} ${currentDay == "Sabato" ? style.schedulerDayToday : ""}`}></div>
                    <div className={`${style.schedulerDay} ${currentDay == "Domenica" ? style.schedulerDayToday : ""}`}></div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div >
    </>
  );
};

export default Programmazione;
