import React, { useContext, useEffect, useState } from "react";
import BtnAddTrack from "../buttons/BtnAddTrack";
import BtnNewPlaylist from "../buttons/BtnNewPlaylist";
import { success } from "../../_components/modals/PopupNotification";
import jwt_decode from "jwt-decode"

// Styles
import style from "./PopupSelezionaPlaylist.module.css";
import AuthContext from "../../_store/auth-context";

const PopupSelezionaPlaylist = ({ setIsOpen, RegistrazioneID }) => {

  //#region  context
  
  //
  const authCtx = useContext(AuthContext);  

  //#endregion
  
  //Content
  let _content = null;

  //#region UseState
  const [isNewPlaylist, setIsNewPlaylist] = useState(false);
  const [playlists, setPlaylists] = useState({});

  //input
  const [playlistName, setPlaylistName] = useState();

  //#endregion
  
  //onPageLoad
  useEffect(() => {
    //

    //
    let _private;
    if(authCtx.isAdmin === true || authCtx.isAdmin === "true"){
      _private = "";
    }else{
      _private = "true";
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_list?" +
            new URLSearchParams({
              All: 0,
              Private:_private,
              // CompanyID: process.env.REACT_APP_COMPANYID_USER,
              CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
              PlaylistID: "",
            }),
          {
            method: "get",
            headers: new Headers({
              "Access-Control-Allow-Origin": "*",
              Authorization:
                "Bearer " +
                authCtx.token,
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          }
        );
        const data = await response.json();
        setPlaylists(JSON.parse(data));
      } catch (error) {}
    };

    //
    fetchData();
  }, []);

  //#region functions

  //
  const showInput = () => {
    setIsNewPlaylist(true);
  };

  //
  const handlerInputPlaylistNameOnChange = (e) => {
    setPlaylistName(e.target.value);
  };

  //
  const handlerAddTrackToPlaylist = (PlaylistID) => {
    //
    const fetchData = async () => {
      try {
        //
        const params = {
          PlaylistID: PlaylistID,
          RegistrazioneID: RegistrazioneID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/registrazione_add",
          options
        );
        const data = await response.json();
        success("Elemento aggiunto con successo", true);
      } catch (error) {}
    };

    //
    fetchData();
  };

  const postHanlderFavorite_Add = (PlaylistID) => {
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistID: PlaylistID,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist/favourite_add",
          options
        );
        const data = await response.json();
      } catch (error) {}
    };

    //
    fetchData();
  };

  //
  const handlerAddPlaylist = (e) => {
    e.preventDefault();

    //
    const fetchData = async () => {
      try {
        //
        const params = {
          // CompanyID: process.env.REACT_APP_COMPANYID_USER,
          CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
          PlaylistName: playlistName,
        };

        //
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization:
              "Bearer " +
              authCtx.token,
          },
          body: JSON.stringify(params),
        };

        //
        const response = await fetch(
          process.env.REACT_APP_GRM_SERVICES +
            "api/v1/utilizzatore/playlist_add",
          options
        );

        //ritorna Id della playlist creata
        const data = await response.json();

        //
        handlerAddTrackToPlaylist(Math.trunc(data));

        //
        postHanlderFavorite_Add(Math.trunc(data));

        //

        setPlaylists((prev) => [
          ...prev,
          {
            PlayListID: Math.trunc(data),
            // CompanyID: process.env.REACT_APP_COMPANYID_USER,
            CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            // Playlist_CompanyID: process.env.REACT_APP_COMPANYID_USER,
            Playlist_CompanyID: jwt_decode(authCtx.token).unique_name.split('|')[2],
            Nome: playlistName,
            Descrizione: null,
            Cover: null,
            tag: null,
          },
        ]);
      } catch (error) {}
    };

    //
    fetchData();
  };

  //#endregion

  //#region content

  if (playlists.length > 0) {
    _content = playlists.map((playlist) => (
      <BtnAddTrack
        playlistName={playlist.Nome}
        key={playlist.PlayListID}
        onClick={() => handlerAddTrackToPlaylist(playlist.PlayListID)}
      />
    ));
  }
  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <h4 className={style.heading}>Aggiungi brano alla playlist</h4>
          </div>
          <button className={style.closeBtn} onClick={() => setIsOpen(false)}>
            x
          </button>
          <div className={style.modalContent}>{_content}</div>
          <div className={style.modalActions}>
            {!isNewPlaylist && (
              <div className={style.actionsContainer}>
                <BtnNewPlaylist
                  onClick={showInput}
                  playlistName="Crea una nuova playlist"
                />
              </div>
            )}

            {isNewPlaylist && (
              <div className={style.actionsContainer}>
                <input
                  type="text"
                  placeholder="Inserisci il nome della playlist"
                  onChange={handlerInputPlaylistNameOnChange}
                />
                <button
                  className={style.confirmBtn}
                  onClick={(e) => handlerAddPlaylist(e)}
                >
                  <h6>CREA</h6>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupSelezionaPlaylist;
