import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../_store/auth-context";
import InputSearch from "../commons/InputSearch";
import RowPlayer from "../commons/RowPlayer";
import CoverImageDefault from "../../_assets/immagini/defaultPlaylist.png";
import jwt_decode from "jwt-decode"

// Styles
import style from "./PopupAddPlaylistToPlayer.module.css";
import LogoCover from "../../_assets/loghi/grm_logo_omino.png"

// Static

const PopupDettaglioRegistrazione = (props) => {
  
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //#region  UseState

  //#endregion


  //#region Content

  let _content;

  _content = (<div className={style.container}>
      <div className={style.containerBg}>
        <img src={LogoCover} alt="Logo Grm Fun" width={100} />
      </div>
      <div className={style.messageContent}>
        <h4>Questa funzionalità è in fase di sviluppo</h4>
      </div>
    </div>)
    
  //#endregion

  return (
    <>
      <div className={style.darkBG} onClick={() => props.setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div className={style.modalHeader}>
            <h4 className={style.heading}>Funzionalità in sviluppo</h4>
          </div>
          <button
            className={style.closeBtn}
            onClick={() => props.setIsOpen(false)}
          >
            x
          </button>
          <div className={style.modalContent}>
            <div className={style.modalContentScroll}>              
                {_content}              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupDettaglioRegistrazione;
