import React, { useState } from "react"


//Styles
import style from "./Btn.module.css"

const Btn = (props) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <button onClick={props.onClick} className={style.btnContainer} style={{ background: props.btnBackground, width: props.width, transform: isHover ? props.transform : "none" }} onMouseEnter={() => { setIsHover(true) }} onMouseLeave={() => { setIsHover(false) }}>
      <h6 style={{ color: props.textColor }}>{props.placeholder}</h6>
    </button>
  )
}

Btn.defaultProps = {
  placeholder: "crea",
  btnBackground: "var(--brand-gradient-light)",
  textColor: "var(--text-primary)",
  transform: "translateX(3px)",
  width: "75px",
  onClick: null
}
export default Btn