import React, { useState, useEffect, useContext } from "react";
import RowDispositivi from "../commons/RowDispositivi";
import RowPlayer from "../commons/RowPlayer";
import TabsPlayer from "../tabs/TabsPlayer";
import CoverImageProg from "../../_assets/immagini/cover-default-programmazione.png";
import RowLog from "../commons/RowLog";
import CodeLabel from "../commons/CodeLabel";
import IconEdit from "../../_assets/icone/edit.svg";

// Styles
import style from "./PlayerContainer.module.css";
import AuthContext from "../../_store/auth-context";
import BtnGreen from "../buttons/BtnGreen";
import PopupCreaDispositivo from "../modals/PopupCreaDispositivo";
import MessageCover from "../commons/MessageCover";

const PlayerContainer = (props) => {
  //#region  context

  //
  const authCtx = useContext(AuthContext);

  //#endregion

  //#region usestates
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [devicesLimit, setDevicesLimit] = useState(false);
  const [logsLimit, setLogsLimit] = useState(false);
  const [riproduzioniLimit, setRiproduzioniLimit] = useState(false);
  const [
    displayMoreDevicesButtonVisibility,
    setDisplayMoreDevicesButtonVisibility,
  ] = useState(true);
  const [
    displayMoreLogsButtonVisibility,
    setDisplayMoreLogsButtonVisibility,
  ] = useState(true);

  const [toggleState, setToggleState] = useState(1);

  useEffect(() => {
    setDevicesLimit(10);
    setLogsLimit(10);
    setRiproduzioniLimit(10);
  }, []);

  //#region functions

  //
  const changeDispositiviLimit = (e) => {
    //
    e.preventDefault();

    // incrementa di 10 il numero di dispositivi visualizzati
    setDevicesLimit(devicesLimit + 10);

    //nasconde il bottone che permette di visualizzare più dispositivi
    if (devicesLimit > props.devices.length) {
      setDisplayMoreDevicesButtonVisibility(false);
    }
  };

  //
  const changeLogsLimit = (e) => {
    //
    e.preventDefault();

    // incrementa di 10 il numero di dispositivi visualizzati
    setLogsLimit(logsLimit + 10);

    //nasconde il bottone che permette di visualizzare più dispositivi
    if (setLogsLimit > props.logs.length) {
      setDisplayMoreLogsButtonVisibility(false);
    }
  };

  //
  const changeRiproduzioniLimit = (e) => {
    //
    e.preventDefault();

    // incrementa di 10 il numero di dispositivi visualizzati
    setLogsLimit(riproduzioniLimit + 10);

    //nasconde il bottone che permette di visualizzare più dispositivi
    if (setLogsLimit > props.riproduzioni.length) {
      setDisplayMoreLogsButtonVisibility(false);
    }
  };
  //#endregion

  //#region content

  //
  let _contentPlaylist = (
    <>
      <div className={style.rowContainer}>
        {props.playlists &&
          props.playlists.map((item) => (
            <RowPlayer
              activePlayer={props.channel}
              coverSrc={
                item.CoverUrlSharing !== undefined &&
                item.CoverUrlSharing !== null &&
                item.CoverUrlSharing !== ""
                  ? item.CoverUrlSharing
                  : item.CoverUrlSharing
              }
              nome={item.Nome}
              desc={item.Descrizione}
              successMessage="Playlist rimossa dal player con successo"
              PlaylistID={item.PlayListID}
              setPlaylists={props.setPlaylists}
            />
          ))}
        {!props.playlists && (
          <MessageCover content="Non sono state ancora aggiunte playlist a questo canale" />
        )}
        {props.playlists && props.playlists.length === 0 && (
          <MessageCover content="Non sono state ancora aggiunte playlist a questo canale" />
        )}
      </div>
    </>
  );

  //
  let _contentProg = (
    <div className={style.rowContainer}>
      <MessageCover content="Stiamo lavorando su questa funzionalità" />;
    </div>
  );

  //
  let _contentDispositivo = (
    <div className={style.rowContainer}>
      {props.devices &&
        props.devices
          .map(
            (item) =>
              item && (
                <RowDispositivi
                  key={item.PlayerDevicesID}
                  ID={item.PlayerDevicesID}
                  PlayerID={props.channel.PlayerID}
                  DeviceDescription={item.Description}
                  DeviceCode={item.DeviceID}
                  DeviceTelephone={item.Telephone}
                  DeviceEmail={item.Email}
                  DeviceID={item.DeviceID}
                  DeviceOS={item.DeviceOS}
                  CreatedDate={item.CreatedDate}
                  statoDevice="in riproduzione"
                  isActive={item.Status}
                  setDeviceStatusUpdate={props.setDeviceStatusUpdate}
                  setDeviceUpdateDelete={props.setDeviceUpdateDelete}
                  toggleState={toggleState}
                  setToggleState={setToggleState}
                />
              )
          )
          .slice(0, devicesLimit)}
      {!props.devices && (
        <MessageCover content="Non sono ancora stati aggiunti devices a questo canale" />
      )}
      {props.devices && props.devices.length === 0 && (
        <MessageCover content="Non sono ancora stati aggiunti devices a questo canale" />
      )}

      {props.devices &&
        props.devices.length !== 0 &&
        props.devices.length > devicesLimit &&
        displayMoreDevicesButtonVisibility && (
          <div
            className={style.loadMore}
            onClick={(e) => {
              changeDispositiviLimit(e);
            }}
          >
            <h5>visualizza più dispositivi</h5>
          </div>
        )}
    </div>
  );

  //
  let _contentLog = (
    <div className={style.rowContainer}>
      {props.logs &&
        props.logs
          .map(
            (item) =>
              item && (
                <RowLog
                  nomeLog={item.Titolo}
                  descLog={item.Description}
                  ISRC={item.ISRC}
                  date={item.Date}
                  onClickISRC={props.onClickISRC}
                  DeviceID={item.DevicesID}
                  DeviceEventID={item.DeviceEventID}
                />
              )
          )
          .slice(0, logsLimit)}
      {!props.logs && (
        <MessageCover content="I brani all'interno di questo canale non sono stati ancora riprodotti" />
      )}
      {props.logs && props.logs.length === 0 && (
        <MessageCover content="I brani all'interno di questo canale non sono stati ancora riprodotti" />
      )}
      {props.logs && displayMoreLogsButtonVisibility && (
        <div
          className={style.loadMore}
          onClick={(e) => {
            changeLogsLimit(e);
          }}
        >
          <h5>visualizza più logs</h5>
        </div>
      )}
    </div>
  );

  //
  let _contentRiproduzioni = (
    <div className={style.rowContainer}>
      {props.riproduzioni &&
        props.riproduzioni
          .map(
            (item) =>
              item && (                
                <RowLog
                  nomeLog={item.Titolo}
                  descLog={item.Description}
                  ISRC={item.ISRC}
                  date={item.Date}
                  onClickISRC={props.onClickISRC}
                  DeviceID={item.DevicesID}
                  DeviceEventID={item.DeviceEventID}
                  trackLength={item.DurataSecondiEffettiva}
                />
              )
          )
          .slice(0, logsLimit)}
      {!props.logs && (
        <MessageCover content="I brani all'interno di questo canale non sono stati ancora riprodotti" />
      )}
      {props.logs && props.logs.length === 0 && (
        <MessageCover content="I brani all'interno di questo canale non sono stati ancora riprodotti" />
      )}
      {props.logs && displayMoreLogsButtonVisibility && (
        <div
          className={style.loadMore}
          onClick={(e) => {
            changeRiproduzioniLimit(e);
          }}
        >
          <h5>visualizza più riproduzioni</h5>
        </div>
      )}
    </div>
  );

  //#endregion

  return (
    <div className={style.playerContainer}>
      <div id={props.channel.PlayerID} className={style.playerContainerHead}>
        <div>
          <h2>
            {props.channel.Nome
              ? props.channel.Nome.toUpperCase()
              : props.channel.Nome}
          </h2>
          <div className={style.playerSubInfo}>
            <h5>
              {props.channel.Descrizione
                ? props.channel.Descrizione.toUpperCase()
                : props.channel.Descrizione}
            </h5>
            <img
              src={IconEdit}
              alt="modifica informazioni"
              onClick={props.onClickEditIcon}
            />
          </div>
        </div>

        <CodeLabel value={props.channel.PlayerCode} />
      </div>
      <TabsPlayer
        tabPlaylist={_contentPlaylist}
        tabProg={_contentProg}
        tabDispositivi={_contentDispositivo}
        tabRiproduzioni={_contentRiproduzioni}
        tabLog={_contentLog}
        onClickAddPlaylist={props.onClickAddPlaylist}
        onClickAddProg={props.onClickAddProg}
        onClickAddDisp={props.onClickAddDisp}
        onClickExportLog={props.onClickExportLog}
        toggleState={toggleState}
        setToggleState={setToggleState}
      />

      {/* {isPopupOpen && <PopupCreaDispositivo setIsOpen={setIsPopupOpen} PlayerID={props.channel.PlayerID} addNewDevice={props.addNewDevice}/>} */}
    </div>
  );
};

PlayerContainer.defaultProps = {
  displayAddIcon: "block",
};
export default PlayerContainer;
